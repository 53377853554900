/* HEADER */
.header
	.header__bar
		&::after
			content: ''
			width: calc( 100% - 50px)
			height: 1px
			background: var(--color-orange)
			position: absolute
			bottom: 0
			left: 0
			right: 0
			margin: 0 auto
			@media screen and (max-width: $md)
				width: calc( 100% - 40px)
		a, a:hover
			color: var(--color-orange)
		.menu-item
			a
				text-transform: uppercase
				font-size: 15px
				@media screen and (max-width: $xxl)
					font-size: 14.5px
				@media screen and (max-width: $xl)
					//font-size: 14px
				.esconder
					@media screen and (max-width: $lg)
						display: none

			.instagram
				padding: 0
				position: relative
				top: 4px
				margin-right: 0px
				margin-left: 10px
				@media screen and (max-width: $xl)
					margin-left: 5px
			.modalContact
				background: var(--color-orange)
				padding: 2px 25px
				border: none
				@media screen and (max-width: $xl)
					padding: 2px 15px
				.button__label
					padding: 0px
					letter-spacing: 1px
					color: #fff
					font-weight: 600
					font-size: 14px
					@media screen and (max-width: $xl)
						font-size: 13px
	.logo__img-primary
		+fluid('width', 100, 130)
	.header__bar_sticky
		.logo__img-primary
			width: 110px
		a, a:hover
			color: var(--color-orange) !important

		.header__col
			padding-top: 18px
			padding-bottom: 18px
			a
				+fluid('font-size', 13.5, 14.5)
				+trans1
			.modalContact
				.button__label
					+fluid('font-size', 12.5, 13.5)

.header__bar[data-arts-color-theme="dark"]
	.menu-item
		a, a:hover
			color: #fff
	&::after
		background: #fff

.header__bar[data-arts-color-theme="light"]
	.menu-item
		a
			color: var(--color-orange) !important

/* VARIOS */
.h2
	p
		@extend .h2

.h4
	p
		@extend .h4

.small
	+fluid('font-size', 15, 16)
	line-height: 1.4

.color-orange
	color: var(--color-orange) !important
.span-border-bottom
	line-height: 1.5
	span
		position: relative
		&::after
			content: ''
			position: absolute
			width: 100%
			height: 1px
			background: var(--color-orange)
			bottom: -2px
			left: 0

.bg-orange
	background: var(--color-orange)

.bg-orange-2
	background: var(--color-orange-2)

.bg-cream
	background: var(--color-cream)

.bg-rose
	background: var(--color-rose)

.bg-green
	background: var(--color-green)

.bg-green-2
	background: var(--color-green-2)

.bg-red
	background: var(--color-red)

.bg-purple
	background: var(--color-purple)

.bg-yellow
	background: var(--color-yellow)

.bg-yellow-2
	background: var(--color-yellow-2)

.line-top
	width: 90px
	border: 1px solid var(--color-orange)

.line-top-2
	width: 2px
	background: var(--color-orange)
	margin-right: 20px

.line-top-3
	width: 100%
	border: 0.5px solid var(--color-orange)

.line-top-4
	width: 1px
	height: 100%
	background: var(--color-orange)

.line-footer
	&::before
		content: ''
		position: absolute
		top: 0
		left: 0
		right: 0
		margin: 0 auto
		height: 1px
		display: block
		background: var(--color-orange)
		width: calc( 100% - 40px)

	&::after
		content: ''
		position: absolute
		bottom: 0
		left: 0
		right: 0
		margin: 0 auto
		height: 1px
		display: block
		background: var(--color-orange)
		width: calc( 100% - 40px)

.point
	width: 8px
	height: 8px
	background: var(--color-orange)
	border-radius: 50%
	margin-right: 10px

.fix-height-p
	min-height: 123px
	@media screen and (max-width: $md)
		min-height: auto

.bg-offset
	position: relative
	overflow: hidden
	&::before
		content: ''
		bottom: 0
		width: 100%
		height: 50%
		background: #fff
		position: absolute

.bg-offset-2
	position: relative
	overflow: hidden
	&::before
		content: ''
		top: 0
		width: 100%
		height: 40%
		background: #fff
		position: absolute
		@media screen and (max-width: $xl)
			height: 55%
		@media screen and (max-width: $lg)
			height: 60%
		@media screen and (max-width: $md)
			height: 70%
		@media screen and (max-width: $sm)
			height: 60%

.fw-300
	font-weight: 300 !Important

.sub
	strong
		border-bottom: 3px solid #000

.color-orange-strong
	strong
		color: var(--color-orange)
		display: inline

.paragraph-pb
	p
		margin-top: 0px

.xl
	+fluid('font-size', 50, 80)
	line-height: 0.9
	font-weight: 300
	letter-spacing: -2px

.paragraph-2, .paragraph-2 p
	+fluid('font-size', 17, 18)

strong
	font-weight: bold

.color-white
	color: #fff !important

.scroll
	position: absolute
	top: -20px

.header__bar
	transition: all 0.3s ease-in-out !important

.header__widget-title
	color: var(--color-orange) !important
	font-size: 13px !important
.header__widget-content, .header__widget-content a
	color: #000 !important

.hidden-header
	.header__bar
		transform: translateY(-110px) !important
		transition: all 0.3s ease-in-out
		@media screen and (max-width: $md)
			transform: translateY(0) !important
	#wws-layout-4
		opacity: 0
		+trans1

#wws-layout-4
	+trans1

.lenis-stopped_
	.wws-popup-container
		opacity: 0
		+trans1

.wws-popup-container_
	+trans1

.pointer
	cursor: pointer

/* VALORES */

.valores
	h6
		margin: 0
	h4
		margin-top: 0px
		font-weight: 300
		margin-bottom: 30px
	h4:last-child
		margin-bottom: 0px
	p
		margin: 0px
		line-height: 1.4

.col-fix
	width: auto !important

.p-fix
	+fluid('padding-bottom', 70, 80)
	+fluid('padding-top', 70, 80)
	+fluid('padding-left', 20, 60)
	+fluid('padding-right', 20, 60)
	@media screen and (max-width: $lg)

/* SLIDER */

.section-fullheight
	&.header_img
		.section-fullheight__inner
			+fluid('height', 120, 320)
	&.header_img_2
		.section-fullheight__inner
			+fluid('height', 160, 400)
	&.header_img_3
		.section-fullheight__inner
			@media screen and (max-width: $sm)
				min-height: 600px

	.line-top
		margin-top: 40px
	.of-cover-absolute
		object-position: right
	strong
		font-weight: 600 !important

/* GALERRY */

.gallery-auto
	.swiper-slide
		@media screen and (min-width: $sm)
			width: auto !important

/* SCROLL DOWN */

.scrolldown
	.line
		width: 1px
		height: 60px
		position: absolute
		bottom: 40px
		left: 0
		right: 0
		margin: 0 auto
		overflow: hidden
		background: linear-gradient(to bottom, var(--color-orange) 50%, rgba(255, 255, 255, 0) 50%)
		background-position: 0 -60px
		background-size: 100% 200%
		animation: scrolldown 3s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite

	.text
		color: var(--color-orange)
		font-size: 13px
		font-weight: bold
		letter-spacing: 1px
		text-transform: uppercase

@keyframes scrolldown
	0%
		background-position: 0 -60px

	75%
		background-position: 0 0

	100%
		background-position: 0 60px

/* SWIPPER  */
.swiper-pagination
	margin-top: 30px !important
.swiper-pagination-bullet-active
	background: var(--color-orange)
.swiper-button-next::after, .swiper-button-prev::after
	display: none
.swiper-button-next, .swiper-button-prev
	.svg-arrow path
		fill: var(--color-orange)

@media screen and (max-width: $xs)
	.wws-popup__open-btn
		width: 46px !important
		height: 45px !important
		svg
			width: 25px !important

.section-fullheight__inner.h-80vh
	@media screen and (max-width: $lg)
		min-height: auto

.gmap
	@media screen and (max-width: $lg)
		height: 500px !important

.comillas
	+fluid('width', 50, 80)

.px-fix
	+fluid('padding-left', 20, 80)
	+fluid('padding-right', 20, 80)

.logo__final
	+fluid('width', 150, 220)
	@media screen and (max-width: $md)
		display: none

.inscribete
	background: var(--color-orange)
	border: none !important
	padding: 0 !important
	.button__label
		color: #fff !important
		text-transform: uppercase
		letter-spacing: 1px
		font-weight: bold
		font-size: 13px
		padding: 6px 20px

.pasado
	cursor: not-allowed
	opacity: 0.6

/* HORARIOS */	

.event_header
	display: none !important

.tt_wrapper
	.tt_tabs_navigation
		a
			font-size: 26px !important
	.before_hour_text
		font-size: 14px !important
		font-weight: 500 !important
		color: #fff !important
	.tt_tabs_navigation li a:hover, .tt_tabs_navigation li a.selected, .tt_tabs_navigation li.ui-tabs-active a
		border-bottom-width: 2px !important
	.tt_timetable .event, .tt_timetable .event:hover, .use_colors .tt_items_list li
		background: var(--color-yellow-2)
	.tt_timetable th, .tt_timetable td, .tt_timetable span
		color: #000 !important
		font-weight: 500
	.tt_timetable .hours
		font-size: 13px
		line-height: 1 !important
	.bottom_hour
		margin-bottom: 10px !important
	.top_hour
		margin-top: 0px !important
	.before_hour_text
		text-decoration: underline
	.tt_timetable .event_container
		padding: 20px 10px
	.tt_timetable th
		padding: 15px 15px 15px
		text-transform: uppercase
		font-size: 13px
		letter-spacing: 1px
	.before_hour_text
		color: #000 !important
	.tt_timetable .row_gray, .row_gray th
		background: var(--color-cream) !important
	table.tt_timetable
		border-spacing: 1px !important
		background: #F8f8f8 !important

	.tt_tabs_navigation li a:hover, .tt_tabs_navigation li a.selected, .tt_tabs_navigation li.ui-tabs-active a
		border-bottom: 1.5px solid #000 !important
	.tt_tabs_navigation li a
		border-bottom: none !important
	.tt_responsive .tt_timetable.small.use_colors .tt_items_list a, .tt_responsive .tt_timetable.small.use_colors .tt_items_list span
		color: #000 !important
	.tt_timetable.small.use_colors .box_header
		margin-bottom: 15px !important
		margin-top: 40px
		text-transform: uppercase
		letter-spacing: 1px
		font-size: 14px !important
		font-weight: 600
	.tt_tabs .tt_navigation_cell:first-child
		margin-bottom: 10px !important

/* FOOTER  */	

#page-footer
	p, a
		@media screen and (max-width: $sm)
			margin-bottom: 6px !important
			display: block


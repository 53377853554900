/* SWIPPER */
.swiper-pagination, .swiper-scrollbar-horizontal
	position: relative !important
	+fluid('margin-top', 40, 80)

.swiper-button-next, .swiper-button-prev
	+fluid('width', 40, 50)
	

.swipperGallery
	.swiper-slide
		.lazy-wrapper
			max-width: 900px
			margin: 0 auto



.swipperBeneficios
	p
		opacity: 0
		+trans1
	h2
		color: rgba(#003479, 0.5)	
		+trans1
	.swiper-slide-active
		h2
			color: var(--color-green)
			margin-bottom: 30px
		p
			opacity: 1

.swipperTimeline
	overflow: visible !important
	.swiper-slide:first-child
		.title
			&::after
				width: calc( 100% - 15%)
				left: 15%
	.swiper-slide:last-child
		.title
			&::after
				width: 15%
					
	.title
		margin-bottom: 50px
		&::after
			content: ''
			position: absolute
			width: 100%
			height: 2px
			background: var(--color-green)
			transform: translateY(-6px) translateX(2px)

		> div
			left: 15%
			position: relative
			height: 130px
			.xxl
				padding-right: 20px
		.punto
			width: 12px
			height: 12px
			background: var(--color-green)
			position: relative
			border-radius: 50%
			left: 15%
	.hover-zoom
		.shape-rounded-rectangle
			&::after
				content: ''
				height: 100%
				width: 100%
				position: absolute
				top: 0
				border: solid var(--color-green)
				+fluid('border-width', 6, 10)
				z-index: 999
				border-radius: 200em
				opacity: 0
				visibility: hidden
				+trans1
		.paragraph
			transform: translateX(-40px)
			+trans2
			padding-left: 20px
			+fluid('padding-left', 15, 20)
			+fluid('padding-right', 40, 120)
			opacity: 0
			visibility: hidden
			p
				margin-top: 0px
			@media screen and (max-width: $xs)
				opacity: 1
				visibility: visible
				transform: translateX(0px)
				
		&:hover
			.shape-rounded-rectangle
				&::after
					opacity: 1
					visibility: visible
			.paragraph
				transform: translateX(0px)
				opacity: 1
				visibility: visible


.swipperRecipe
	+fluid('padding-right', 15, 50)	
	+fluid('padding-left', 15, 50)	
	+fluid('padding-bottom', 50, 60)	

	.swiper-slide 
		width: 380px	
		@media screen and (max-width: $xs)
			width: 100%



.swiper-scrollbar	
	max-width: 600px !important	
	margin-left: auto
	margin-right: auto
	height: 3px !important
	.swiper-scrollbar-drag
		background: var(--color-blue)









+min-screen-xs
	.swiper-home
		.swiper-slide
			display: flex
			justify-content: center
			align-items: center
			width: auto !important
		.swiper-slide img
			display: block
			object-fit: cover
			+fluid('height', 500, 700)
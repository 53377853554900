.accordion
	.accordion-item-header
		&:hover
			background: var(--color-gray-2)
			+trans1
		&.active
			padding-bottom: 0px
			background: var(--color-gray-2)
			.postura
				.lazy-wrapper
					width: 100%
			.arrow
				transform: rotate(0.5turn)

	.line
		border: 1.5px solid var(--color-gray-1)
	.postura
		position: absolute
		right: 0px
		display: flex
		justify-content: end
		@media screen and (max-width: $md)
			position: relative
			justify-content: center
			margin-top: 20px
			right:  auto
		.lazy-wrapper
			width: 125px
			+trans1
	.arrow
		margin-top: 20px
		+trans1		
		+fluid('width', 20, 28)

	.titulo
		transform: translateY(20px)
		@media screen and (max-width: $md)
			display: flex
			flex-direction: column
			align-items: center
			transform: translateY(0px)
			margin-top: 20px
	.titulo
		h6, h2
			margin: 0
		h6
			padding-top: 15px

	.accordion-item
		background-color: #fff

	.accordion-item-header
		position: relative
		cursor: pointer
		padding-right: 25px
		padding-top: 35px
		padding-bottom: 35px
		transition: padding 0.3s ease
		@media screen and (max-width: $lg)
			padding-top: 25px
			padding-bottom: 25px
			padding-right: 20px
			padding-left: 20px
		&_::after
			content: "\002B"
			font-size: 2rem
			position: absolute
			right: 1rem

		&.active_::after
			content: "\2212"

	.accordion-item-body
		max-height: 0
		overflow: hidden
		transition: max-height 0.2s ease-out

	.accordion-item-body-content
		background: var(--color-gray-2)
		padding-bottom: 50px
		padding-right: 50px
		@media screen and (max-width: $xl)
			padding-left: 40px
			padding-right: 40px
			padding-top: 30px
		@media screen and (max-width: $lg)
			padding-left: 20px
			padding-right: 20px
			
				
			
	
		.items
			display: flex
			flex-flow: row wrap
			width: 500px
			@media screen and (max-width: $lg)
				margin-bottom: 10px
			.item
				width: 240px
				padding-right: 20px
				margin-bottom: 15px
				@media screen and (max-width: $lg)
					width: 100%
					padding-right: 0
					margin-bottom: 10px
		p:first-child
			margin-top: 0px
		p
			+fluid('font-size', 15, 18)
		h6
			+fluid('font-size', 14, 14)
			letter-spacing: 0px
			font-weight: bold !important
			margin: 0

		//padding: 1rem
		//line-height: 1.5rem



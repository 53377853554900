.horizontal-scroll
	display: flex
	&.has-horizontal-scroll
		overflow: hidden
		.js-horizontal-scroll__wrapper .js-horizontal-scroll__section
			transform: translate3d(var(--translateX), var(--translateY), 0)
		.horizontal-scroll__wrapper
			flex-wrap: nowrap
			height: 100vh
			width: 100%
		.horizontal-scroll__section
			display: flex
			flex-direction: column
			justify-content: center
			height: 100%
		.horizontal-scroll__divider
			display: none

.horizontal-scroll__bordered
	box-shadow: 0px 0 0 0 var(--color-borders), 0 0 0 0 var(--color-borders), 1px 1px 0 0 var(--color-borders), 0px 0 0 0 var(--color-borders) inset, 0 0 0 0 var(--color-borders) inset
.horizontal-scroll__wrapper
	position: relative
	display: flex
	width: 100%

.horizontal-scroll__section
	position: relative
.horizontal-scroll__header
	display: flex
	flex-wrap: wrap
	align-items: stretch
	width: 100%
	height: 100%
.horizontal-scroll__divider
	width: 100%
	display: block
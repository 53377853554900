.color-accent
	color: var(--color-accent) !important
.color-accent-dark-theme
	color: var(--color-accent-dark-theme) !important
.color-accent-light-theme
	color: var(--color-accent-light-theme) !important

.color-light-theme
	color: var(--paragraph-color-light-theme) !important
.color-dark-theme
	color: var(--paragraph-color-dark-theme) !important

.custom-modal
	--duracion: .4s
	--offset: 300px, 0
	position: fixed
	z-index: 999
	width: 100%
	right: 0
	height: 100%
	display: none
	backdrop-filter: blur(2px)
	overflow-y: scroll
	overflow-x: hidden
	.container
		padding: 0px
		display: flex
		min-height: 100%
		max-width: 70vw
		margin-right: 0
		&.background-1
			background: var(--color-yellow)	
		&.background-2
			background: var(--color-green)			
		@media screen and (max-width: $xl)
			max-width:80vw !important

		@media screen and (max-width: $md)
			max-width: 100% !important
		.modal-center
			display: flex
			align-items: center
			width: 100%
			max-width: 1000px
			margin: 0 auto
		.bg-content-white	
			position: relative
			width: 100%
			+fluid('padding-left', 15, 30)
			+fluid('padding-right', 15, 30)
			+fluid('padding-top', 80, 160)
			+fluid('padding-bottom', 80, 160)
			p, a
				+fluid('font-size', 17, 19)
				z-index: 99
				position: relative
				color: #000
				





	&.show
		display: block
		animation: show-modal var(--duracion) ease forwards
		section
			animation: reset-translate var(--duracion) ease forwards
		&.hidde
			animation: hidde-modal var(--duracion) ease forwards
			section
				animation: set-translate var(--duracion) ease forwards
	.close-btn
		position: absolute
		font-size: 3rem
		right: 20px
		top: 35px
		background: none
		border: none
		appearance: none
		color: var(--color-dark-2)
		z-index: 99
		cursor: pointer
		@media screen and (max-width: $md)
			font-size: 2.5rem
			
	
@keyframes show-modal 
	from
		opacity: 0
	to
		opacity: 1

@keyframes reset-translate
	from
		transform: translate(var(--offset))
	to
		transform: translate(0)

@keyframes hidde-modal 
	from
		opacity: 1
	to
		opacity: 0

@keyframes set-translate
	from
		transform: translate(0)
	to
		transform: translate(var(--offset))





body.overflow-hidden
	.wws-popup-container
		opacity: 0
	header
		.header__bar_sticky
			background: transparent !important
			box-shadow: none !important	



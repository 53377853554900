.header
	&:not(.opened)
		.header__label-burger_closed-hover, .header__label-burger_opened-open, .header__label-burger_opened-hover
			transform: translateY(100%)
			opacity: 0
		.header__burger
			&:hover
				.header__label-burger_closed-open
					transform: translateY(-100%)
					opacity: 0
				.header__label-burger_closed-hover
					transform: translateY(0%)
					opacity: 1
	&.opened
		.header__label-burger_closed-open, .header__label-burger_closed-hover, .header__label-burger_opened-hover
			transform: translateY(100%)
			opacity: 0
		.header__burger
			&:hover
				.header__label-burger_opened-open
					transform: translateY(-100%)
					opacity: 0
				.header__label-burger_opened-hover
					transform: translateY(0%)
					opacity: 1
.header__burger-wrapper-lines
	position: relative
	display: flex
	height: 40px
	flex-direction: column
	justify-content: center
.header__burger
	display: inline-flex
	align-items: center
	justify-content: center
	position: relative
	vertical-align: middle
	cursor: pointer
	pointer-events: initial
	z-index: 500
	background: none
	outline: none
	&:hover
		.header__burger-line
			&:before
				transform: translateX(100%)
			&:after
				transform: translateX(0%)
.header__burger-label
	display: inline-block
	line-height: 1
	position: relative
	text-align: right
	transition: transform 0.3s ease
	color: currentColor
.header__label-burger_inner
	position: absolute
	top: 0
	right: 0
	+trans1
.header__label-burger_closed-open
	position: relative

.header__burger-line
	position: relative
	@include fluid('width', 30, 40)
	height: 2px
	margin-top: 2px
	margin-bottom: 2px
	margin-left: auto
	margin-right: auto
	overflow: hidden
	+trans2
	&:nth-of-type(1)
		&:before, &:after
			transition-delay: 0ms
	&:nth-of-type(2)
		&:before, &:after
			transition-delay: 50ms
	&:nth-of-type(3)
		&:before, &:after
			transition-delay: 100ms
	&:first-of-type
		margin-top: auto
		margin-bottom: 2px
	&:last-of-type
		margin-top: 2px
		margin-bottom: auto
	&:before
		content: ''
		width: 100%
		height: 100%
		position: absolute
		top: 0
		left: 0
		transform: translateX(0%)
		background-color: var(--color-orange)
		transition: transform 0.2s ease
	&:after
		content: ''
		width: 100%
		height: 100%
		position: absolute
		top: 0
		left: 0
		transform: translateX(calc(-100% - 4px))
		background-color: currentColor
		transition: transform 0.4s ease
.header__burger_opened, .header__burger_opened:hover
	.header__burger-line
		&:nth-of-type(1)
			transform: scaleX(1) rotate(45deg) translate(2px, 2px)
		&:nth-of-type(2)
			transform: scaleX(1) rotate(-45deg) translate(2px, -2px)
		&:nth-of-type(3)
			transform-origin: right center
			transform: scaleX(0)
.header__burger_opened
	.header__label-burger_closed-open, .header__label-burger_closed-hover
		transform: translateY(-100%) !important
		opacity: 0 !important

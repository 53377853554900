.persiana
	height: 4rem
	overflow: hidden
	position: relative
	&.footer
		height: 3rem
		.persiana-item
			+fluid('font-size', 34, 48)
			font-weight: 300
			color: var(--color-orange)
			letter-spacing: -1px !important
	.persiana-item
		margin: 0
		position: absolute
		top: 0
		+fluid('font-size', 40, 56)
		font-weight: bold
		


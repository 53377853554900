@charset "UTF-8";
html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  -webkit-text-rendering: optimizeSpeed;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: none;
  scroll-behavior: initial !important;
  width: 100%;
  min-height: 100%;
  scrollbar-gutter: stable;
}

body {
  width: 100%;
  min-height: 100%;
  min-width: 320px;
  font-family: var(--paragraph-font-family);
  line-height: calc(1 * (var(--paragraph-min-line-height) * 1px));
  color: var(--paragraph-color);
  font-size: 100%;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  overflow-x: hidden;
}
@media screen and (min-width: 360px) {
  body {
    line-height: calc(1 * (var(--paragraph-min-line-height) * 1px + (var(--paragraph-max-line-height) - var(--paragraph-min-line-height)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  body {
    line-height: calc(1 * (var(--paragraph-max-line-height) * 1px));
  }
}

.lock-scroll body {
  overflow: hidden !important;
}

img, iframe {
  max-width: 100%;
}

img {
  display: block;
  height: auto;
}

.page-wrapper {
  position: relative;
  z-index: 100;
  overflow: hidden;
}

.page-wrapper__content {
  position: relative;
  z-index: 100;
  overflow: hidden;
}

[tabindex] {
  outline: none;
}

.d-flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-inline-flex-centered {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Typography */
:root {
  --font-primary: "Formular", sans-serif;
  --font-secondary: "Formular", serif;
  --color-orange: #F87221;
  --color-orange-2: #fdcdc3;
  --color-rose: #fce1da;
  --color-cream: #F3ECE6;
  --color-green: #bccfc9;
  --color-green-2: #f2ffeb;
  --color-red: #fdcdc3;
  --color-purple: #ecddfe;
  --color-yellow: #f6d272;
  --color-yellow-2: #fdedbc;
  /* Paragraph */
  --paragraph-font-family: var(--font-primary);
  --paragraph-min-font-size: 16;
  --paragraph-max-font-size: 18;
  --paragraph-min-line-height: 24;
  --paragraph-max-line-height: 32;
  --paragraph-letter-spacing: 0;
  --paragraph-color-light-theme: #000000;
  --paragraph-color-dark-theme: #dfdfdf;
  --paragraph-color: var(--paragraph-color-light-theme);
  /* Buttons */
  --ui-element-font-family: var(--font-primary);
  --ui-element-min-font-size: 14;
  --ui-element-max-font-size: 16;
  --ui-element-font-weight: 600;
  --ui-element-line-height: 1;
  --ui-element-letter-spacing: 0;
  --ui-element-color-light-theme: #000000;
  --ui-element-color-dark-theme: #fff;
  --ui-element-color: var(--ui-element-color-light-theme);
  /* Display XXL * */
  --display-xxl-font-family: var(--font-secondary);
  --display-xxl-max-font-size: 288;
  --display-xxl-min-font-size: 80;
  --display-xxl-font-weight: 200;
  --display-xxl-line-height: 1.2;
  --display-xxl-letter-spacing: -0.02em;
  --display-xxl-color-light-theme: #000000;
  --display-xxl-color-dark-theme: #ffffff;
  --display-xxl-color: var(--display-xxl-color-light-theme);
  /* Display XL * */
  --display-xl-font-family: var(--font-secondary);
  --display-xl-max-font-size: 216;
  --display-xl-min-font-size: 80;
  --display-xl-font-weight: 200;
  --display-xl-line-height: 1.2;
  --display-xl-letter-spacing: -0.02em;
  --display-xl-color-light-theme: #000000;
  --display-xl-color-dark-theme: #ffffff;
  --display-xl-color: var(--display-xl-color-light-theme);
  /* Display caps */
  --display-caps-font-family: var(--font-secondary);
  --display-caps-max-font-size: 144;
  --display-caps-min-font-size: 48;
  --display-caps-font-weight: 200;
  --display-caps-line-height: 1.2;
  --display-caps-letter-spacing: 0.06em;
  --display-caps-color-light-theme: #000000;
  --display-caps-color-dark-theme: #ffffff;
  --display-caps-color: var(--display-caps-color-light-theme);
  /* Heading 1 */
  --h1-font-family: var(--font-secondary);
  --h1-max-font-size: 90;
  --h1-min-font-size: 46;
  --h1-font-weight: 200;
  --h1-line-height: 1.15;
  --h1-letter-spacing: -1.5px;
  --h1-color-light-theme: #000000;
  --h1-color-dark-theme: #ffffff;
  --h1-color: var(--h1-color-light-theme);
  /* Heading 2 */
  --h2-font-family: var(--font-secondary);
  --h2-max-font-size: 54;
  --h2-min-font-size: 36;
  --h2-font-weight: 300;
  --h2-line-height: 1.3;
  --h2-letter-spacing: -1px;
  --h2-color-light-theme: #000000;
  --h2-color-dark-theme: #ffffff;
  --h2-color: var(--h2-color-light-theme);
  /* Heading 3 */
  --h3-font-family: var(--font-secondary);
  --h3-max-font-size: 40;
  --h3-min-font-size: 28;
  --h3-font-weight: 400;
  --h3-line-height: 1.3;
  --h3-letter-spacing: -0.5px;
  --h3-color-light-theme: #000000;
  --h3-color-dark-theme: #ffffff;
  --h3-color: var(--h3-color-light-theme);
  /* Heading 4 */
  --h4-font-family: var(--font-secondary);
  --h4-max-font-size: 34;
  --h4-min-font-size: 28;
  --h4-font-weight: 300;
  --h4-line-height: 1.3;
  --h4-letter-spacing: -0.5px;
  --h4-color-light-theme: #000000;
  --h4-color-dark-theme: #ffffff;
  --h4-color: var(--h4-color-light-theme);
  /* Heading 5 */
  --h5-font-family: var(--font-primary);
  --h5-max-font-size: 17;
  --h5-min-font-size: 16;
  --h5-font-weight: 700;
  --h5-line-height: 1.3;
  --h5-letter-spacing: 1px;
  --h5-color-light-theme: #000000;
  --h5-color-dark-theme: #ffffff;
  --h5-color: var(--h5-color-light-theme);
  /* Heading 6 */
  --h6-font-family: var(--font-primary);
  --h6-max-font-size: 15;
  --h6-min-font-size: 15;
  --h6-font-weight: 700;
  --h6-line-height: 1.4;
  --h6-letter-spacing: 1px;
  --h6-color-light-theme: #000000;
  --h6-color-dark-theme: #ffffff;
  --h6-color: var(--h6-color-light-theme);
  /* Blockquote */
  --blockquote-font-family: var(--font-primary);
  --blockquote-max-font-size: 24;
  --blockquote-min-font-size: 18;
  --blockquote-font-weight: 400;
  --blockquote-line-height: 1.33;
  --blockquote-letter-spacing: 0;
  --blockquote-color-light-theme: #000000;
  --blockquote-color-dark-theme: #ffffff;
  --blockquote-color: var(--blockquote-color-light-theme);
  /* Dropcap */
  --dropcap-font-family: var(--font-secondary);
  --dropcap-max-font-size: 112;
  --dropcap-min-font-size: 52;
  --dropcap-font-weight: 200;
  --dropcap-line-height: 0.7;
  --dropcap-letter-spacing: 0;
  --dropcap-color-light-theme: #000000;
  --dropcap-color-dark-theme: #ffffff;
  --dropcap-color: var(--dropcap-color-light-theme);
  /* Subheading */
  --subheading-font-family: var(--font-primary);
  --subheading-max-font-size: 13;
  --subheading-min-font-size: 11;
  --subheading-font-weight: 700;
  --subheading-line-height: 1.3;
  --subheading-letter-spacing: 0.15em;
  --subheading-color-light-theme: #000000;
  --subheading-color-dark-theme: #dfdfdf;
  --subheading-color: var(--subheading-color-light-theme);
  /* Overlay menu item */
  --overlay-menu-item-font-family: var(--font-secondary);
  --overlay-menu-item-max-font-size: 30;
  --overlay-menu-item-min-font-size: 20;
  --overlay-menu-item-font-weight: 500;
  --overlay-menu-item-line-height: 1.2;
  --overlay-menu-item-letter-spacing: -0.01em;
  --overlay-menu-item-color-light-theme: #000000;
  --overlay-menu-item-color-dark-theme: #ffffff;
  --overlay-menu-item-color: var(--overlay-menu-item-color-light-theme);
  /* Overlay submenu item */
  --overlay-submenu-item-font-family: var(--font-secondary);
  --overlay-submenu-item-max-font-size: 54;
  --overlay-submenu-item-min-font-size: 32;
  --overlay-submenu-item-font-weight: 300;
  --overlay-submenu-item-line-height: 1.3;
  --overlay-submenu-item-letter-spacing: 0;
  --overlay-submenu-item-color-light-theme: #000000;
  --overlay-submenu-item-color-dark-theme: #ffffff;
  --overlay-submenu-item-color: var(--overlay-submenu-item-color-light-theme);
  /* Super label */
  --super-label-font-family: var(--font-primary);
  --super-label-max-font-size: 18;
  --super-label-min-font-size: 12;
  --super-label-font-weight: 700;
  --super-label-line-height: 1;
  --super-label-letter-spacing: 0;
  --super-label-color-light-theme: #000000;
  --super-label-color-dark-theme: #ffffff;
  --super-label-color: var(--super-label-color-light-theme);
}

/* Distances */
:root {
  /* Fluid paddings & margins (min values) */
  --distance-min-xxsmall: 20;
  --distance-min-xsmall: 30;
  --distance-min-small: 50;
  --distance-min-medium: 50;
  --distance-min-large: 50;
  --distance-min-xlarge: 90;
  /* Fluid paddings & margins (max values) */
  --distance-max-xxsmall: 30;
  --distance-max-xsmall: 40;
  --distance-max-small: 90;
  --distance-max-medium: 120;
  --distance-max-large: 180;
  --distance-max-xlarge: 240;
  /* Container & page gutters */
  --gutter-horizontal-min: 20;
  --gutter-horizontal-max: 80;
  --gutter-vertical-min: 20;
  --gutter-vertical-max: 80;
  --gutter-horizontal: calc(1 * (var(--gutter-horizontal-min) * 1px));
  --gutter-vertical: calc(1 * (var(--gutter-vertical-min) * 1px));
  /* Bootstrap */
  --bs-gutter-x: 20px;
  --bs-gutter-y: 20px;
}
@media screen and (min-width: 360px) {
  :root {
    --gutter-horizontal: calc(1 * (var(--gutter-horizontal-min) * 1px + (var(--gutter-horizontal-max) - var(--gutter-horizontal-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  :root {
    --gutter-horizontal: calc(1 * (var(--gutter-horizontal-max) * 1px));
  }
}
@media screen and (min-width: 360px) {
  :root {
    --gutter-vertical: calc(1 * (var(--gutter-vertical-min) * 1px + (var(--gutter-vertical-max) - var(--gutter-vertical-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  :root {
    --gutter-vertical: calc(1 * (var(--gutter-vertical-max) * 1px));
  }
}

/* Colors */
:root {
  /* Dark colors */
  --color-dark-1: #000000;
  --color-dark-1-rgb: 32, 33, 31;
  --color-dark-2: #1B1A18;
  --color-dark-2-rgb: 27, 26, 24;
  --color-dark-3: #313130;
  --color-dark-3-rgb: 49, 49, 48;
  --color-dark-4: #060707;
  --color-dark-4-rgb: 6, 7, 7;
  /* Gray colors */
  --color-gray-1: #CCCCCC;
  --color-gray-1-rgb: 147, 149, 151;
  --color-gray-2: #F2F2F2;
  --color-gray-2-rgb: 192, 192, 192;
  --color-gray-3: #777777;
  --color-gray-3-rgb: 119, 119, 119;
  --color-gray-4: #888888;
  --color-gray-4-rgb: 136, 136, 136;
  /* Light colors */
  --color-light-1: #DBDAD7;
  --color-light-1-rgb: 219, 218, 215;
  --color-light-2: #f2ece6;
  --color-light-2-rgb: 232, 230, 224;
  --color-light-3: #f3ece6;
  --color-light-3-rgb: 241, 242, 237;
  --color-light-4: #FFFFFF;
  --color-light-4-rgb: 255, 255, 255;
  /* Platinum colors */
  --color-platinum-1: #767B75;
  --color-platinum-1-rgb: 118, 123, 117;
  --color-platinum-2: #686D67;
  --color-platinum-2-rgb: 104, 109, 103;
  /* Accent colors */
  --color-accent-light-theme: #656453;
  --color-accent-light-theme-rgb: 101, 100, 83;
  --color-accent-dark-theme: var(--color-orange);
  --color-accent-dark-theme-rgb: 253, 249, 207;
  --color-accent: var(--color-accent-light-theme);
  --color-accent-rgb: 101, 100, 83;
  /* Border colors */
  --color-border-opacity-light-theme: rgba(32, 33, 32, .2);
  --color-border-opacity-dark-theme: rgba(255, 255, 255, .2);
  --color-border-opacity: var(--color-border-opacity-light-theme);
  --color-border-solid-light-theme: #000000;
  --color-border-solid-dark-theme: #fff;
  --color-border-solid: var(--color-border-solid-light-theme);
  /* Links colors */
  --color-links-light-theme: #666;
  --color-links-dark-theme: #fff;
  --color-links: var(--color-links-light-theme);
  --color-classic-submenu-links: #000;
  --color-classic-submenu-links-hover: #656453;
  --color-classic-submenu-links-hover-background: #65645315;
}

.pswp {
  --pswp-min-gutters-horizontal: 20;
  --pswp-max-gutters-horizontal: 40;
  --pswp-min-gutters-vertical: 10;
  --pswp-max-gutters-vertical: 30;
}
.pswp[data-arts-color-theme=dark] .pswp__bg {
  background-color: #000;
}
.pswp[data-arts-color-theme=dark] .pswp-custom-caption {
  background-color: #070707;
}
.pswp[data-arts-color-theme=light] .pswp__bg {
  background-color: #fff;
}
.pswp[data-arts-color-theme=light] .pswp-custom-caption {
  background-color: #f8f8f8;
}

.pswp__top-bar {
  height: auto;
}

.pswp__wrapper-embed {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 120px;
  padding-right: calc(1 * (20 * 1px));
  padding-bottom: 120px;
  padding-left: calc(1 * (20 * 1px));
}
@media screen and (min-width: 360px) {
  .pswp__wrapper-embed {
    padding-right: calc(1 * (20 * 1px + (120 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pswp__wrapper-embed {
    padding-right: calc(1 * (120 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .pswp__wrapper-embed {
    padding-left: calc(1 * (20 * 1px + (120 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pswp__wrapper-embed {
    padding-left: calc(1 * (120 * 1px));
  }
}
.pswp__wrapper-embed iframe {
  width: 100%;
  height: 100%;
}
.pswp__wrapper-embed video {
  width: 100%;
  height: auto;
}

.pswp:not(.pswp--zoomed-in) .pswp__zoom-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.pswp-custom-arrow-prev, .pswp-custom-arrow-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.pswp-custom-arrow-prev {
  left: 0;
  padding-left: 15px !important;
}

.pswp-custom-arrow-next {
  right: 0;
  padding-right: 15px !important;
}

.pswp--one-slide .pswp-custom-arrow-prev, .pswp--one-slide .pswp-custom-arrow-next {
  opacity: 0;
  visibility: hidden;
}

.pswp-custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: calc(1 * (var(--pswp-min-gutters-horizontal) * 1px));
  padding-right: calc(1 * (var(--pswp-min-gutters-horizontal) * 1px));
  padding-top: calc(1 * (var(--pswp-min-gutters-vertical) * 1px));
  padding-bottom: calc(1 * (var(--pswp-min-gutters-vertical) * 1px));
  transition: all 0.3s ease;
  z-index: 500;
}
@media screen and (min-width: 360px) {
  .pswp-custom-button {
    padding-left: calc(1 * (var(--pswp-min-gutters-horizontal) * 1px + (var(--pswp-max-gutters-horizontal) - var(--pswp-min-gutters-horizontal)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pswp-custom-button {
    padding-left: calc(1 * (var(--pswp-max-gutters-horizontal) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .pswp-custom-button {
    padding-right: calc(1 * (var(--pswp-min-gutters-horizontal) * 1px + (var(--pswp-max-gutters-horizontal) - var(--pswp-min-gutters-horizontal)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pswp-custom-button {
    padding-right: calc(1 * (var(--pswp-max-gutters-horizontal) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .pswp-custom-button {
    padding-top: calc(1 * (var(--pswp-min-gutters-vertical) * 1px + (var(--pswp-max-gutters-vertical) - var(--pswp-min-gutters-vertical)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pswp-custom-button {
    padding-top: calc(1 * (var(--pswp-max-gutters-vertical) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .pswp-custom-button {
    padding-bottom: calc(1 * (var(--pswp-min-gutters-vertical) * 1px + (var(--pswp-max-gutters-vertical) - var(--pswp-min-gutters-vertical)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pswp-custom-button {
    padding-bottom: calc(1 * (var(--pswp-max-gutters-vertical) * 1px));
  }
}

.pswp-custom-button__label {
  position: relative;
  white-space: nowrap;
  transition: transform 0.3s ease;
}

.pswp-custom-button_left {
  margin-right: auto;
}

.pswp-custom-button_center {
  margin-left: auto;
  margin-right: auto;
}

.has-cursor-follower .pswp-custom-button_left[data-arts-cursor-follower-target]:hover .pswp-custom-button__label {
  transform: translateX(20px);
}
.has-cursor-follower .pswp-custom-button_right[data-arts-cursor-follower-target]:hover .pswp-custom-button__label, .has-cursor-follower .pswp-custom-button_center[data-arts-cursor-follower-target]:hover .pswp-custom-button__label {
  transform: translateX(-20px);
}

.pswp-custom-button_close {
  order: 10;
}
.pswp-custom-button_close:hover .pswp-custom-button__close-label-close {
  transform: translateY(-100%);
  opacity: 0;
}
.pswp-custom-button_close:hover .pswp-custom-button__close-label-hover {
  transform: translateY(0%);
  opacity: 1;
}

.pswp-custom-button__close-label-close {
  position: relative;
  transform: translateY(0%);
  opacity: 1;
  transition: all 0.3s ease;
}

.pswp-custom-button__close-label-hover {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.3s ease;
}

.pswp-custom-button__close-line:nth-of-type(1) {
  transform: scaleX(1) rotate(45deg) translate(2px, 2px);
}
.pswp-custom-button__close-line:nth-of-type(2) {
  transform: scaleX(1) rotate(-45deg) translate(2px, -2px);
}
.pswp-custom-button__close-line:nth-of-type(3) {
  transform-origin: right center;
  transform: scaleX(0);
}

.pswp-custom-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
  text-align: center;
  background-color: #000;
}
.pswp-custom-caption a {
  color: var(--color-accent);
}

.pswp-custom-caption__content {
  display: inline-block;
  max-width: 600px;
  padding: 20px 0;
}

.pswp-custom-button_counter {
  transition: opacity 0.3s ease;
  opacity: 1;
  visibility: visible;
}

.pswp--one-slide .pswp-custom-button_counter {
  opacity: 0;
  visibility: hidden;
}

.pswp-custom-preloader {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: calc(var(--pswp-max-gutters-vertical) * 1px);
  z-index: 50 !important;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}
.pswp-custom-preloader svg {
  width: 30px;
  height: 30px;
  z-index: 10000;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  color: var(--color-accent);
}
.pswp-custom-preloader svg circle {
  stroke: currentColor;
  stroke-width: 2px;
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -150;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

.pswp__preloader--active.pswp-custom-preloader {
  opacity: 1;
  visibility: visible;
}

.pswp-custom-button_zoom {
  transition: opacity 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.pswp-custom-button__zoom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.pswp--zoom-allowed .pswp-custom-button_zoom {
  opacity: 1;
  visibility: visible;
}

.blocking-curtain {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: none;
}

[data-barba=wrapper] {
  height: 100%;
  overflow: hidden;
}

.page-wrapper + .page-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: -1;
}
.page-wrapper + .page-wrapper .page-wrapper__content {
  opacity: 0;
}

/* HEADER */
.header .header__bar::after {
  content: "";
  width: calc(100% - 50px);
  height: 1px;
  background: var(--color-orange);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .header .header__bar::after {
    width: calc(100% - 40px);
  }
}
.header .header__bar a, .header .header__bar a:hover {
  color: var(--color-orange);
}
.header .header__bar .menu-item a {
  text-transform: uppercase;
  font-size: 15px;
}
@media screen and (max-width: 1400px) {
  .header .header__bar .menu-item a {
    font-size: 14.5px;
  }
}
@media screen and (max-width: 1100px) {
  .header .header__bar .menu-item a .esconder {
    display: none;
  }
}
.header .header__bar .menu-item .instagram {
  padding: 0;
  position: relative;
  top: 4px;
  margin-right: 0px;
  margin-left: 10px;
}
@media screen and (max-width: 1200px) {
  .header .header__bar .menu-item .instagram {
    margin-left: 5px;
  }
}
.header .header__bar .menu-item .modalContact {
  background: var(--color-orange);
  padding: 2px 25px;
  border: none;
}
@media screen and (max-width: 1200px) {
  .header .header__bar .menu-item .modalContact {
    padding: 2px 15px;
  }
}
.header .header__bar .menu-item .modalContact .button__label {
  padding: 0px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
@media screen and (max-width: 1200px) {
  .header .header__bar .menu-item .modalContact .button__label {
    font-size: 13px;
  }
}
.header .logo__img-primary {
  width: calc(1 * (100 * 1px));
}
@media screen and (min-width: 360px) {
  .header .logo__img-primary {
    width: calc(1 * (100 * 1px + (130 - 100) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .header .logo__img-primary {
    width: calc(1 * (130 * 1px));
  }
}
.header .header__bar_sticky .logo__img-primary {
  width: 110px;
}
.header .header__bar_sticky a, .header .header__bar_sticky a:hover {
  color: var(--color-orange) !important;
}
.header .header__bar_sticky .header__col {
  padding-top: 18px;
  padding-bottom: 18px;
}
.header .header__bar_sticky .header__col a {
  font-size: calc(1 * (13.5 * 1px));
  transition: all 0.3s ease;
}
@media screen and (min-width: 360px) {
  .header .header__bar_sticky .header__col a {
    font-size: calc(1 * (13.5 * 1px + (14.5 - 13.5) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .header .header__bar_sticky .header__col a {
    font-size: calc(1 * (14.5 * 1px));
  }
}
.header .header__bar_sticky .header__col .modalContact .button__label {
  font-size: calc(1 * (12.5 * 1px));
}
@media screen and (min-width: 360px) {
  .header .header__bar_sticky .header__col .modalContact .button__label {
    font-size: calc(1 * (12.5 * 1px + (13.5 - 12.5) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .header .header__bar_sticky .header__col .modalContact .button__label {
    font-size: calc(1 * (13.5 * 1px));
  }
}

.header__bar[data-arts-color-theme=dark] .menu-item a, .header__bar[data-arts-color-theme=dark] .menu-item a:hover {
  color: #fff;
}
.header__bar[data-arts-color-theme=dark]::after {
  background: #fff;
}

.header__bar[data-arts-color-theme=light] .menu-item a {
  color: var(--color-orange) !important;
}

/* VARIOS */
.small {
  font-size: calc(1 * (15 * 1px));
  line-height: 1.4;
}
@media screen and (min-width: 360px) {
  .small {
    font-size: calc(1 * (15 * 1px + (16 - 15) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .small {
    font-size: calc(1 * (16 * 1px));
  }
}

.color-orange {
  color: var(--color-orange) !important;
}

.span-border-bottom {
  line-height: 1.5;
}
.span-border-bottom span {
  position: relative;
}
.span-border-bottom span::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: var(--color-orange);
  bottom: -2px;
  left: 0;
}

.bg-orange {
  background: var(--color-orange);
}

.bg-orange-2 {
  background: var(--color-orange-2);
}

.bg-cream {
  background: var(--color-cream);
}

.bg-rose {
  background: var(--color-rose);
}

.bg-green {
  background: var(--color-green);
}

.bg-green-2 {
  background: var(--color-green-2);
}

.bg-red {
  background: var(--color-red);
}

.bg-purple {
  background: var(--color-purple);
}

.bg-yellow {
  background: var(--color-yellow);
}

.bg-yellow-2 {
  background: var(--color-yellow-2);
}

.line-top {
  width: 90px;
  border: 1px solid var(--color-orange);
}

.line-top-2 {
  width: 2px;
  background: var(--color-orange);
  margin-right: 20px;
}

.line-top-3 {
  width: 100%;
  border: 0.5px solid var(--color-orange);
}

.line-top-4 {
  width: 1px;
  height: 100%;
  background: var(--color-orange);
}

.line-footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 1px;
  display: block;
  background: var(--color-orange);
  width: calc(100% - 40px);
}
.line-footer::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 1px;
  display: block;
  background: var(--color-orange);
  width: calc(100% - 40px);
}

.point {
  width: 8px;
  height: 8px;
  background: var(--color-orange);
  border-radius: 50%;
  margin-right: 10px;
}

.fix-height-p {
  min-height: 123px;
}
@media screen and (max-width: 991px) {
  .fix-height-p {
    min-height: auto;
  }
}

.bg-offset {
  position: relative;
  overflow: hidden;
}
.bg-offset::before {
  content: "";
  bottom: 0;
  width: 100%;
  height: 50%;
  background: #fff;
  position: absolute;
}

.bg-offset-2 {
  position: relative;
  overflow: hidden;
}
.bg-offset-2::before {
  content: "";
  top: 0;
  width: 100%;
  height: 40%;
  background: #fff;
  position: absolute;
}
@media screen and (max-width: 1200px) {
  .bg-offset-2::before {
    height: 55%;
  }
}
@media screen and (max-width: 1100px) {
  .bg-offset-2::before {
    height: 60%;
  }
}
@media screen and (max-width: 991px) {
  .bg-offset-2::before {
    height: 70%;
  }
}
@media screen and (max-width: 767px) {
  .bg-offset-2::before {
    height: 60%;
  }
}

.fw-300 {
  font-weight: 300 !important;
}

.sub strong {
  border-bottom: 3px solid #000;
}

.color-orange-strong strong {
  color: var(--color-orange);
  display: inline;
}

.paragraph-pb p {
  margin-top: 0px;
}

.xl {
  font-size: calc(1 * (50 * 1px));
  line-height: 0.9;
  font-weight: 300;
  letter-spacing: -2px;
}
@media screen and (min-width: 360px) {
  .xl {
    font-size: calc(1 * (50 * 1px + (80 - 50) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .xl {
    font-size: calc(1 * (80 * 1px));
  }
}

.paragraph-2, .paragraph-2 p {
  font-size: calc(1 * (17 * 1px));
}
@media screen and (min-width: 360px) {
  .paragraph-2, .paragraph-2 p {
    font-size: calc(1 * (17 * 1px + (18 - 17) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .paragraph-2, .paragraph-2 p {
    font-size: calc(1 * (18 * 1px));
  }
}

strong {
  font-weight: bold;
}

.color-white {
  color: #fff !important;
}

.scroll {
  position: absolute;
  top: -20px;
}

.header__bar {
  transition: all 0.3s ease-in-out !important;
}

.header__widget-title {
  color: var(--color-orange) !important;
  font-size: 13px !important;
}

.header__widget-content, .header__widget-content a {
  color: #000 !important;
}

.hidden-header .header__bar {
  transform: translateY(-110px) !important;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 991px) {
  .hidden-header .header__bar {
    transform: translateY(0) !important;
  }
}
.hidden-header #wws-layout-4 {
  opacity: 0;
  transition: all 0.3s ease;
}

#wws-layout-4 {
  transition: all 0.3s ease;
}

.lenis-stopped_ .wws-popup-container {
  opacity: 0;
  transition: all 0.3s ease;
}

.wws-popup-container_ {
  transition: all 0.3s ease;
}

.pointer {
  cursor: pointer;
}

/* VALORES */
.valores h6 {
  margin: 0;
}
.valores h4 {
  margin-top: 0px;
  font-weight: 300;
  margin-bottom: 30px;
}
.valores h4:last-child {
  margin-bottom: 0px;
}
.valores p {
  margin: 0px;
  line-height: 1.4;
}

.col-fix {
  width: auto !important;
}

.p-fix {
  padding-bottom: calc(1 * (70 * 1px));
  padding-top: calc(1 * (70 * 1px));
  padding-left: calc(1 * (20 * 1px));
  padding-right: calc(1 * (20 * 1px));
}
@media screen and (min-width: 360px) {
  .p-fix {
    padding-bottom: calc(1 * (70 * 1px + (80 - 70) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .p-fix {
    padding-bottom: calc(1 * (80 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .p-fix {
    padding-top: calc(1 * (70 * 1px + (80 - 70) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .p-fix {
    padding-top: calc(1 * (80 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .p-fix {
    padding-left: calc(1 * (20 * 1px + (60 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .p-fix {
    padding-left: calc(1 * (60 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .p-fix {
    padding-right: calc(1 * (20 * 1px + (60 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .p-fix {
    padding-right: calc(1 * (60 * 1px));
  }
}
/* SLIDER */
.section-fullheight.header_img .section-fullheight__inner {
  height: calc(1 * (120 * 1px));
}
@media screen and (min-width: 360px) {
  .section-fullheight.header_img .section-fullheight__inner {
    height: calc(1 * (120 * 1px + (320 - 120) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .section-fullheight.header_img .section-fullheight__inner {
    height: calc(1 * (320 * 1px));
  }
}
.section-fullheight.header_img_2 .section-fullheight__inner {
  height: calc(1 * (160 * 1px));
}
@media screen and (min-width: 360px) {
  .section-fullheight.header_img_2 .section-fullheight__inner {
    height: calc(1 * (160 * 1px + (400 - 160) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .section-fullheight.header_img_2 .section-fullheight__inner {
    height: calc(1 * (400 * 1px));
  }
}
@media screen and (max-width: 767px) {
  .section-fullheight.header_img_3 .section-fullheight__inner {
    min-height: 600px;
  }
}
.section-fullheight .line-top {
  margin-top: 40px;
}
.section-fullheight .of-cover-absolute {
  object-position: right;
}
.section-fullheight strong {
  font-weight: 600 !important;
}

/* GALERRY */
@media screen and (min-width: 767px) {
  .gallery-auto .swiper-slide {
    width: auto !important;
  }
}

/* SCROLL DOWN */
.scrolldown .line {
  width: 1px;
  height: 60px;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
  background: linear-gradient(to bottom, var(--color-orange) 50%, rgba(255, 255, 255, 0) 50%);
  background-position: 0 -60px;
  background-size: 100% 200%;
  animation: scrolldown 3s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
}
.scrolldown .text {
  color: var(--color-orange);
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@keyframes scrolldown {
  0% {
    background-position: 0 -60px;
  }
  75% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 60px;
  }
}
/* SWIPPER  */
.swiper-pagination {
  margin-top: 30px !important;
}

.swiper-pagination-bullet-active {
  background: var(--color-orange);
}

.swiper-button-next::after, .swiper-button-prev::after {
  display: none;
}

.swiper-button-next .svg-arrow path, .swiper-button-prev .svg-arrow path {
  fill: var(--color-orange);
}

@media screen and (max-width: 576px) {
  .wws-popup__open-btn {
    width: 46px !important;
    height: 45px !important;
  }
  .wws-popup__open-btn svg {
    width: 25px !important;
  }
}
@media screen and (max-width: 1100px) {
  .section-fullheight__inner.h-80vh {
    min-height: auto;
  }
}

@media screen and (max-width: 1100px) {
  .gmap {
    height: 500px !important;
  }
}

.comillas {
  width: calc(1 * (50 * 1px));
}
@media screen and (min-width: 360px) {
  .comillas {
    width: calc(1 * (50 * 1px + (80 - 50) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .comillas {
    width: calc(1 * (80 * 1px));
  }
}

.px-fix {
  padding-left: calc(1 * (20 * 1px));
  padding-right: calc(1 * (20 * 1px));
}
@media screen and (min-width: 360px) {
  .px-fix {
    padding-left: calc(1 * (20 * 1px + (80 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-fix {
    padding-left: calc(1 * (80 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .px-fix {
    padding-right: calc(1 * (20 * 1px + (80 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-fix {
    padding-right: calc(1 * (80 * 1px));
  }
}

.logo__final {
  width: calc(1 * (150 * 1px));
}
@media screen and (min-width: 360px) {
  .logo__final {
    width: calc(1 * (150 * 1px + (220 - 150) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .logo__final {
    width: calc(1 * (220 * 1px));
  }
}
@media screen and (max-width: 991px) {
  .logo__final {
    display: none;
  }
}

.inscribete {
  background: var(--color-orange);
  border: none !important;
  padding: 0 !important;
}
.inscribete .button__label {
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 13px;
  padding: 6px 20px;
}

.pasado {
  cursor: not-allowed;
  opacity: 0.6;
}

/* HORARIOS */	
.event_header {
  display: none !important;
}

.tt_wrapper .tt_tabs_navigation a {
  font-size: 26px !important;
}
.tt_wrapper .before_hour_text {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
}
.tt_wrapper .tt_tabs_navigation li a:hover, .tt_wrapper .tt_tabs_navigation li a.selected, .tt_wrapper .tt_tabs_navigation li.ui-tabs-active a {
  border-bottom-width: 2px !important;
}
.tt_wrapper .tt_timetable .event, .tt_wrapper .tt_timetable .event:hover, .tt_wrapper .use_colors .tt_items_list li {
  background: var(--color-yellow-2);
}
.tt_wrapper .tt_timetable th, .tt_wrapper .tt_timetable td, .tt_wrapper .tt_timetable span {
  color: #000 !important;
  font-weight: 500;
}
.tt_wrapper .tt_timetable .hours {
  font-size: 13px;
  line-height: 1 !important;
}
.tt_wrapper .bottom_hour {
  margin-bottom: 10px !important;
}
.tt_wrapper .top_hour {
  margin-top: 0px !important;
}
.tt_wrapper .before_hour_text {
  text-decoration: underline;
}
.tt_wrapper .tt_timetable .event_container {
  padding: 20px 10px;
}
.tt_wrapper .tt_timetable th {
  padding: 15px 15px 15px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
}
.tt_wrapper .before_hour_text {
  color: #000 !important;
}
.tt_wrapper .tt_timetable .row_gray, .tt_wrapper .row_gray th {
  background: var(--color-cream) !important;
}
.tt_wrapper table.tt_timetable {
  border-spacing: 1px !important;
  background: #F8f8f8 !important;
}
.tt_wrapper .tt_tabs_navigation li a:hover, .tt_wrapper .tt_tabs_navigation li a.selected, .tt_wrapper .tt_tabs_navigation li.ui-tabs-active a {
  border-bottom: 1.5px solid #000 !important;
}
.tt_wrapper .tt_tabs_navigation li a {
  border-bottom: none !important;
}
.tt_wrapper .tt_responsive .tt_timetable.small.use_colors .tt_items_list a, .tt_wrapper .tt_responsive .tt_timetable.small.use_colors .tt_items_list span {
  color: #000 !important;
}
.tt_wrapper .tt_timetable.small.use_colors .box_header {
  margin-bottom: 15px !important;
  margin-top: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px !important;
  font-weight: 600;
}
.tt_wrapper .tt_tabs .tt_navigation_cell:first-child {
  margin-bottom: 10px !important;
}

/* FOOTER  */	
@media screen and (max-width: 767px) {
  #page-footer p, #page-footer a {
    margin-bottom: 6px !important;
    display: block;
  }
}

.accordion .accordion-item-header:hover {
  background: var(--color-gray-2);
  transition: all 0.3s ease;
}
.accordion .accordion-item-header.active {
  padding-bottom: 0px;
  background: var(--color-gray-2);
}
.accordion .accordion-item-header.active .postura .lazy-wrapper {
  width: 100%;
}
.accordion .accordion-item-header.active .arrow {
  transform: rotate(0.5turn);
}
.accordion .line {
  border: 1.5px solid var(--color-gray-1);
}
.accordion .postura {
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: end;
}
@media screen and (max-width: 991px) {
  .accordion .postura {
    position: relative;
    justify-content: center;
    margin-top: 20px;
    right: auto;
  }
}
.accordion .postura .lazy-wrapper {
  width: 125px;
  transition: all 0.3s ease;
}
.accordion .arrow {
  margin-top: 20px;
  transition: all 0.3s ease;
  width: calc(1 * (20 * 1px));
}
@media screen and (min-width: 360px) {
  .accordion .arrow {
    width: calc(1 * (20 * 1px + (28 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .accordion .arrow {
    width: calc(1 * (28 * 1px));
  }
}
.accordion .titulo {
  transform: translateY(20px);
}
@media screen and (max-width: 991px) {
  .accordion .titulo {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(0px);
    margin-top: 20px;
  }
}
.accordion .titulo h6, .accordion .titulo h2 {
  margin: 0;
}
.accordion .titulo h6 {
  padding-top: 15px;
}
.accordion .accordion-item {
  background-color: #fff;
}
.accordion .accordion-item-header {
  position: relative;
  cursor: pointer;
  padding-right: 25px;
  padding-top: 35px;
  padding-bottom: 35px;
  transition: padding 0.3s ease;
}
@media screen and (max-width: 1100px) {
  .accordion .accordion-item-header {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.accordion .accordion-item-header_::after {
  content: "+";
  font-size: 2rem;
  position: absolute;
  right: 1rem;
}
.accordion .accordion-item-header.active_::after {
  content: "−";
}
.accordion .accordion-item-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.accordion .accordion-item-body-content {
  background: var(--color-gray-2);
  padding-bottom: 50px;
  padding-right: 50px;
}
@media screen and (max-width: 1200px) {
  .accordion .accordion-item-body-content {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
  }
}
@media screen and (max-width: 1100px) {
  .accordion .accordion-item-body-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.accordion .accordion-item-body-content .items {
  display: flex;
  flex-flow: row wrap;
  width: 500px;
}
@media screen and (max-width: 1100px) {
  .accordion .accordion-item-body-content .items {
    margin-bottom: 10px;
  }
}
.accordion .accordion-item-body-content .items .item {
  width: 240px;
  padding-right: 20px;
  margin-bottom: 15px;
}
@media screen and (max-width: 1100px) {
  .accordion .accordion-item-body-content .items .item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
  }
}
.accordion .accordion-item-body-content p:first-child {
  margin-top: 0px;
}
.accordion .accordion-item-body-content p {
  font-size: calc(1 * (15 * 1px));
}
@media screen and (min-width: 360px) {
  .accordion .accordion-item-body-content p {
    font-size: calc(1 * (15 * 1px + (18 - 15) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .accordion .accordion-item-body-content p {
    font-size: calc(1 * (18 * 1px));
  }
}
.accordion .accordion-item-body-content h6 {
  font-size: calc(1 * (14 * 1px));
  letter-spacing: 0px;
  font-weight: bold !important;
  margin: 0;
}
@media screen and (min-width: 360px) {
  .accordion .accordion-item-body-content h6 {
    font-size: calc(1 * (14 * 1px + (14 - 14) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .accordion .accordion-item-body-content h6 {
    font-size: calc(1 * (14 * 1px));
  }
}

[data-arts-os-animation=true] > *:not(.js-ajax-transition-visible-element) {
  opacity: 0;
  pointer-events: none !important;
}
[data-arts-os-animation=true] > *:not(.js-ajax-transition-visible-element) * {
  pointer-events: none !important;
}

[data-arts-os-animation=true] .js-ajax-transition-hidden-element {
  opacity: 0;
  visibility: hidden;
  pointer-events: none !important;
}
[data-arts-os-animation=true] .js-ajax-transition-hidden-element * {
  pointer-events: none !important;
}

.svg-arrow path {
  fill: currentColor;
}

.aspect-landscape {
  aspect-ratio: 1.5/1 !important;
}

.aspect-portrait {
  --media-width: 1280;
  --media-height: 1920;
}

.aspect-portrait-2 {
  aspect-ratio: 1/1.33 !important;
}

.aspect-square {
  aspect-ratio: 1/1 !important;
}

.aspect-unset {
  aspect-ratio: unset !important;
}

.has-aspect-ratio {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.has-aspect-ratio::before {
  content: "";
  display: block;
  padding-bottom: calc(var(--media-height) / var(--media-width) * 100%);
}
.has-aspect-ratio::after {
  content: "";
  display: table;
  clear: both;
}
.has-aspect-ratio > img, .has-aspect-ratio > video {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
}

.auto-scroll-next {
  overflow: hidden;
  position: relative;
  z-index: 100;
}

.auto-scroll-next__label {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: opacity 0.3s ease;
  opacity: 1;
  visibility: visible;
}

.auto-scroll-next__heading {
  transition: all 0.3s ease;
}

.auto-scroll-next__link {
  display: block;
  overflow: initial !important;
}
.auto-scroll-next__link:hover * {
  color: currentColor;
}

.auto-scroll-next__wrapper-media {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
  max-width: calc(100% - var(--gutter-horizontal) * 2);
  max-height: 100%;
  margin: 0 auto;
  transform: translateY(-50%);
  pointer-events: none;
}
.auto-scroll-next__wrapper-media *:not(.auto-scroll-next__inner-media) {
  pointer-events: none;
}

.auto-scroll-next__header {
  pointer-events: none;
  cursor: pointer;
  overflow: initial !important;
}

.auto-scroll-next__lane {
  transition: opacity 0.3s ease;
}

.auto-scroll-next__inner {
  z-index: 200 !important;
}

.auto-scroll-next__inner-media {
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  height: 60vh;
  max-width: 100%;
  pointer-events: all;
}
.auto-scroll-next__inner-media img {
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center center;
}

.auto-scroll-next__inner-media:not(.js-ajax-transition-clone) img {
  width: auto;
  height: 100%;
}

.auto-scroll-next__mask-media {
  display: block;
  height: 100%;
  width: auto;
  overflow: hidden;
}

.auto-scroll-next__progress-line {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  z-index: 50;
  transform: scaleX(0);
  transform-origin: left center;
}

.auto-scroll-next[data-arts-os-animation=true] .auto-scroll-next__button-hover {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
}

.auto-scroll-next__wrapper-button {
  pointer-events: all !important;
  cursor: pointer;
}
.auto-scroll-next__wrapper-button * {
  pointer-events: none;
}

.auto-scroll-next_scrolling .auto-scroll-next__button-normal {
  transform: scale(0);
}
.auto-scroll-next_scrolling .auto-scroll-next__button-hover {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
.auto-scroll-next_scrolling .auto-scroll-next__button-hover * {
  color: var(--color-accent);
}

.auto-scroll-next__button-normal, .auto-scroll-next__button-hover, .auto-scroll-next__button-static {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.auto-scroll-next__button-hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.auto-scroll-next_complete .auto-scroll-next__label {
  opacity: 0;
  visibility: hidden;
}
.auto-scroll-next_complete .auto-scroll-next__button-normal {
  transform: scale(0);
}
.auto-scroll-next_complete .auto-scroll-next__button-hover {
  transform: translate(-50%, -50%) scale(0);
}
.auto-scroll-next_complete .auto-scroll-next__lane {
  opacity: 0;
  visibility: hidden;
}

.auto-scroll-next_scrolling .auto-scroll-next__label-lane-normal {
  transform: translateY(100%);
}
.auto-scroll-next_scrolling .auto-scroll-next__label-lane-scrolling {
  transform: translateY(0%);
}

.auto-scroll-next__label-lane-normal {
  transition: transform 0.6s ease;
}

.auto-scroll-next__label-lane-scrolling {
  transition: transform 0.6s ease;
  transform: translateY(-100%);
}

.block-counter {
  position: relative;
}

.block-counter__counter {
  position: absolute;
  top: auto;
  bottom: calc(100% - 2em);
  left: calc(100% + 0.5em);
  letter-spacing: 0;
  white-space: nowrap;
}

.row:not([class*=g-]) {
  --bs-gutter-x: 40px;
  --bs-gutter-y: 0;
}

.g-default {
  --bs-gutter-x: calc(1 * (20 * 1px));
  --bs-gutter-y: calc(1 * (30 * 1px));
}
@media screen and (min-width: 360px) {
  .g-default {
    --bs-gutter-x: calc(1 * (20 * 1px + (40 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .g-default {
    --bs-gutter-x: calc(1 * (40 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .g-default {
    --bs-gutter-y: calc(1 * (30 * 1px + (40 - 30) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .g-default {
    --bs-gutter-y: calc(1 * (40 * 1px));
  }
}

.g-gutters {
  --bs-gutter-x: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-horizontal));
  --bs-gutter-y: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-vertical));
}

.g-gutters-horizontal {
  --bs-gutter-x: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-horizontal));
  --bs-gutter-y: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-horizontal));
}

.g-gutters-vertical {
  --bs-gutter-x: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-vertical));
  --bs-gutter-y: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-vertical));
}

.container-fluid-gutters {
  width: 100%;
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1100px) {
  .max-lg-pt-0 {
    padding-top: 0 !important;
  }
  .max-lg-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .max-lg-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .max-lg-pb-0 {
    padding-bottom: 0 !important;
  }
  .max-lg-p-0 {
    padding: 0 !important;
  }
  .max-lg-mt-0 {
    margin-top: 0 !important;
  }
  .max-lg-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .max-lg-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .max-lg-mb-0 {
    margin-bottom: 0 !important;
  }
  .max-lg-m-0 {
    margin: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  .max-md-pt-0 {
    padding-top: 0 !important;
  }
  .max-md-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .max-md-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .max-md-pb-0 {
    padding-bottom: 0 !important;
  }
  .max-md-p-0 {
    padding: 0 !important;
  }
  .max-md-mt-0 {
    margin-top: 0 !important;
  }
  .max-md-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .max-md-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .max-md-mb-0 {
    margin-bottom: 0 !important;
  }
  .max-md-m-0 {
    margin: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  .max-sm-pt-0 {
    padding-top: 0 !important;
  }
  .max-sm-pb-0 {
    padding-bottom: 0 !important;
  }
  .max-sm-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .max-sm-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .max-sm-mt-0 {
    margin-top: 0 !important;
  }
  .max-sm-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .max-sm-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .max-sm-mb-0 {
    margin-bottom: 0 !important;
  }
  .max-sm-m-0 {
    margin: 0 !important;
  }
}
.p-bs-gutters {
  padding: var(--bs-gutter-x) var(--bs-gutter-y);
}

.px-bs-gutters {
  padding-left: var(--bs-gutter-x);
  padding-right: var(--bs-gutter-x);
}

.ps-bs-gutters {
  padding-left: var(--bs-gutter-x);
}

.pe-bs-gutters {
  padding-right: var(--bs-gutter-x);
}

.py-bs-gutters {
  padding-top: var(--bs-gutter-y);
  padding-bottom: var(--bs-gutter-y);
}

.pt-bs-gutters {
  padding-top: var(--bs-gutter-y);
}

.pb-bs-gutters {
  padding-bottom: var(--bs-gutter-y);
}

@media screen and (min-width: 992px) {
  .px-lg-min-gutters {
    padding-left: calc(var(--gutter-horizontal-min) * 1px);
    padding-right: calc(var(--gutter-horizontal-min) * 1px);
  }
}
.w-container-edge-end {
  max-width: 100%;
  width: calc(100% - (100vw - 1320px) / 2 - var(--bs-gutter-x));
  margin-left: auto;
  text-align: left;
}

.w-container-edge-start {
  max-width: 100%;
  width: calc(100% - (100vw - 1320px) / 2 - var(--bs-gutter-x));
  margin-right: auto;
  text-align: right;
}

@media screen and (max-width: 1400px) {
  .w-container-edge-end, .w-container-edge-start {
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 1200px) {
  .w-container-edge-end {
    width: calc(100% - (100vw - 1140px) / 2 - var(--bs-gutter-x));
  }
  .w-container-edge-start {
    width: calc(100% - (100vw - 1140px) / 2 - var(--bs-gutter-x));
  }
}
.b-auto-opacity-solid {
  --color-borders: var(--color-border-solid);
  border: 1px solid var(--color-borders);
}

.bt-auto-opacity-solid {
  --color-borders: var(--color-border-solid);
  border-top: 1px solid var(--color-borders);
}

.br-auto-opacity-solid {
  --color-borders: var(--color-border-solid);
  border-right: 1px solid var(--color-borders);
}

.bb-auto-opacity-solid {
  --color-borders: var(--color-border-solid);
  border-bottom: 1px solid var(--color-borders);
}

.bl-auto-opacity-solid {
  --color-borders: var(--color-border-solid);
  border-left: 1px solid var(--color-borders);
}

.borders-solid {
  --color-borders: var(--color-border-solid) !important;
}

.borders-opacity {
  --color-borders: var(--color-border-opacity) !important;
}

.borders-auto-opacity-solid {
  --color-borders: var(--color-border-solid) !important;
}

[data-arts-color-theme=dark] .borders-auto-opacity-solid, [data-arts-color-theme=dark] .b-auto-opacity-solid .bt-auto-opacity-solid, [data-arts-color-theme=dark] .br-auto-opacity-solid, [data-arts-color-theme=dark] .bb-auto-opacity-solid, [data-arts-color-theme=dark] .bl-auto-opacity-solid {
  --color-borders: var(--color-border-opacity) !important;
}

.borders-auto-opacity-solid[data-arts-color-theme=dark], .b-auto-opacity-solid .bt-auto-opacity-solid[data-arts-color-theme=dark], .br-auto-opacity-solid[data-arts-color-theme=dark], .bb-auto-opacity-solid[data-arts-color-theme=dark], .bl-auto-opacity-solid[data-arts-color-theme=dark] {
  --color-borders: var(--color-border-opacity) !important;
}

.b-auto {
  border: 1px solid var(--color-borders);
}

.bt-auto {
  border-top: 1px solid var(--color-borders);
}

.br-auto {
  border-right: 1px solid var(--color-borders);
}

.bb-auto {
  border-bottom: 1px solid var(--color-borders);
}

.bl-auto {
  border-left: 1px solid var(--color-borders);
}

.bg-bt {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 1px;
  background-color: var(--color-borders);
}

.bg-br {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: var(--color-borders);
}

.bg-bb {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: var(--color-borders);
}

.bg-bl {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: var(--color-borders);
}

.b-opacity {
  border: 1px solid var(--color-border-opacity);
}

.bt-opacity {
  border-top: 1px solid var(--color-border-opacity);
}

.br-opacity {
  border-right: 1px solid var(--color-border-opacity);
}

.bb-opacity {
  border-bottom: 1px solid var(--color-border-opacity);
}

.bl-opacity {
  border-left: 1px solid var(--color-border-opacity);
}

.b-solid {
  border: 1px solid var(--color-border-solid);
}

.bt-solid {
  border-top: 1px solid var(--color-border-solid);
}

.br-solid {
  border-right: 1px solid var(--color-border-solid);
}

.bb-solid {
  border-bottom: 1px solid var(--color-border-solid);
}

.bl-solid {
  border-left: 1px solid var(--color-border-solid);
}

.categories {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.categories li {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1;
}
.categories li:not(:last-child):after {
  content: "/";
  display: inline-block;
  margin-left: 0.6em;
  margin-right: 0.6em;
}

.button {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  box-shadow: none;
  border-width: 2px;
  border-style: solid;
  padding: 16px 36px;
  border-radius: 96px;
  color: var(--color-dark-4);
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: color, background-color, border-color;
  outline: none;
}
.button.style_2 {
  padding: 0 !important;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid var(--color-orange);
  color: var(--color-orange);
  font-weight: bold;
  text-transform: uppercase;
}
.button.style_2 span {
  padding: 0;
}
.button:focus {
  outline: none;
}
.button:hover .button__label-normal .button__title {
  transform: translate(0%, -100%);
  opacity: 0;
}
.button:hover .button__label-normal .button__icon {
  opacity: 0;
  transform: translate(100%, 0%);
}
.button:hover .button__label-hover .button__title {
  transform: translate(0%, 0%);
  opacity: 1;
}
.button:hover .button__label-hover .button__icon {
  opacity: 1;
  transform: translate(0%, 0%);
}
.button * {
  pointer-events: none;
}

.button_icon {
  display: inline-flex;
  border: none;
}
.button_icon .button__label {
  padding: 0;
}

.button__label-hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.button__label-hover .button__title {
  transform: translate(0%, 100%);
  opacity: 0;
}
.button__label-hover .button__icon {
  transform: translate(-100%, 0%);
  opacity: 0;
}

.button__label-normal .button__title {
  transform: translate(0%, 0%);
  opacity: 1;
}

.button__title {
  display: inline-block;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.button__label {
  display: inline-block;
  padding: 16px 36px;
}

.button__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  vertical-align: middle;
  margin-bottom: 0.1em;
}

.button__icon_before {
  margin-right: 0.5em;
}

.button__icon_after {
  margin-left: 0.25em;
  margin-right: -0.5em;
}

.button_fullwidth {
  width: 100%;
}

.button-fullwidth .button {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .button {
    padding: 12px 24px;
  }
  .button__label {
    padding: 12px 24px;
  }
}
@media screen and (max-width: 991px) {
  .button {
    padding: 12px 24px;
  }
  .button__label {
    padding: 12px 24px;
  }
  .button__icon i {
    font-size: 20px;
  }
}
.button.button_bordered {
  background-color: unset !important;
  color: var(--ui-element-color);
  border-width: 2px;
  border-style: solid;
  border-color: currentColor;
}

.button.button_solid {
  background-color: var(--ui-element-color);
  color: var(--ui-element-color-dark-theme);
  border: none;
}

[data-arts-color-theme=dark]:not([data-arts-color-theme=dark] [data-arts-color-theme=light]) .button.button_solid:not([data-arts-color-theme=dark] .button[data-arts-color-theme=dark]) {
  color: var(--ui-element-color-light-theme);
}

.circle-fact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  text-align: center;
  --shape-paddings-min: 30;
  --shape-paddings-max: 80;
  padding: calc(1 * (var(--shape-paddings-min) * 1px));
  white-space: nowrap;
}
@media screen and (min-width: 360px) {
  .circle-fact {
    padding: calc(1 * (var(--shape-paddings-min) * 1px + (var(--shape-paddings-max) - var(--shape-paddings-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .circle-fact {
    padding: calc(1 * (var(--shape-paddings-max) * 1px));
  }
}

.content u {
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: color 0.6s ease;
}
.content u svg {
  position: absolute;
  top: 55%;
  left: 50%;
  width: 110%;
  height: 80%;
  transform: translate(-50%, -50%) rotate(-5deg);
  transform-origin: center center;
  stroke: currentColor;
  stroke-width: 1px;
  pointer-events: none;
}
.content u svg ellipse {
  stroke-dashoffset: 0;
  stroke-dasharray: none;
}
.content > *:first-child:not(.soc1al) {
  margin-top: 0 !important;
}
.content > *:last-child:not(.soc1al) {
  margin-bottom: 0 !important;
}

.split-text-animation-revealed u {
  color: var(--color-accent);
}

.content-width-1 {
  width: 100%;
  max-width: 600px;
}

.content-width-2 {
  width: 100%;
  max-width: 720px;
}

.content-width-3 {
  width: 100%;
  max-width: 960px;
}

.bg-dark-1 {
  background-color: var(--color-dark-1) !important;
  --overlay-rgb: var(--color-dark-1-rgb) !important;
}

.bg-dark-2 {
  background-color: var(--color-dark-2) !important;
  --overlay-rgb: var(--color-dark-2-rgb) !important;
}

.bg-dark-3 {
  background-color: var(--color-dark-3) !important;
  --overlay-rgb: var(--color-dark-3-rgb) !important;
}

.bg-dark-4 {
  background-color: var(--color-dark-4) !important;
  --overlay-rgb: var(--color-dark-4-rgb) !important;
}

.bg-gray-1 {
  background-color: var(--color-gray-1) !important;
  --overlay-rgb: var(--color-gray-1-rgb) !important;
}

.bg-gray-2 {
  background-color: var(--color-gray-2) !important;
  --overlay-rgb: var(--color-gray-2-rgb) !important;
}

.bg-gray-3 {
  background-color: var(--color-gray-3) !important;
  --overlay-rgb: var(--color-gray-3-rgb) !important;
}

.bg-gray-4 {
  background-color: var(--color-gray-4) !important;
  --overlay-rgb: var(--color-gray-4-rgb) !important;
}

.bg-light-1 {
  background-color: var(--color-light-1) !important;
  --overlay-rgb: var(--color-light-1-rgb) !important;
}

.bg-light-2 {
  background-color: var(--color-light-2) !important;
  --overlay-rgb: var(--color-light-2-rgb) !important;
}

.bg-light-3 {
  background-color: var(--color-light-3) !important;
  --overlay-rgb: var(--color-light-3-rgb) !important;
}

.bg-light-4 {
  background-color: var(--color-light-4) !important;
  --overlay-rgb: var(--color-light-4-rgb) !important;
}

.bg-platinum-1 {
  background-color: var(--color-platinum-1) !important;
  --overlay-rgb: var(--color-platinum-1-rgb) !important;
}

.bg-platinum-2 {
  background-color: var(--color-platinum-2) !important;
  --overlay-rgb: var(--color-platinum-2-rgb) !important;
}

.bg-accent {
  background-color: var(--color-accent) !important;
  --overlay-rgb: var(--color-accent-rgb) !important;
}

.bg-accent-dark-theme {
  background-color: var(--color-accent-dark-theme) !important;
  --overlay-rgb: var(--color-accent-dark-theme-rgb) !important;
}

.bg-accent-light-theme {
  background-color: var(--color-accent-light-theme) !important;
  --overlay-rgb: var(--color-accent-light-theme-rgb) !important;
}

.color-accent {
  color: var(--color-accent) !important;
}

.color-accent-dark-theme {
  color: var(--color-accent-dark-theme) !important;
}

.color-accent-light-theme {
  color: var(--color-accent-light-theme) !important;
}

.color-light-theme {
  color: var(--paragraph-color-light-theme) !important;
}

.color-dark-theme {
  color: var(--paragraph-color-dark-theme) !important;
}

.counter-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(1 * (50 * 1px));
  height: calc(1 * (50 * 1px));
  border-radius: 100%;
  border: 1px solid var(--color-border-opacity);
  white-space: nowrap;
}
@media screen and (min-width: 360px) {
  .counter-circle {
    width: calc(1 * (50 * 1px + (80 - 50) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .counter-circle {
    width: calc(1 * (80 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .counter-circle {
    height: calc(1 * (50 * 1px + (80 - 50) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .counter-circle {
    height: calc(1 * (80 * 1px));
  }
}

.counter-up {
  display: inline-block;
}

.counter-up__prefix {
  font-size: 75%;
}

.counter-up__suffix {
  font-size: 75%;
}

.counter-up__number {
  line-height: 0.9 !important;
  white-space: nowrap;
}

.counter-up__label {
  line-height: 1 !important;
}

.cursor-none {
  cursor: none !important;
}
.cursor-none * {
  cursor: none !important;
}

.cursor-progress {
  cursor: progress !important;
}
.cursor-progress * {
  cursor: progress !important;
}

.pointer-events-none {
  pointer-events: none !important;
}
.pointer-events-none * {
  pointer-events: none !important;
}

.pointer-events-inner-none * {
  pointer-events: none !important;
}

.pointer-events-links-none a {
  pointer-events: !important;
}

a.pointer-events-links-none {
  pointer-events: !important;
}

.arts-cursor {
  --cursor-color: var(--color-orange);
  --cursor-color-dark-theme: var(--color-orange);
  --cursor-border-width: 0px;
  --cursor-background-color: var(--color-orange);
  --cursor-background-color-dark-theme: red;
  position: fixed;
  top: 0;
  left: 0;
  transform: scale(0);
  user-select: none;
  pointer-events: none;
  z-index: 100001;
  color: var(--cursor-color, transparent);
}
.arts-cursor * {
  user-select: none;
  pointer-events: none;
}

.has-cursor-follower .arts-cursor {
  transform: translate3d(calc(var(--translateX, 0) - 50%), calc(var(--translateY, 0) - 50%), 0) rotate3d(0, 0, 1, var(--rotate, 0)) scale3d(var(--scaleX, 0), var(--scaleY, 0), 1);
}
.has-cursor-follower .arts-cursor__follower {
  transform: scale(calc(var(--scale, 1) * var(--scale-pressed, 1)));
}
.has-cursor-follower .arts-cursor__wrapper {
  transform: rotate3d(0, 0, 1, var(--rotate, 0));
}

.arts-cursor__follower {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  box-shadow: inset 0 0 0 var(--cursor-border-width, 1px) currentColor;
  background-color: var(--cursor-background-color, transparent);
}

.arts-cursor__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.arts-cursor__arrow {
  position: absolute;
  margin: auto;
  font-size: 24px !important;
  width: 24px;
  height: 24px;
  opacity: 0;
  visibility: hidden;
}

.arts-cursor__arrow_left {
  top: 0;
  bottom: 0;
  left: 0;
}

.arts-cursor__arrow_right {
  top: 0;
  bottom: 0;
  right: 0;
}

.arts-cursor__arrow_up {
  top: 0;
  left: 0;
  right: 0;
}

.arts-cursor__arrow_down {
  bottom: 0;
  left: 0;
  right: 0;
}

.arts-cursor__wrapper-label, .arts-cursor__wrapper-icon, .arts-cursor__wrapper-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.arts-cursor__label {
  display: block;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
}

.arts-cursor__icon {
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  font-size: 28px !important;
  width: 28px;
  height: 28px;
  vertical-align: middle;
}
.arts-cursor__icon:before {
  margin: 0;
  vertical-align: top;
}

.arts-cursor-none {
  cursor: none !important;
}
.arts-cursor-none * {
  cursor: none !important;
}

.arts-cursor-progress {
  cursor: progress !important;
}
.arts-cursor-progress * {
  cursor: progress !important;
}

.arts-cursor__label {
  font-size: 14px;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 0;
}

.arts-is-dragging {
  cursor: none !important;
}
.arts-is-dragging * {
  cursor: none !important;
}
.arts-is-dragging .arts-is-dragging__blocker {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}

.arts-is-dragging__blocker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition-delay: 0.2s;
}

.arts-cursor__wrapper-loading {
  transform: translate(-50%, -50%) scale(0);
  width: 100%;
  height: 100%;
}
.arts-cursor__wrapper-loading svg {
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: auto;
}
.arts-cursor__wrapper-loading svg circle {
  stroke: currentColor;
  stroke-width: 2px;
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -150;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

.arts-cursor[data-arts-color-theme=dark], #page-wrapper[data-arts-color-theme=dark] + .arts-cursor {
  --color-accent: var(--color-accent-dark-theme);
  --cursor-color: var(--cursor-color-dark-theme);
  --cursor-background-color: var(--cursor-background-color-dark-theme);
}

.curtain-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  z-index: -1;
  pointer-events: none;
}

.canvas-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 0;
}

.has-curtains img, .has-curtains video {
  visibility: hidden;
  opacity: 0;
}

.no-curtains .canvas-wrapper {
  opacity: 0;
  visibility: hidden;
}

.canvas-wrapper ~ img, .canvas-wrapper ~ video {
  visibility: hidden;
  opacity: 0;
}

.custom-modal {
  --duracion: .4s;
  --offset: 300px, 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  right: 0;
  height: 100%;
  display: none;
  backdrop-filter: blur(2px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.custom-modal .container {
  padding: 0px;
  display: flex;
  min-height: 100%;
  max-width: 70vw;
  margin-right: 0;
}
.custom-modal .container.background-1 {
  background: var(--color-yellow);
}
.custom-modal .container.background-2 {
  background: var(--color-green);
}
@media screen and (max-width: 1200px) {
  .custom-modal .container {
    max-width: 80vw !important;
  }
}
@media screen and (max-width: 991px) {
  .custom-modal .container {
    max-width: 100% !important;
  }
}
.custom-modal .container .modal-center {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.custom-modal .container .bg-content-white {
  position: relative;
  width: 100%;
  padding-left: calc(1 * (15 * 1px));
  padding-right: calc(1 * (15 * 1px));
  padding-top: calc(1 * (80 * 1px));
  padding-bottom: calc(1 * (80 * 1px));
}
@media screen and (min-width: 360px) {
  .custom-modal .container .bg-content-white {
    padding-left: calc(1 * (15 * 1px + (30 - 15) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .custom-modal .container .bg-content-white {
    padding-left: calc(1 * (30 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .custom-modal .container .bg-content-white {
    padding-right: calc(1 * (15 * 1px + (30 - 15) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .custom-modal .container .bg-content-white {
    padding-right: calc(1 * (30 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .custom-modal .container .bg-content-white {
    padding-top: calc(1 * (80 * 1px + (160 - 80) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .custom-modal .container .bg-content-white {
    padding-top: calc(1 * (160 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .custom-modal .container .bg-content-white {
    padding-bottom: calc(1 * (80 * 1px + (160 - 80) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .custom-modal .container .bg-content-white {
    padding-bottom: calc(1 * (160 * 1px));
  }
}
.custom-modal .container .bg-content-white p, .custom-modal .container .bg-content-white a {
  font-size: calc(1 * (17 * 1px));
  z-index: 99;
  position: relative;
  color: #000;
}
@media screen and (min-width: 360px) {
  .custom-modal .container .bg-content-white p, .custom-modal .container .bg-content-white a {
    font-size: calc(1 * (17 * 1px + (19 - 17) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .custom-modal .container .bg-content-white p, .custom-modal .container .bg-content-white a {
    font-size: calc(1 * (19 * 1px));
  }
}
.custom-modal.show {
  display: block;
  animation: show-modal var(--duracion) ease forwards;
}
.custom-modal.show section {
  animation: reset-translate var(--duracion) ease forwards;
}
.custom-modal.show.hidde {
  animation: hidde-modal var(--duracion) ease forwards;
}
.custom-modal.show.hidde section {
  animation: set-translate var(--duracion) ease forwards;
}
.custom-modal .close-btn {
  position: absolute;
  font-size: 3rem;
  right: 20px;
  top: 35px;
  background: none;
  border: none;
  appearance: none;
  color: var(--color-dark-2);
  z-index: 99;
  cursor: pointer;
}
@media screen and (max-width: 991px) {
  .custom-modal .close-btn {
    font-size: 2.5rem;
  }
}

@keyframes show-modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes reset-translate {
  from {
    transform: translate(var(--offset));
  }
  to {
    transform: translate(0);
  }
}
@keyframes hidde-modal {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes set-translate {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(var(--offset));
  }
}
body.overflow-hidden .wws-popup-container {
  opacity: 0;
}
body.overflow-hidden header .header__bar_sticky {
  background: transparent !important;
  box-shadow: none !important;
}

.hover-zoom.shape-top-arc .hover-zoom__zoom, .hover-zoom.shape-top-arc .hover-zoom__inner {
  transform-origin: center bottom;
}
.hover-zoom.shape-bottom-arc .hover-zoom__zoom, .hover-zoom.shape-bottom-arc .hover-zoom__inner {
  transform-origin: center top;
}

.hover-zoom:hover:not(:focus) .hover-zoom__zoom {
  transform: scale(0.98);
}
.hover-zoom:hover:not(:focus) .hover-zoom__inner {
  transform: scale(1.02);
}

.hover-zoom-hovered .hover-zoom__zoom {
  transform: scale(0.98);
}
.hover-zoom-hovered .hover-zoom__inner {
  transform: scale(1.02);
}
.hover-zoom-hovered a, .hover-zoom-hovered a * {
  color: var(--color-accent);
}

.hover-zoom__inner {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.hover-zoom__zoom, .hover-zoom__inner {
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.4s ease-in-out;
}

.filter {
  position: relative;
  white-space: nowrap;
}

.filter__inner {
  margin-top: -0.5em;
  margin-bottom: -0.5em;
  margin-left: -0.25em;
  margin-right: -0.25em;
  position: relative;
}

.filter__item {
  position: relative;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  cursor: pointer;
  transition: color 0.3s ease;
}
.filter__item:not(.filter__item_active):hover {
  color: var(--color-accent);
}

.filter__item-inner {
  display: inline-block;
  padding: 0.25em;
}
.filter__item-inner svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  stroke: currentColor;
  stroke-width: 1px;
  pointer-events: none;
}

.filter__item_active {
  color: var(--color-accent) !important;
  pointer-events: none;
}

.filter__item-label {
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .filter .filter__item:first-child {
    padding-top: 0;
  }
  .filter .filter__item:last-child {
    padding-bottom: 0;
  }
}
.form {
  width: 100%;
}

.form__submit {
  margin-top: 1.5em;
}

.form__col {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.form__col_submit {
  margin-top: 1.5em;
  margin-bottom: 0;
}

.form__heading {
  margin-top: 0;
  margin-bottom: 1em;
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
  border-color: red;
  padding: 15px;
  margin: 1.5em 0 0;
  display: none !important;
}

@media only screen and (max-width: 992px) {
  .form__col_submit {
    text-align: left;
  }
}
.gmap {
  width: 100%;
  height: 100%;
}

.gmap__container {
  width: 100%;
  height: 100%;
}

.js-gui {
  position: fixed;
  bottom: 20px;
  left: 0;
  z-index: 9999;
}
.js-gui .dg .cr.function {
  font-weight: bold;
  font-size: 12px;
  border-left: none;
}

.header {
  position: static;
  --header-min-gutters-horizontal: 20;
  --header-max-gutters-horizontal: 30;
  --header-min-gutters-vertical: 20;
  --header-max-gutters-vertical: 30;
}
.header a, .header select, .header input {
  pointer-events: initial;
}

.header__border-horizontal {
  transform-origin: left center;
  transition: all 0.3s ease;
}

.header__border-vertical {
  transform-origin: top center;
  transition: all 0.3s ease;
}

.header__label {
  position: relative;
  white-space: nowrap;
  transition: transform 0.3s ease;
}

.header__label-hover {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.3s ease;
  color: currentColor;
}

.header__label-normal {
  position: relative;
  transform: translateY(0%);
  transition: all 0.3s ease;
}

.header__arrow-back {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header__arrow-back-line {
  position: relative;
  width: 32px;
  height: 2px;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  clip-path: inset(0px);
}
.header__arrow-back-line:nth-of-type(1) {
  width: 20px;
  transform: rotate(-40deg) translate(-18px, 9px);
  transform-origin: left center;
}
.header__arrow-back-line:nth-of-type(1):before, .header__arrow-back-line:nth-of-type(1):after {
  transition-delay: 50ms;
}
.header__arrow-back-line:nth-of-type(2) {
  margin-top: auto;
  margin-bottom: auto;
}
.header__arrow-back-line:nth-of-type(2):before, .header__arrow-back-line:nth-of-type(2):after {
  transition-delay: 0ms;
}
.header__arrow-back-line:nth-of-type(3) {
  width: 20px;
  transform: rotate(40deg) translate(-18px, -9px);
  transform-origin: left center;
}
.header__arrow-back-line:nth-of-type(3):before, .header__arrow-back-line:nth-of-type(3):after {
  transition-delay: 50ms;
}
.header__arrow-back-line:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  background-color: currentColor;
  transition: all 0.3s ease;
}
.header__arrow-back-line:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(calc(-100% - 4px));
  background-color: currentColor;
  transition: all 0.5s ease;
}

.header__bar {
  display: flex;
  z-index: 500;
  pointer-events: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
}

.header__bar_absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header__bar_fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header__bar_relative {
  position: relative !important;
}

.header__bar[data-arts-header-sticky-class] {
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0);
}

.header__bar_sticky[data-arts-header-sticky-class]:not(.header__bar_scrolling-down):not(.ajax-loading .header__bar_sticky) {
  border-color: transparent;
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.04);
}
.header__bar_sticky[data-arts-header-sticky-class]:not(.header__bar_scrolling-down):not(.ajax-loading .header__bar_sticky) .header__border-horizontal {
  background-color: transparent;
}

.header__bar_scrolling-down, .ajax-loading .header__bar_sticky {
  background-color: transparent !important;
}

.header__bar_sticky {
  translate: none;
  rotate: none;
  scale: none;
  transform: translate3d(0, var(--translateY), 0);
  transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease, opacity 0.3s ease;
}

.header:not(.opened) .header__label-burger_closed-hover, .header:not(.opened) .header__label-burger_opened-open, .header:not(.opened) .header__label-burger_opened-hover {
  transform: translateY(100%);
  opacity: 0;
}
.header:not(.opened) .header__burger:hover .header__label-burger_closed-open {
  transform: translateY(-100%);
  opacity: 0;
}
.header:not(.opened) .header__burger:hover .header__label-burger_closed-hover {
  transform: translateY(0%);
  opacity: 1;
}
.header.opened .header__label-burger_closed-open, .header.opened .header__label-burger_closed-hover, .header.opened .header__label-burger_opened-hover {
  transform: translateY(100%);
  opacity: 0;
}
.header.opened .header__burger:hover .header__label-burger_opened-open {
  transform: translateY(-100%);
  opacity: 0;
}
.header.opened .header__burger:hover .header__label-burger_opened-hover {
  transform: translateY(0%);
  opacity: 1;
}

.header__burger-wrapper-lines, .pswp-custom-button__close-wrapper-lines {
  position: relative;
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
}

.header__burger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  pointer-events: initial;
  z-index: 500;
  background: none;
  outline: none;
}
.header__burger:hover .header__burger-line:before, .header__burger:hover .pswp-custom-button__close-line:before {
  transform: translateX(100%);
}
.header__burger:hover .header__burger-line:after, .header__burger:hover .pswp-custom-button__close-line:after {
  transform: translateX(0%);
}

.header__burger-label, .pswp-custom-button__label {
  display: inline-block;
  line-height: 1;
  position: relative;
  text-align: right;
  transition: transform 0.3s ease;
  color: currentColor;
}

.header__label-burger_inner {
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s ease;
}

.header__label-burger_closed-open {
  position: relative;
}

.header__burger-line, .pswp-custom-button__close-line {
  position: relative;
  width: calc(1 * (30 * 1px));
  height: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
@media screen and (min-width: 360px) {
  .header__burger-line, .pswp-custom-button__close-line {
    width: calc(1 * (30 * 1px + (40 - 30) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .header__burger-line, .pswp-custom-button__close-line {
    width: calc(1 * (40 * 1px));
  }
}
.header__burger-line:nth-of-type(1):before, .pswp-custom-button__close-line:nth-of-type(1):before, .header__burger-line:nth-of-type(1):after, .pswp-custom-button__close-line:nth-of-type(1):after {
  transition-delay: 0ms;
}
.header__burger-line:nth-of-type(2):before, .pswp-custom-button__close-line:nth-of-type(2):before, .header__burger-line:nth-of-type(2):after, .pswp-custom-button__close-line:nth-of-type(2):after {
  transition-delay: 50ms;
}
.header__burger-line:nth-of-type(3):before, .pswp-custom-button__close-line:nth-of-type(3):before, .header__burger-line:nth-of-type(3):after, .pswp-custom-button__close-line:nth-of-type(3):after {
  transition-delay: 100ms;
}
.header__burger-line:first-of-type, .pswp-custom-button__close-line:first-of-type {
  margin-top: auto;
  margin-bottom: 2px;
}
.header__burger-line:last-of-type, .pswp-custom-button__close-line:last-of-type {
  margin-top: 2px;
  margin-bottom: auto;
}
.header__burger-line:before, .pswp-custom-button__close-line:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  background-color: var(--color-orange);
  transition: transform 0.2s ease;
}
.header__burger-line:after, .pswp-custom-button__close-line:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(calc(-100% - 4px));
  background-color: currentColor;
  transition: transform 0.4s ease;
}

.header__burger_opened .header__burger-line:nth-of-type(1), .header__burger_opened .pswp-custom-button__close-line:nth-of-type(1), .header__burger_opened:hover .header__burger-line:nth-of-type(1) {
  transform: scaleX(1) rotate(45deg) translate(2px, 2px);
}
.header__burger_opened .header__burger-line:nth-of-type(2), .header__burger_opened .pswp-custom-button__close-line:nth-of-type(2), .header__burger_opened:hover .header__burger-line:nth-of-type(2) {
  transform: scaleX(1) rotate(-45deg) translate(2px, -2px);
}
.header__burger_opened .header__burger-line:nth-of-type(3), .header__burger_opened .pswp-custom-button__close-line:nth-of-type(3), .header__burger_opened:hover .header__burger-line:nth-of-type(3) {
  transform-origin: right center;
  transform: scaleX(0);
}

.header__burger_opened .header__label-burger_closed-open, .header__burger_opened .header__label-burger_closed-hover {
  transform: translateY(-100%) !important;
  opacity: 0 !important;
}

.header__overlay-menu-back {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  pointer-events: auto;
  z-index: 500;
  transform: translateY(-30px);
  transition: all 0.3s ease;
}
.header__overlay-menu-back:hover .header__arrow-back-line:before {
  transform: translateX(100%);
}
.header__overlay-menu-back:hover .header__arrow-back-line:after {
  transform: translateX(0%);
}
.header__overlay-menu-back:hover .header__label_opened-back {
  transform: translateY(-100%);
  opacity: 0;
}
.header__overlay-menu-back:hover .header__label_opened-hover {
  transform: translateY(0%);
  opacity: 1;
}

.has-cursor-follower .header__overlay-menu-back[data-arts-cursor-follower-target]:hover .header__label {
  transform: translateX(20px);
}

.header__label_opened-hover {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.3s ease;
  color: currentColor;
}

.header__label_opened-back {
  position: relative;
  transform: translateY(0%);
  transition: all 0.3s ease;
}

.header__col {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: calc(1 * (var(--header-min-gutters-horizontal) * 1px));
  padding-right: calc(1 * (var(--header-min-gutters-horizontal) * 1px));
  padding-top: calc(1 * (var(--header-min-gutters-vertical) * 1px));
  padding-bottom: calc(1 * (var(--header-min-gutters-vertical) * 1px));
  transition: all 0.3s ease;
  z-index: 500;
}
@media screen and (min-width: 360px) {
  .header__col {
    padding-left: calc(1 * (var(--header-min-gutters-horizontal) * 1px + (var(--header-max-gutters-horizontal) - var(--header-min-gutters-horizontal)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .header__col {
    padding-left: calc(1 * (var(--header-max-gutters-horizontal) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .header__col {
    padding-right: calc(1 * (var(--header-min-gutters-horizontal) * 1px + (var(--header-max-gutters-horizontal) - var(--header-min-gutters-horizontal)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .header__col {
    padding-right: calc(1 * (var(--header-max-gutters-horizontal) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .header__col {
    padding-top: calc(1 * (var(--header-min-gutters-vertical) * 1px + (var(--header-max-gutters-vertical) - var(--header-min-gutters-vertical)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .header__col {
    padding-top: calc(1 * (var(--header-max-gutters-vertical) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .header__col {
    padding-bottom: calc(1 * (var(--header-min-gutters-vertical) * 1px + (var(--header-max-gutters-vertical) - var(--header-min-gutters-vertical)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .header__col {
    padding-bottom: calc(1 * (var(--header-max-gutters-vertical) * 1px));
  }
}
.header__col:hover .header__label-normal {
  transform: translateY(-100%);
  opacity: 0;
}
.header__col:hover .header__label-hover {
  transform: translateY(0%);
  opacity: 1;
}

.has-cursor-follower .header__col_left[data-arts-cursor-follower-target]:hover .header__label {
  transform: translateX(20px);
}
.has-cursor-follower .header__col_right[data-arts-cursor-follower-target]:hover .header__label, .has-cursor-follower .header__col_center[data-arts-cursor-follower-target]:hover .header__label {
  transform: translateX(-20px);
}

.header__col-overlay, .header__col-widgets {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.header__col-overlay {
  padding-left: var(--gutter-horizontal);
  padding-right: calc(var(--gutter-horizontal) / 2);
}

.header__col-widgets {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border-left: 1px solid var(--color-border-opacity);
  padding-left: calc(var(--gutter-horizontal) / 2);
  padding-right: var(--gutter-horizontal);
  padding-bottom: var(--gutter-vertical);
}

.header__col_left {
  text-align: left;
}

.header__col_center {
  text-align: center;
}

.header__col_right {
  text-align: right;
}

@media screen and (max-width: 991px) {
  .header__col-overlay, .header__col-widgets {
    justify-content: flex-start;
    padding-left: var(--gutter-horizontal);
    padding-right: var(--gutter-horizontal);
    height: auto;
  }
  .header__col-overlay {
    padding-top: calc(var(--gutter-vertical) + var(--header-height) * 1.4);
    padding-bottom: calc(var(--gutter-vertical) * 1.4);
  }
  .header__col-widgets {
    padding-top: calc(var(--gutter-vertical) * 1.4);
    padding-bottom: calc(var(--gutter-vertical) * 1.4);
    border-left: none;
  }
  .header__col-widgets::before {
    content: "";
    width: 30px;
    height: 2px;
    background: var(--color-orange);
    position: relative;
    top: -20px;
  }
  .header__col_md-border-left {
    border-left: none;
  }
  .header__col_md-border-right {
    border-right: none;
  }
}
.pt-header-height {
  padding-top: var(--header-height);
}

.pb-header-height {
  padding-bottom: var(--header-height);
}

.py-header-height {
  padding-top: var(--header-height);
  padding-bottom: var(--header-height);
}

.header-height {
  height: var(--header-height);
}

.mt-header-height {
  margin-top: var(--header-height);
}

.mb-header-height {
  margin-bottom: var(--header-height);
}

.my-header-height {
  margin-top: var(--header-height);
  margin-bottom: var(--header-height);
}

.header_classic-menu-right .menu-classic .sub-menu {
  left: auto;
  right: 1em;
}
.header_classic-menu-right .menu-classic .sub-menu ul {
  left: auto;
  right: calc(100% + 1px);
}

.header_classic-menu-split-center .header__col_classic-right .menu-classic .sub-menu {
  left: auto;
  right: 1em;
}
.header_classic-menu-split-center .header__col_classic-right .menu-classic .sub-menu ul {
  left: auto;
  right: calc(100% + 1px);
}

.header_overlay-logo-center-burger-left .header__overlay-menu-back {
  top: var(--header-height);
}
@media screen and (max-width: 991px) {
  .header_overlay-logo-center-burger-left .sub-menu-holder {
    top: var(--header-height);
  }
}

[data-arts-header-logo=primary] .logo__img-primary {
  opacity: 1;
  visibility: visible;
}
[data-arts-header-logo=primary] .logo__img-secondary {
  opacity: 0;
  visibility: hidden;
}

[data-arts-header-logo=secondary] .logo__img-primary {
  opacity: 0;
  visibility: hidden;
}
[data-arts-header-logo=secondary] .logo__img-secondary {
  opacity: 1;
  visibility: visible;
}

.header.opened .header__col {
  border-color: transparent;
}
.header.opened .header__col_logo, .header.opened .header__col_additional {
  transform: translateY(-30px);
  opacity: 0;
  visibility: hidden;
}
.header.opened .header__wrapper-overlay-menu {
  display: block;
  pointer-events: auto;
}
.header.opened .header__border-horizontal {
  transform: scaleX(0);
}
.header.opened .header__border-vertical {
  transform: scaleY(0);
}
.header.opened.opened-submenu .header__overlay-menu-back, .header.opened.opened-submenu .header__overlay-submenu-status {
  pointer-events: initial;
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.header.opened.opened-submenu .menu-overlay__counter {
  border-color: transparent;
}
.header.animating .header__wrapper-overlay-menu *:not(.header__overlay-menu-back) {
  pointer-events: none !important;
}
.header.animating .menu-overlay__counter {
  border-color: transparent;
}

.header:not(.opened) .header__wrapper-overlay-menu {
  pointer-events: none !important;
}
.header:not(.opened) .header__wrapper-overlay-menu * {
  pointer-events: none !important;
}

.header__wrapper-overlay-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}

.header_hidden-not-opened:not(.opened) {
  opacity: 0;
  visibility: hidden;
}

.header__overlay-inner {
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .header:not(.opened-submenu) .header__overlay-inner {
    overflow-x: hidden;
    overflow-y: overlay;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
  }
}
.header__overlay-submenu-status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  padding-right: calc(var(--gutter-horizontal) / 2);
  pointer-events: none !important;
  z-index: 500;
  transform: translateY(-30px);
  transition: all 0.3s ease;
}

.header__submenu-label {
  display: inline-block;
  line-height: 1;
  height: 40px;
  text-align: right;
  transition: transform 0.3s ease;
}

.header__label_opened-next {
  position: absolute;
  top: calc(50% - 8px);
  transform: translate(-50%, 100%);
  right: 0;
  opacity: 0;
  color: currentColor;
}

.header__label_opened-current {
  position: absolute;
  top: calc(50% - 8px);
  transform: translate(-50%, -50%);
  right: 0;
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .header__overlay-submenu-status {
    left: 0;
    right: 0;
    text-align: center;
    padding-right: var(--gutter-horizontal);
  }
}
@media screen and (max-width: 767px) {
  .header__overlay-submenu-status {
    display: none;
    top: auto;
    bottom: 0;
  }
}
.header__widget {
  margin-bottom: var(--gutter-vertical);
}
.header__widget:last-child {
  margin-bottom: 0;
}

.header__widget-title {
  margin-bottom: 0.5em;
}

.header__widget-content {
  line-height: 1.8;
}

.h-200 {
  max-height: 100vh;
  height: 200px;
}

.h-300 {
  max-height: 100vh;
  height: 300px;
}

.h-400 {
  max-height: 100vh;
  height: 400px;
}

.h-500 {
  max-height: 100vh;
  height: 500px;
}

.h-600 {
  max-height: 100vh;
  height: 600px;
}

.h-700 {
  max-height: 100vh;
  height: 700px;
}

.h-800 {
  max-height: 100vh;
  height: 800px;
}

.h-900 {
  max-height: 100vh;
  height: 900px;
}

.h-1000 {
  max-height: 100vh;
  height: 1000px;
}

@media screen and (min-width: 992px) {
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
}
.of-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.of-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.full-height {
  position: relative !important;
  display: inline-block !important;
  align-items: center !important;
  justify-content: center !important;
  width: auto !important;
  height: 100% !important;
  max-width: unset !important;
}

.hs-full-height {
  width: 100%;
  height: auto;
}

.auto-width-height {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}

.full-width {
  width: 100% !important;
  height: auto !important;
}

.of-cover-absolute {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.op-top-center {
  object-position: top center;
}

.op-right-center {
  object-position: right center;
}

.op-bottom-center {
  object-position: bottom center;
}

.op-left-center {
  object-position: left center;
}

.overflow-hidden {
  overflow: hidden;
  clip-path: inset(0px);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  --overlay-gradient-opacity-start: 1;
  --overlay-gradient-opacity-end: 0.25;
  --overlay-opacity: 0;
  background-color: rgba(var(--overlay-rgb), var(--overlay-opacity));
  transform: translateZ(0);
}

.overlay_dark {
  --overlay-rgb: 0, 0, 0;
}

.overlay_light {
  --overlay-rgb: 255, 255, 255;
}

/** Themed overlays **/
.overlay_theme-from-top-to-bottom {
  background-image: linear-gradient(180deg, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-start)) 0%, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-end)) 100%);
}

.overlay_theme-from-bottom-to-top {
  background-image: linear-gradient(0deg, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-start)) 0%, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-end)) 100%);
}

.overlay_theme-from-left-to-right {
  background-image: linear-gradient(90deg, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-start)) 0%, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-end)) 100%);
}

.overlay_theme-from-right-to-left {
  background-image: linear-gradient(270deg, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-start)) 0%, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-end)) 100%);
}

.overlay_opacity-10 {
  --overlay-opacity: 0.1;
}

.overlay_opacity-20 {
  --overlay-opacity: 0.2;
}

.overlay_opacity-30 {
  --overlay-opacity: 0.3;
}

.overlay_opacity-40 {
  --overlay-opacity: 0.4;
}

.overlay_opacity-50 {
  --overlay-opacity: 0.5;
}

.overlay_opacity-60 {
  --overlay-opacity: 0.6;
}

.overlay_opacity-70 {
  --overlay-opacity: 0.7;
}

.overlay_opacity-80 {
  --overlay-opacity: 0.8;
}

.overlay_opacity-90 {
  --overlay-opacity: 0.9;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33% !important;
}

.w-40 {
  width: 40% !important;
}

@media screen and (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-33 {
    width: 33% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-66 {
    width: 66% !important;
  }
}
.z-minus-1 {
  z-index: -1 !important;
}

.z-100 {
  z-index: 100 !important;
}

.z-50 {
  z-index: 50 !important;
}

.z-40 {
  z-index: 40 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-0 {
  z-index: 0 !important;
}

.horizontal-scroll {
  display: flex;
}
.horizontal-scroll.has-horizontal-scroll {
  overflow: hidden;
}
.horizontal-scroll.has-horizontal-scroll .js-horizontal-scroll__wrapper .js-horizontal-scroll__section {
  transform: translate3d(var(--translateX), var(--translateY), 0);
}
.horizontal-scroll.has-horizontal-scroll .horizontal-scroll__wrapper {
  flex-wrap: nowrap;
  height: 100vh;
  width: 100%;
}
.horizontal-scroll.has-horizontal-scroll .horizontal-scroll__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.horizontal-scroll.has-horizontal-scroll .horizontal-scroll__divider {
  display: none;
}

.horizontal-scroll__bordered {
  box-shadow: 0px 0 0 0 var(--color-borders), 0 0 0 0 var(--color-borders), 1px 1px 0 0 var(--color-borders), 0px 0 0 0 var(--color-borders) inset, 0 0 0 0 var(--color-borders) inset;
}

.horizontal-scroll__wrapper {
  position: relative;
  display: flex;
  width: 100%;
}

.horizontal-scroll__section {
  position: relative;
}

.horizontal-scroll__header {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.horizontal-scroll__divider {
  width: 100%;
  display: block;
}

.no-horizontal-scroll .no-hs-text-start {
  text-align: left !important;
}
.no-horizontal-scroll .no-hs-text-center {
  text-align: center !important;
}
.no-horizontal-scroll .no-hs-text-end {
  text-align: right !important;
}
.no-horizontal-scroll .no-hs-align-items-center {
  align-items: center !important;
}
.no-horizontal-scroll .no-hs-d-block {
  display: block !important;
}
.no-horizontal-scroll .no-hs-d-flex {
  display: flex !important;
}
.no-horizontal-scroll .no-hs-d-inline-flex {
  display: inline-flex !important;
}
.no-horizontal-scroll .no-hs-d-none {
  display: none !important;
}
.no-horizontal-scroll .no-hs-pt-header-height {
  padding-top: var(--header-height);
}
.no-horizontal-scroll .no-hs-pb-header-height {
  padding-bottom: var(--header-height);
}
.no-horizontal-scroll .no-hs-py-header-height {
  padding-top: var(--header-height);
  padding-bottom: var(--header-height);
}
.no-horizontal-scroll .no-hs-header-height {
  height: var(--header-height);
}
.no-horizontal-scroll .no-hs-mt-0 {
  margin-top: 0 !important;
}
.no-horizontal-scroll .no-hs-mb-0 {
  margin-bottom: 0 !important;
}
.no-horizontal-scroll .no-hs-me-0 {
  margin-right: 0 !important;
}
.no-horizontal-scroll .no-hs-ms-0 {
  margin-left: 0 !important;
}
.no-horizontal-scroll .no-hs-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.no-horizontal-scroll .no-hs-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.no-horizontal-scroll .no-hs-m-0 {
  margin: 0 !important;
}
.no-horizontal-scroll .no-hs-pt-0 {
  padding-top: 0 !important;
}
.no-horizontal-scroll .no-hs-pb-0 {
  padding-bottom: 0 !important;
}
.no-horizontal-scroll .no-hs-pe-0 {
  padding-right: 0 !important;
}
.no-horizontal-scroll .no-hs-ps-0 {
  padding-left: 0 !important;
}
.no-horizontal-scroll .no-hs-py-0 {
  paddipg-top: 0 !important;
  padding-bottom: 0 !important;
}
.no-horizontal-scroll .no-hs-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.no-horizontal-scroll .no-hs-p-0 {
  padding: 0 !important;
}
@media screen and (min-width: 992px) {
  .no-horizontal-scroll .no-hs-pb-lg-0 {
    padding-bottom: 0 !important;
  }
}
@media screen and (max-width: 1100px) {
  .no-horizontal-scroll .no-hs-max-lg-pt-0 {
    padding-top: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-lg-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-lg-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-lg-pb-0 {
    padding-bottom: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-lg-p-0 {
    padding: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-lg-mt-0 {
    margin-top: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-lg-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-lg-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-lg-mb-0 {
    margin-bottom: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-lg-m-0 {
    margin: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  .no-horizontal-scroll .no-hs-max-sm-pt-0 {
    padding-top: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-sm-pb-0 {
    padding-bottom: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-sm-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-sm-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-sm-mt-0 {
    margin-top: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-sm-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-sm-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-sm-mb-0 {
    margin-bottom: 0 !important;
  }
  .no-horizontal-scroll .no-hs-max-sm-m-0 {
    margin: 0 !important;
  }
}

.has-horizontal-scroll .hs-full-height {
  position: relative !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: auto !important;
  height: 100% !important;
  max-width: unset !important;
}
.has-horizontal-scroll .no-hs-d-block {
  display: none !important;
}
.has-horizontal-scroll .no-hs-d-flex {
  display: none !important;
}
.has-horizontal-scroll .no-hs-d-inline-flex {
  display: none !important;
}
.has-horizontal-scroll .no-hs-d-none {
  display: block !important;
}
.has-horizontal-scroll .hs-pt-header-height {
  padding-top: var(--header-height);
}
.has-horizontal-scroll .hs-pb-header-height {
  padding-bottom: var(--header-height);
}
.has-horizontal-scroll .hs-py-header-height {
  padding-top: var(--header-height);
  padding-bottom: var(--header-height);
}
.has-horizontal-scroll .hs-header-height {
  height: var(--header-height);
}
.has-horizontal-scroll .hs-mt-header-height {
  margin-top: var(--header-height);
}
.has-horizontal-scroll .hs-mb-header-height {
  margin-bottom: var(--header-height);
}
.has-horizontal-scroll .hs-my-header-height {
  margin-top: var(--header-height);
  margin-bottom: var(--header-height);
}
.has-horizontal-scroll .hs-vh-100 {
  height: 100vh !important;
}
.has-horizontal-scroll .hs-col {
  flex: 1 0 0%;
}
.has-horizontal-scroll .row-hs-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.has-horizontal-scroll .row-hs-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.has-horizontal-scroll .row-hs-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.has-horizontal-scroll .row-hs-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.has-horizontal-scroll .row-hs-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.has-horizontal-scroll .row-hs-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.has-horizontal-scroll .row-hs-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.has-horizontal-scroll .hs-col-auto {
  flex: 0 0 auto;
  width: auto;
}
.has-horizontal-scroll .hs-col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.has-horizontal-scroll .hs-col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.has-horizontal-scroll .hs-col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.has-horizontal-scroll .hs-col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.has-horizontal-scroll .hs-col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.has-horizontal-scroll .hs-col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.has-horizontal-scroll .hs-col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.has-horizontal-scroll .hs-col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.has-horizontal-scroll .hs-col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.has-horizontal-scroll .hs-col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.has-horizontal-scroll .hs-col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.has-horizontal-scroll .hs-col-12 {
  flex: 0 0 auto;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .has-horizontal-scroll .hs-pb-lg-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) {
  .has-horizontal-scroll .hs-col-sm {
    flex: 1 0 0%;
  }
  .has-horizontal-scroll .row-hs-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .has-horizontal-scroll .row-hs-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .has-horizontal-scroll .row-hs-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .has-horizontal-scroll .row-hs-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .has-horizontal-scroll .row-hs-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .has-horizontal-scroll .row-hs-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .has-horizontal-scroll .row-hs-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .has-horizontal-scroll .hs-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .has-horizontal-scroll .hs-col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .has-horizontal-scroll .hs-col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .has-horizontal-scroll .hs-col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .has-horizontal-scroll .hs-col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .has-horizontal-scroll .hs-col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .has-horizontal-scroll .hs-col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .has-horizontal-scroll .hs-col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .has-horizontal-scroll .hs-col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .has-horizontal-scroll .hs-col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .has-horizontal-scroll .hs-col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .has-horizontal-scroll .hs-col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .has-horizontal-scroll .hs-col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .has-horizontal-scroll .hs-col-md {
    flex: 1 0 0%;
  }
  .has-horizontal-scroll .row-hs-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .has-horizontal-scroll .row-hs-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .has-horizontal-scroll .row-hs-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .has-horizontal-scroll .row-hs-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .has-horizontal-scroll .row-hs-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .has-horizontal-scroll .row-hs-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .has-horizontal-scroll .row-hs-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .has-horizontal-scroll .hs-col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .has-horizontal-scroll .hs-col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .has-horizontal-scroll .hs-col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .has-horizontal-scroll .hs-col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .has-horizontal-scroll .hs-col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .has-horizontal-scroll .hs-col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .has-horizontal-scroll .hs-col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .has-horizontal-scroll .hs-col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .has-horizontal-scroll .hs-col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .has-horizontal-scroll .hs-col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .has-horizontal-scroll .hs-col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .has-horizontal-scroll .hs-col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .has-horizontal-scroll .hs-col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .has-horizontal-scroll .hs-col-lg {
    flex: 1 0 0%;
  }
  .has-horizontal-scroll .row-hs-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .has-horizontal-scroll .row-hs-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .has-horizontal-scroll .row-hs-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .has-horizontal-scroll .row-hs-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .has-horizontal-scroll .row-hs-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .has-horizontal-scroll .row-hs-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .has-horizontal-scroll .row-hs-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .has-horizontal-scroll .hs-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .has-horizontal-scroll .hs-col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .has-horizontal-scroll .hs-col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .has-horizontal-scroll .hs-col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .has-horizontal-scroll .hs-col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .has-horizontal-scroll .hs-col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .has-horizontal-scroll .hs-col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .has-horizontal-scroll .hs-col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .has-horizontal-scroll .hs-col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .has-horizontal-scroll .hs-col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .has-horizontal-scroll .hs-col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .has-horizontal-scroll .hs-col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .has-horizontal-scroll .hs-col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .has-horizontal-scroll .hs-col-xl {
    flex: 1 0 0%;
  }
  .has-horizontal-scroll .row-hs-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .has-horizontal-scroll .row-hs-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .has-horizontal-scroll .row-hs-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .has-horizontal-scroll .row-hs-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .has-horizontal-scroll .row-hs-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .has-horizontal-scroll .row-hs-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .has-horizontal-scroll .row-hs-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .has-horizontal-scroll .hs-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .has-horizontal-scroll .hs-col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .has-horizontal-scroll .hs-col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .has-horizontal-scroll .hs-col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .has-horizontal-scroll .hs-col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .has-horizontal-scroll .hs-col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .has-horizontal-scroll .hs-col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .has-horizontal-scroll .hs-col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .has-horizontal-scroll .hs-col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .has-horizontal-scroll .hs-col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .has-horizontal-scroll .hs-col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .has-horizontal-scroll .hs-col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .has-horizontal-scroll .hs-col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .has-horizontal-scroll .hs-col-xxl {
    flex: 1 0 0%;
  }
  .has-horizontal-scroll .row-hs-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .has-horizontal-scroll .row-hs-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .has-horizontal-scroll .row-hs-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .has-horizontal-scroll .row-hs-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .has-horizontal-scroll .row-hs-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .has-horizontal-scroll .row-hs-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .has-horizontal-scroll .row-hs-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .has-horizontal-scroll .hs-col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .has-horizontal-scroll .hs-col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .has-horizontal-scroll .hs-col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .has-horizontal-scroll .hs-col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .has-horizontal-scroll .hs-col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .has-horizontal-scroll .hs-col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .has-horizontal-scroll .hs-col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .has-horizontal-scroll .hs-col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .has-horizontal-scroll .hs-col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .has-horizontal-scroll .hs-col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .has-horizontal-scroll .hs-col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .has-horizontal-scroll .hs-col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .has-horizontal-scroll .hs-col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.hover-scroll {
  --container-height: 66vh;
}

.hover-scroll__inner {
  height: var(--container-height);
  overflow: hidden;
}

.hover-scroll__link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.hover-scroll__link:hover .hover-scroll__element {
  transform: translateY(calc(-100% + var(--container-height)));
}

.hover-scroll__element {
  transition: transform 5s ease;
}
.icon-box {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
}
.icon-box:hover .icon-box__wrapper-icon {
  border-color: currentColor;
}

.has-cursor-follower .icon-box[data-arts-cursor-follower-target*="magnetic: true"]:hover .icon-box__wrapper-icon {
  border-color: transparent;
}

.icon-box__wrapper-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(1 * (120 * 1px));
  height: calc(1 * (120 * 1px));
  border-radius: 50%;
  border: 1px solid var(--color-borders);
  transition: border-color 0.3s ease;
}
@media screen and (min-width: 360px) {
  .icon-box__wrapper-icon {
    width: calc(1 * (120 * 1px + (160 - 120) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .icon-box__wrapper-icon {
    width: calc(1 * (160 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .icon-box__wrapper-icon {
    height: calc(1 * (120 * 1px + (160 - 120) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .icon-box__wrapper-icon {
    height: calc(1 * (160 * 1px));
  }
}

.icon-box__icon {
  font-size: calc(30 * 1px);
}
@media screen and (min-width: 360px) {
  .icon-box__icon {
    font-size: calc(30 * 1px + (40 - 30) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .icon-box__icon {
    font-size: calc(40 * 1px);
  }
}

.infinite-list {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.infinite-list_hovered a.infinite-list__item-heading.current, .infinite-list_hovered .infinite-list__item-heading.current a, .infinite-list_hovered-zoom-out a.infinite-list__item-heading.current, .infinite-list_hovered-zoom-out .infinite-list__item-heading.current a {
  color: var(--color-accent);
}
.infinite-list_hovered a.infinite-list__item-heading.current *, .infinite-list_hovered .infinite-list__item-heading.current a *, .infinite-list_hovered-zoom-out a.infinite-list__item-heading.current *, .infinite-list_hovered-zoom-out .infinite-list__item-heading.current a * {
  color: var(--color-accent);
}

.infinite-list__content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  pointer-events: none;
}
.infinite-list__content a {
  pointer-events: auto;
}

.infinite-list__content_bottom {
  top: auto;
  bottom: var(--gutter-vertical);
  transform: translateY(0%);
}

.infinite-list__content_top {
  top: var(--gutter-vertical);
  bottom: auto;
  transform: translateY(0%);
}

.infinite-list__content_relative {
  position: relative;
  top: auto;
  bottom: auto;
  transform: none;
}

.infinite-list__images {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 40;
}

.infinite-list__images-lane {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  clip-path: inset(0px);
  z-index: 10;
  pointer-events: none;
}

.infinite-list__images-lane_left {
  left: 0;
  right: auto;
}

.infinite-list__images-lane_right {
  left: auto;
  right: 0;
}

.infinite-list__image-link {
  position: relative;
  display: block;
  pointer-events: auto;
}

.infinite-list__item-heading {
  display: inline-block;
}

.infinite-list__image-item_lock-inactive:not(.active) {
  pointer-events: none;
}
.infinite-list__image-item_lock-inactive:not(.active) * {
  pointer-events: none;
}

.infinite-list__image-inner {
  width: 100%;
}

.infinite-list__counters {
  transition: opacity 0.3s ease;
  white-space: nowrap;
}

.infinite-list__image-item_narrow-width {
  width: 760px;
  max-width: 66vw;
  flex: 1 0 760px;
}

.infinite-list__image-item_halfscreen-width {
  width: 100%;
  max-width: calc(50vw - var(--gutter-horizontal) * 2);
}

.infinite-list__hide-on-hover {
  transition: all 0.3s ease;
  transform: translateY(0%);
  opacity: 1;
  visibility: visible;
}

.infinite-list__header {
  overflow: hidden;
}

.infinite-list__header_absolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.infinite-list__opacity-link {
  transition: all 0.3s ease;
}

.infinite-list__marquee-row {
  display: flex;
  flex-wrap: wrap;
}

.infinite-list__marquee-image-inner {
  padding: max(var(--gutter-horizontal) / 2, var(--gutter-horizontal-min) * 1px);
}

.infinite-list__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media screen and (min-width: 992px) {
  .infinite-list__marquee-row {
    height: 100%;
    padding-left: max(var(--gutter-horizontal) / 2, var(--gutter-horizontal-min) * 1px);
    padding-right: max(var(--gutter-horizontal) / 2, var(--gutter-horizontal-min) * 1px);
  }
}
@media screen and (max-width: 991px) {
  .infinite-list__image-item_narrow-width, .infinite-list__image-item_halfscreen-width {
    max-width: 50vw;
  }
}
.infinite-list[data-arts-os-animation=true] .infinite-list__button-hover {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
}

.infinite-list_hovered .infinite-list__button-normal, .infinite-list_hovered-zoom-out .infinite-list__button-normal {
  transform: scale(0);
}
.infinite-list_hovered .infinite-list__button-hover, .infinite-list_hovered-zoom-out .infinite-list__button-hover {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
.infinite-list_hovered .infinite-list__button-hover *, .infinite-list_hovered-zoom-out .infinite-list__button-hover * {
  color: var(--color-accent);
}

.infinite-list__wrapper-button, .auto-scroll-next__wrapper-button {
  display: inline-block;
  line-height: 1;
  position: absolute;
  left: var(--gutter-horizontal);
  bottom: var(--gutter-vertical);
  z-index: 50;
  pointer-events: none;
}
.infinite-list__wrapper-button.text-start, .text-start.auto-scroll-next__wrapper-button {
  left: var(--gutter-horizontal);
  right: auto;
}
.infinite-list__wrapper-button.text-center, .text-center.auto-scroll-next__wrapper-button {
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
}
.infinite-list__wrapper-button.text-end, .text-end.auto-scroll-next__wrapper-button {
  left: auto;
  right: var(--gutter-horizontal);
}

.infinite-list__wrapper-button_top {
  top: var(--gutter-vertical);
  bottom: auto;
}

.pt-header-height .infinite-list__wrapper-button_top {
  top: calc(var(--gutter-vertical) + var(--header-height));
}

.infinite-list__button-normal, .infinite-list__button-hover, .infinite-list__button-static {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.infinite-list__button-hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.infinite-list__inner-headings {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.infinite-list__item-heading {
  display: inline-block;
  line-height: 1;
  transform: translateY(100%);
  transition: all 0.3s ease;
}
.infinite-list__item-heading:hover * {
  color: currentColor;
}

.infinite-list:not(.infinite-list_dragging) .infinite-list__item-heading.current {
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%);
}

.infinite-list__inner {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.infinite-list__zoom, .infinite-list__inner {
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.infinite-list_mouse-drag {
  cursor: grab;
}
.infinite-list_mouse-drag * {
  cursor: grab;
}
.infinite-list_mouse-drag.infinite-list_pressed {
  cursor: grabbing;
}
.infinite-list_mouse-drag.infinite-list_pressed * {
  cursor: grabbing;
}

.infinite-list_scrolling * {
  pointer-events: none;
}

.infinite-list_dragging {
  user-select: none;
}
.infinite-list_dragging * {
  pointer-events: none;
}

.infinite-list_scrolling .infinite-list__counters {
  opacity: 0.1;
  transition-delay: 0.4s;
}

.infinite-list_dragging .infinite-list__counters {
  opacity: 0.1;
  transition-delay: 0s;
}
.infinite-list_dragging .arts-is-dragging__blocker {
  opacity: 1;
  visibility: visible;
}

.infinite-list_pressed .infinite-list__zoom {
  transform: scale(0.95);
}
.infinite-list_pressed .infinite-list__inner {
  transform: scale(1.05);
}

.infinite-list_hovered:not(.infinite-list_dragging) .infinite-list__hide-on-hover, .infinite-list_hovered-zoom-out:not(.infinite-list_dragging) .infinite-list__hide-on-hover {
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
}
.infinite-list_hovered:not(.infinite-list_dragging) .infinite-list__opacity-link:not(.current), .infinite-list_hovered-zoom-out:not(.infinite-list_dragging) .infinite-list__opacity-link:not(.current) {
  opacity: 0.5;
}

.infinite-list_hovered .infinite-list__zoom-link.current .infinite-list__zoom {
  transform: scale(0.98);
}
.infinite-list_hovered .infinite-list__zoom-link.current .infinite-list__inner {
  transform: scale(1.02);
}

.infinite-list_hovered-zoom-out .infinite-list__zoom-link:not(.current) .infinite-list__zoom {
  transform: scale(0.95);
}
.infinite-list_hovered-zoom-out .infinite-list__zoom-link:not(.current) .infinite-list__inner {
  transform: scale(1.05);
}

.infinite-list__wrapper-counter {
  position: absolute;
  top: 0;
  left: 1em;
  white-space: nowrap;
  transform: none !important;
}
.infinite-list__wrapper-counter [data-arts-split-text-element=char]:nth-child(1), .infinite-list__wrapper-counter .js-arts-split-text__char:nth-child(1) {
  z-index: -1;
  transform: translateY(-33%);
}
.infinite-list__wrapper-counter [data-arts-split-text-element=char]:nth-child(2), .infinite-list__wrapper-counter .js-arts-split-text__char:nth-child(2) {
  z-index: 60;
  transform: translateY(-33%);
}

.infinite-list__wrapper-counter:not([data-arts-split-text-ready=true]) {
  transform: translateY(-33%);
}

.infinite-list__wrapper-counter_right {
  left: auto;
  right: 1em;
}

@media screen and (max-width: 991px) {
  .infinite-list__wrapper-counter {
    left: auto;
    right: 1em;
  }
}
.inline-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin: -0.5em;
  text-align: inherit;
}
.inline-links li {
  display: inline-block !important;
  margin: 0.5em;
}
.inline-links li a {
  display: inline-block !important;
}

.lazy {
  transition: background-color 0.1s ease;
}

.lazy:not(.loaded):not(.entered) {
  background-color: rgba(136, 136, 136, 0.1);
}

video.lazy:not(.error) + img.lazy {
  opacity: 0;
  visibility: hidden;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: -1;
  }
  50% {
    stroke-dasharray: 75, 200;
    stroke-dashoffset: -75;
  }
  100% {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -150;
  }
}
.logo {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

a.logo:hover {
  opacity: 0.8;
}

.logo__wrapper-img {
  position: relative;
  align-items: center;
  flex: 1 0 auto;
}
.logo__wrapper-img img {
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  display: block;
}

.logo__text-title {
  display: block;
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  color: var(--paragraph-color);
}

.logo__text-tagline {
  display: block;
  font-style: italic;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.3;
}

.logo__img-secondary {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.logo__description {
  margin-top: 1em;
}

.marquee-crossing-lanes__wrapper-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
}

.marquee-crossing-lanes__lane {
  position: relative;
  transform-origin: center center;
  margin-left: -2rem;
  margin-right: -2rem;
  width: auto;
  outline: 2px solid transparent;
  zoom: 1.005;
}

.marquee-crossing-lanes__lane_rotate-1 {
  transform: rotateZ(-3deg) translate3d(0, 50%, 0);
  z-index: 50;
}

.marquee-crossing-lanes__lane_rotate-2 {
  transform: rotateZ(3deg) translate3d(0, -50%, 0);
  z-index: 40;
}

@media screen and (max-width: 1200px) {
  .marquee-crossing-lanes__lane_rotate-1 {
    transform: rotateZ(-4deg) translate3d(0, 50%, 0);
  }
  .marquee-crossing-lanes__lane_rotate-2 {
    transform: rotateZ(4deg) translate3d(0, -50%, 0);
  }
  .marquee-crossing-lanes__inner {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
@media screen and (max-width: 1100px) {
  .marquee-crossing-lanes__lane_rotate-1 {
    transform: rotateZ(-5deg) translate3d(0, 50%, 0);
  }
  .marquee-crossing-lanes__lane_rotate-2 {
    transform: rotateZ(5deg) translate3d(0, -50%, 0);
  }
}
.marquee-header {
  position: relative;
  width: 100%;
}

.marquee-header__lane {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
}

.marquee-header__lane_mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  margin: 0 auto;
}

.marquee-header__lane_v-center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: 100%;
}

.marquee-header__inner {
  position: relative;
  overflow: hidden;
  z-index: 50;
}

a.marquee-header__item {
  display: inline-block;
}
a.marquee-header__item:hover * {
  color: currentColor;
}

.auto-scroll-next__heading {
  margin: 0;
}

.marquee-headings-hover {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.marquee-headings-hover[data-arts-os-animation=true] .marquee-headings-hover__button-hover {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
}

.marquee-headings-hover__content {
  transition: opacity 0.2s ease;
  transition-delay: 0.3s;
  z-index: 50;
}

.marquee-headings-hover__header {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.marquee-headings-hover__wrapper-button {
  position: relative;
  display: inline-block;
}

.marquee-headings-hover__wrapper-button_absolute {
  display: inline-block;
  line-height: 1;
  position: absolute;
  left: var(--gutter-horizontal);
  bottom: var(--gutter-vertical);
  z-index: 50;
  pointer-events: none;
}
.marquee-headings-hover__wrapper-button_absolute.text-start {
  left: var(--gutter-horizontal);
  right: auto;
}
.marquee-headings-hover__wrapper-button_absolute.text-center {
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
}
.marquee-headings-hover__wrapper-button_absolute.text-end {
  left: auto;
  right: var(--gutter-horizontal);
}

.marquee-headings-hover__item {
  position: relative;
  display: inline-block;
  transition: color 0.3s ease;
  max-width: 100%;
  z-index: 50;
}
.marquee-headings-hover__item:hover * {
  color: currentColor;
}
.marquee-headings-hover__item:hover .marquee-headings-hover__counter {
  border-color: currentColor;
}

.marquee-headings-hover__button-normal, .marquee-headings-hover__button-hover {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.marquee-headings-hover__button-hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.marquee-headings-hover__heading, .headings-hover__item-image {
  transition: opacity 0.3s ease;
  max-width: 100%;
}

.headings-hover__item-image {
  width: 100%;
}

.marquee-headings-hover__heading {
  will-change: opacity;
}

.marquee-headings-hover__images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  overflow: hidden;
}

.marquee-headings-hover__inner-img {
  display: inline-flex;
}
.marquee-headings-hover__inner-img img, .marquee-headings-hover__inner-img video {
  flex: 1;
  height: 60vh;
  width: auto;
  object-fit: contain;
  object-position: center center;
}

.marquee-headings-hover__images_right .marquee-headings-hover__wrapper-img {
  left: auto;
  right: var(--gutter-horizontal);
  transform: translate(0%, -50%);
  max-width: calc(100% - var(--gutter-horizontal));
}

.marquee-headings-hover__images_left .marquee-headings-hover__wrapper-img {
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
  transform: translate(0%, -50%);
  max-width: calc(100% - var(--gutter-horizontal) * 2);
}

.marquee-headings-hover__wrapper-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(var(--shape-size) * 1%), 0% calc(var(--shape-size) * 1%));
  --shape-size: 0;
  z-index: 0;
}

.marquee-headings-hover__counter {
  vertical-align: middle;
  margin-right: calc(var(--gutter-horizontal) / 2);
  margin-bottom: 0.5em;
  transition: border-color 0.3s ease;
  flex: 1 0 auto;
}

.marquee-headings-hover__col-content {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

@media screen and (max-width: 991px) {
  .marquee-headings-hover__wrapper-img {
    max-width: calc(100% - var(--gutter-horizontal) * 2);
    width: auto;
    height: auto;
    margin: auto;
  }
  .marquee-headings-hover__images_right, .marquee-headings-hover__images_left {
    width: 100%;
    right: 0;
    left: 0;
  }
  .marquee-headings-hover__images_right .marquee-headings-hover__wrapper-img, .marquee-headings-hover__images_left .marquee-headings-hover__wrapper-img {
    max-width: calc(100% - var(--gutter-horizontal) * 2);
    left: 0;
    right: 0;
  }
}
.marquee-heading, .marquee-delimiter {
  display: inline-flex;
}

.marquee-headings-hover_hovered .marquee-headings-hover__item:not(.current) .marquee-headings-hover__heading, .marquee-headings-hover_hovered .marquee-headings-hover__item:not(.current) .headings-hover__item-image {
  opacity: 0.1;
  visibility: visible;
}
.marquee-headings-hover_hovered .marquee-headings-hover__button-normal {
  transform: scale(0);
}
.marquee-headings-hover_hovered .marquee-headings-hover__button-hover {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
.marquee-headings-hover_hovered .marquee-headings-hover__button-hover .rotating-button__link {
  color: var(--color-accent);
}
.marquee-headings-hover_hovered .marquee-headings-hover__content {
  opacity: 0;
  transition-delay: 0s;
}

.marquee-headings-hover__item.current * {
  color: currentColor;
}

.marquee-headings-hover_mouse-drag {
  cursor: grab;
  user-select: none;
}
.marquee-headings-hover_mouse-drag * {
  cursor: grab;
}
.marquee-headings-hover_mouse-drag.marquee-headings-hover_pressed {
  cursor: grabbing;
}
.marquee-headings-hover_mouse-drag.marquee-headings-hover_pressed * {
  cursor: grabbing;
}

.marquee-headings-hover_dragging .arts-is-dragging__blocker {
  opacity: 1;
  visibility: visible;
}

.js-mask__clone_has-color * {
  color: inherit !important;
}

.masthead__overlay {
  z-index: 10;
}

.masthead__scroll-down {
  display: inline-block;
  position: absolute;
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
  bottom: calc(var(--gutter-horizontal-min) * 1px);
  z-index: 100;
}
@media screen and (min-width: 992px) {
  .masthead__scroll-down.offset-lg-6 {
    margin-left: calc(50% - var(--gutter-horizontal));
  }
}

.masthead__scroll-down_left {
  left: var(--gutter-horizontal);
  right: auto;
}

.masthead__scroll-down_right {
  left: auto;
  right: var(--gutter-horizontal);
}

.masthead__header {
  position: relative;
  z-index: 50;
}

.masthead__footer {
  position: absolute;
  bottom: var(--gutter-vertical);
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
  z-index: 50;
}

.masthead__background-full-height {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  max-height: 100%;
  width: auto;
  z-index: 0;
}

.masthead__background-full-height_left {
  left: 0;
  right: auto;
}

.masthead__background-full-height_center {
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.masthead__background-full-height_right {
  left: auto;
  right: 0;
}

.masthead__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  z-index: 0;
}

.masthead__background-top-space {
  position: absolute;
  top: calc(var(--header-height) + var(--gutter-vertical) / 2);
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  max-width: 100%;
  max-height: calc(100% - var(--header-height) - var(--gutter-vertical) / 2);
}

.masthead__background_right {
  margin-left: auto;
}

.masthead__media {
  position: relative;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width: 1101px) {
  .masthead__background_right {
    right: var(--gutter-horizontal);
  }
}
@media screen and (max-width: 1100px) {
  .masthead__background-mobile {
    height: 50vh;
  }
  .masthead__scroll-down_mobile-center {
    left: var(--gutter-horizontal);
    right: var(--gutter-horizontal);
    text-align: center;
  }
}
.masthead-1__background {
  position: absolute;
  top: calc(var(--header-height) + var(--gutter-vertical));
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
  bottom: 0;
  height: 100%;
  max-width: calc(100% - var(--gutter-horizontal) * 2);
  max-height: calc(100% - var(--header-height) - var(--gutter-vertical) * 2);
}

.masthead-1__header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: calc(var(--header-height) + var(--gutter-vertical) * 2);
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
  padding-bottom: calc(var(--gutter-vertical) * 2);
  margin: 0;
  z-index: 50;
}

.masthead-1__heading-top {
  display: block;
  width: 100%;
  max-width: calc(50vw - var(--gutter-horizontal));
  text-align: right;
}

.masthead-1__heading-bottom {
  display: block;
  width: 100%;
  max-width: calc(50vw - var(--gutter-horizontal));
  margin-left: auto;
  text-align: left;
}

.masthead-1__description {
  width: 100%;
  max-width: calc(50% - var(--gutter-horizontal));
  z-index: 60;
}

@media screen and (max-width: 991px) {
  .masthead-1__header {
    justify-content: flex-start;
  }
  .masthead-1__heading-top, .masthead-1__heading-bottom {
    max-width: 100%;
    text-align: left;
  }
  .masthead-1__description {
    margin-top: auto;
    text-align: right;
    margin-left: auto;
  }
}
.masthead-2__background {
  position: relative;
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.masthead-2__wrapper-background {
  height: 100vh;
}

.masthead-2__header_absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.masthead-2__header {
  position: relative;
  z-index: 60;
}

@media screen and (max-width: 991px) {
  .masthead-2__wrapper-background {
    height: 66vh;
  }
}
@media screen and (min-width: 992px) {
  .masthead-2__header {
    margin-left: calc(-1 * (var(--distance-min-xlarge) * 1px));
  }
}
@media screen and (min-width: 992px) and (min-width: 360px) {
  .masthead-2__header {
    margin-left: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .masthead-2__header {
    margin-left: calc(-1 * (var(--distance-max-xlarge) * 1px));
  }
}
.media__link:hover * {
  color: currentColor;
}

.media__caption {
  margin-top: 1em;
}

@media screen and (max-width: 991px) {
  .media__caption {
    margin-bottom: 0.5em;
  }
}
.menu-classic {
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin: 0 -0.25em;
  font-weight: bold;
  font-size: 14px;
  line-height: 2;
}
.menu-classic > li {
  position: relative;
  display: inline-block;
}
.menu-classic > li a {
  display: block;
  margin-left: 1.7em;
  margin-right: 1.7em;
}
@media screen and (max-width: 1400px) {
  .menu-classic > li a {
    margin-left: 1.1em;
    margin-right: 1.1em;
  }
}
@media screen and (max-width: 1200px) {
  .menu-classic > li a {
    margin-right: calc(1 * (0 * 1px));
    margin-left: calc(1 * (0 * 1px));
  }
}
@media screen and (max-width: 1200px) and (min-width: 360px) {
  .menu-classic > li a {
    margin-right: calc(1 * (0 * 1px + (25 - 0) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (max-width: 1200px) and (min-width: 1920px) {
  .menu-classic > li a {
    margin-right: calc(1 * (25 * 1px));
  }
}
@media screen and (max-width: 1200px) and (min-width: 360px) {
  .menu-classic > li a {
    margin-left: calc(1 * (0 * 1px + (25 - 0) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (max-width: 1200px) and (min-width: 1920px) {
  .menu-classic > li a {
    margin-left: calc(1 * (25 * 1px));
  }
}
.menu-classic > li.menu-item-has-children > a:after {
  content: "+";
  display: inline-block;
  margin-left: 0.5em;
  transition: transform 0.3s ease;
  transform: rotate(0deg);
  transform-origin: center center;
}
.menu-classic > li.menu-item-has-children > a:hover:after {
  transform: rotate(-90deg);
}
.menu-classic > li:not(.menu-item-has-children) svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  stroke: currentColor;
  stroke-width: 1px;
  pointer-events: none;
}
.menu-classic .menu-item-has-children {
  position: relative;
}
.menu-classic .menu-item-has-children > a:hover ~ ul {
  opacity: 1;
  visibility: visible;
  transform: translate(0px, 0px);
  z-index: 50;
}
.menu-classic > li.current-menu-item > a {
  color: var(--color-accent);
}
.menu-classic > li.current-menu-ancestor > a {
  color: var(--color-accent);
}
.menu-classic > li.current-menu-ancestor .sub-menu li.current-menu-ancestor > a {
  color: var(--color-classic-submenu-links-hover);
}
.menu-classic > li.current-menu-ancestor .sub-menu li.current-menu-item > a {
  color: var(--color-classic-submenu-links-hover);
}
.menu-classic .sub-menu {
  position: absolute;
  top: 100%;
  left: 1em;
  transform: translate(0px, 1em);
  list-style-type: none;
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -1;
  background-color: #fff;
  border-radius: 4px;
  text-align: left;
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.08);
}
.menu-classic .sub-menu:hover {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  z-index: 50;
}
.menu-classic .sub-menu > li {
  white-space: nowrap;
}
.menu-classic .sub-menu > li a {
  padding: 0.5em 1.25em;
  background-color: transparent;
  color: var(--color-classic-submenu-links);
}
.menu-classic .sub-menu > li a:hover {
  background-color: var(--color-classic-submenu-links-hover-background);
  color: var(--color-classic-submenu-links-hover);
}
.menu-classic .sub-menu > li:first-child a {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.menu-classic .sub-menu > li:last-child a {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.menu-classic .sub-menu ul {
  top: 0;
  left: calc(100% + 1px);
  transform: translate(0.5em, 0);
  opacity: 0;
  visibility: hidden;
}

.ajax-loading .menu-classic {
  pointer-events: auto;
}
.ajax-loading .menu-classic * {
  pointer-events: auto;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  line-height: 2;
}

.menu-overlay {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.menu-overlay .sub-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: var(--header-height);
  padding-bottom: var(--header-height);
  visibility: hidden;
}
.menu-overlay .sub-menu > li {
  display: block;
}
.menu-overlay .sub-menu > li > a {
  display: inline-block;
  padding: 0.4rem 0;
}
.menu-overlay > li {
  display: block;
  overflow: hidden;
}
.menu-overlay > li > a {
  display: inline-flex;
  align-items: center;
  padding: 0 0 1.2rem 0;
  text-transform: uppercase;
  color: var(--color-orange);
}
.menu-overlay > li > a:hover .menu-overlay__counter {
  color: currentColor;
  border-color: currentColor;
}
.menu-overlay li.current-menu-item > a, .menu-overlay li.current-menu-ancestor > a {
  color: var(--color-accent);
}
.menu-overlay li.current-menu-item > a *, .menu-overlay li.current-menu-ancestor > a * {
  color: var(--color-accent);
}

.menu-overlay li a * {
  pointer-events: none;
}

.menu-overlay__heading {
  flex: 0 1 auto;
}

.menu-overlay__counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  flex: 1 0 70px;
  border-radius: 100%;
  border: 1px solid var(--color-border-opacity);
  white-space: nowrap;
  transition: color 0.2s ease, border-color 0.2s ease;
}

.text-start .menu-overlay__counter {
  margin-right: calc(var(--gutter-horizontal) / 2);
  order: -1;
}

.text-end .menu-overlay__counter {
  margin-left: calc(var(--gutter-horizontal) / 2);
}

@media screen and (max-width: 991px) {
  .menu-overlay .sub-menu {
    padding-top: 0;
  }
  .menu-overlay__counter {
    width: 50px;
    height: 50px;
    flex: 1 0 50px;
  }
  .text-start .menu-overlay__counter {
    margin-right: calc(var(--gutter-horizontal));
  }
  .text-end .menu-overlay__counter {
    margin-left: calc(var(--gutter-horizontal));
  }
}
.sub-menu-holder {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.sub-menu-holder .sub-menu {
  width: 100%;
  height: 100%;
  z-index: 50;
}
.sub-menu-holder .sub-menu.current {
  pointer-events: auto;
}

@media screen and (max-width: 991px) {
  .sub-menu-holder .sub-menu {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    max-height: calc(var(--fix-bar-vh, 1vh) * 100);
    padding-bottom: calc(var(--header-height) * 2);
  }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6000;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal__message {
  font-size: 18px;
}

.modal-content__wrapper-button {
  text-align: center;
}
.modal-content__wrapper-button .button {
  min-width: 200px;
}
.modal-content__wrapper-button .button * {
  pointer-events: none;
}

.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 60;
}
.modal__close * {
  pointer-events: none;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-dialog_container {
  max-width: 980px !important;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
  overscroll-behavior: contain;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  outline: 0;
  padding: 3em;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.7;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
  .modal__message {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.persiana {
  height: 4rem;
  overflow: hidden;
  position: relative;
}
.persiana.footer {
  height: 3rem;
}
.persiana.footer .persiana-item {
  font-size: calc(1 * (34 * 1px));
  font-weight: 300;
  color: var(--color-orange);
  letter-spacing: -1px !important;
}
@media screen and (min-width: 360px) {
  .persiana.footer .persiana-item {
    font-size: calc(1 * (34 * 1px + (48 - 34) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .persiana.footer .persiana-item {
    font-size: calc(1 * (48 * 1px));
  }
}
.persiana .persiana-item {
  margin: 0;
  position: absolute;
  top: 0;
  font-size: calc(1 * (40 * 1px));
  font-weight: bold;
}
@media screen and (min-width: 360px) {
  .persiana .persiana-item {
    font-size: calc(1 * (40 * 1px + (56 - 40) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .persiana .persiana-item {
    font-size: calc(1 * (56 * 1px));
  }
}

.parallax {
  display: flex;
  flex-direction: column;
  position: relative;
}

.parallax__link {
  display: inline-block;
  max-width: 100%;
}

.parallax__caption {
  margin-top: 1em;
}

@media screen and (max-width: 991px) {
  .parallax__caption {
    margin-bottom: 0.5em;
  }
}
.portfolio-card {
  display: block;
}

.portfolio-card__wrapper-media {
  position: relative;
  overflow: hidden;
  z-index: 50;
}

.portfolio-card__media-mask {
  position: relative;
  max-width: 66vw;
  width: 1320px;
}

.portfolio-card__link {
  display: block;
  transition: all 0.3s ease;
}
.portfolio-card__link:hover .portfolio-card__overlay, .portfolio-card__link:hover .portfolio-card__wrapper-marquee, .portfolio-card__link:hover .portfolio-card__wrapper-content-top {
  opacity: 1;
  visibility: visible;
}

.portfolio-card__link_static:hover .portfolio-card__overlay_static {
  opacity: 0.6;
}
.portfolio-card__link_static:hover .portfolio-card__wrapper-marquee_static {
  opacity: 1;
  color: var(--color-accent);
}
.portfolio-card__link_static:hover .portfolio-card__wrapper-marquee_static * {
  color: var(--color-accent);
}
.portfolio-card__link_static:hover .portfolio-card__wrapper-marquee_mask {
  opacity: 1;
  color: var(--color-accent-dark-theme);
}
.portfolio-card__link_static:hover .portfolio-card__wrapper-marquee_mask * {
  color: var(--color-accent-dark-theme);
}

.portfolio-card__content {
  display: inline-block;
}

.portfolio-card__wrapper-marquee {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  transition: color 0.3s ease, opacity 0.3s ease;
}

.portfolio-card__wrapper-marquee_static {
  opacity: 0.5;
  visibility: visible;
}

.portfolio-card__wrapper-marquee_mask {
  opacity: 1;
  visibility: visible;
}

.portfolio-card__overlay {
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.portfolio-card__overlay_static {
  opacity: 1;
  visibility: visible;
}

.portfolio-card__wrapper-content-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: calc(1 * (20 * 1px));
  display: flex;
  justify-content: space-between;
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
@media screen and (min-width: 360px) {
  .portfolio-card__wrapper-content-top {
    padding: calc(1 * (20 * 1px + (40 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .portfolio-card__wrapper-content-top {
    padding: calc(1 * (40 * 1px));
  }
}

.portfolio-card__wrapper-content-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: calc(1 * (20 * 1px));
  display: flex;
  justify-content: space-between;
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
@media screen and (min-width: 360px) {
  .portfolio-card__wrapper-content-bottom {
    padding: calc(1 * (20 * 1px + (40 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .portfolio-card__wrapper-content-bottom {
    padding: calc(1 * (40 * 1px));
  }
}

@media screen and (max-width: 1100px) {
  .portfolio-card__media-mask {
    max-width: 100%;
    width: 100%;
  }
  .portfolio-card__wrapper-content-top {
    padding: 20px;
  }
}
.portfolio-item {
  overflow: hidden;
  position: relative;
  display: block;
  z-index: 50;
  --counter-scale-from: 0.5;
  --counter-scale-to: 1;
  --button-hover-background: var(--color-accent);
  --button-hover-border-color: var(--color-accent);
  --button-hover-color: #000;
}
.portfolio-item:hover .portfolio-item__overlay {
  opacity: 0;
}
.portfolio-item:hover .portfolio-item__inner-button:after {
  background-color: var(--button-hover-background);
  transform: scale(var(--counter-scale-to, 1));
}
.portfolio-item:hover .portfolio-item__inner-button_border:after {
  box-shadow: inset 0 0 0 1px;
}
.portfolio-item:hover .portfolio-item__button-label {
  transform: translateY(0);
  opacity: 1;
  color: var(--button-hover-color);
}
.portfolio-item:hover .portfolio-item__circle {
  color: var(--button-hover-color);
  transform: translate(-50%, calc(-50% - 30px));
  opacity: 0;
}
.portfolio-item:hover .portfolio-item__wrapper-title {
  transform: translateY(30px);
  opacity: 0;
}

.portfolio-item_button-bordered {
  --button-hover-background: transparent;
  --button-hover-border-color: var(--color-accent);
  --button-hover-color: var(--color-accent);
}

.portfolio-item__overlay {
  transition: all 0.3s ease;
  z-index: 0;
}

.portfolio-item__wrapper-title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 50;
  transition: all 0.3s ease;
  transform: translateY(0);
}

.portfolio-item__wrapper-button {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.portfolio-item__circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
}

.portfolio-item__button {
  border-color: transparent !important;
}

.portfolio-item__inner-button:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(var(--counter-scale-from, 0.5));
  background-color: transparent;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.portfolio-item__inner-button_border:after {
  box-shadow: inset 0 0 0 2px var(--color-border-opacity);
}

.portfolio-item__button-label {
  position: relative;
  display: inline-block;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.3s ease;
  z-index: 50;
}

.portfolio-item__wrapper-circle {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(1 * (40 * 1px));
  height: calc(1 * (40 * 1px));
  border-radius: 50%;
  border: 1px solid var(--color-border-opacity);
  top: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
  transform: translate(50%, -50%);
  z-index: 50;
  pointer-events: none;
}
@media screen and (min-width: 360px) {
  .portfolio-item__wrapper-circle {
    width: calc(1 * (40 * 1px + (80 - 40) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .portfolio-item__wrapper-circle {
    width: calc(1 * (80 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .portfolio-item__wrapper-circle {
    height: calc(1 * (40 * 1px + (80 - 40) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .portfolio-item__wrapper-circle {
    height: calc(1 * (80 * 1px));
  }
}

@media screen and (max-width: 767px) {
  .portfolio-item__wrapper-circle {
    transform: translate(0%, 0%);
  }
}
.pricing-table {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

.pricing-table_hovered .pricing-table__overlay {
  opacity: 0.7;
}

.pricing-table__outline, .pricing-table__border {
  pointer-events: none;
}

.pricing-table__outline {
  position: absolute;
  top: calc(var(--gutter-horizontal-min) * 1px);
  left: calc(var(--gutter-horizontal-min) * 1px);
  right: calc(var(--gutter-horizontal-min) * 1px);
  bottom: calc(var(--gutter-horizontal-min) * 1px);
}

.pricing-table__border {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.pricing-table__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.pricing-table__overlay {
  z-index: 10;
  transition: opacity 0.3s ease;
}

.pricing-table__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 50;
}

.pricing-table__currency-sign {
  display: inline-block;
  font-size: 40%;
  font-weight: bold;
  margin-top: 0.33em;
  margin-right: 0.15em;
}

.pricing-table__price {
  display: inline-block;
}

.pricing-table__cost {
  display: inline-flex;
  justify-content: center;
}

.pricing-table__property-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 600;
  overflow: hidden;
}

.preloader:not(.preloader_loaded) {
  cursor: progress;
}
.preloader:not(.preloader_loaded) * {
  cursor: progress;
}

.preloader__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.preloader__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.preloader__footer {
  position: relative;
  margin-top: auto;
  width: 100%;
  z-index: 50;
}

.preloader__image {
  display: inline-block;
  height: 66vh;
}
.preloader__image img, .preloader__image video {
  height: 100%;
  max-height: 66vh;
  width: auto;
  transition: none;
}

.preloader__image-mask {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.preloader__wrapper-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: calc(100% - 2 * var(--gutter-horizontal));
  max-height: 100%;
  margin: auto;
  transform: translate(-50%, -50%);
  z-index: 40;
}

.preloader__wrapper-images-outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.preloader__loading {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader__wrapper-counter {
  position: absolute;
  top: 0;
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
  bottom: 0;
  width: calc(1 * (200 * 1px));
  height: calc(1 * (200 * 1px));
  margin: auto;
  aspect-ratio: 1/1;
  border: 1px solid var(--color-border-opacity);
  border-radius: 100em;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 360px) {
  .preloader__wrapper-counter {
    width: calc(1 * (200 * 1px + (600 - 200) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .preloader__wrapper-counter {
    width: calc(1 * (600 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .preloader__wrapper-counter {
    height: calc(1 * (200 * 1px + (600 - 200) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .preloader__wrapper-counter {
    height: calc(1 * (600 * 1px));
  }
}
.preloader__wrapper-counter svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  stroke-width: 2px;
  pointer-events: none;
  stroke-dashoffset: 0.001;
  stroke-dasharray: 0px, 999999px;
}
.preloader__wrapper-counter svg .circle {
  stroke: currentColor;
}

.preloader__heading {
  position: absolute;
  top: 50%;
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
  transform: translateY(-50%);
  z-index: 50;
}

.preloader__counter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.preloader__wrapper-images-inner {
  position: relative;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.preloader__overlay {
  z-index: 0;
}

.preloader__counter-sign {
  display: inline-block;
  margin-top: 0.4em;
}

.preloader__wrapper-counter-end {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.properties__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.properties__list li {
  display: block;
}
.properties__list li:not(:last-child) {
  margin-bottom: 1rem;
}
.properties__list li:not(:first-child) {
  margin-top: 1rem;
}

.properties__name {
  margin-bottom: 1rem;
}

.rotating-button {
  display: inline-flex;
}

.rotating-button__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  border-radius: 50%;
}
.rotating-button__link * {
  pointer-events: none;
}

.rotating-button__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: currentColor;
}

.rotating-button__dot {
  color: var(--color-accent);
  pointer-events: none;
  --size-min: 20;
  --size-max: 25;
  position: relative;
  width: calc(1 * (var(--size-min) * 1px));
  height: calc(1 * (var(--size-min) * 1px));
  box-shadow: 0px 0px 0px 1px var(--color-border-opacity) inset;
  display: inline-flex;
  align-items: center;
  border-radius: 100%;
  vertical-align: middle;
  z-index: 50;
}
@media screen and (min-width: 360px) {
  .rotating-button__dot {
    width: calc(1 * (var(--size-min) * 1px + (var(--size-max) - var(--size-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .rotating-button__dot {
    width: calc(1 * (var(--size-max) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .rotating-button__dot {
    height: calc(1 * (var(--size-min) * 1px + (var(--size-max) - var(--size-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .rotating-button__dot {
    height: calc(1 * (var(--size-max) * 1px));
  }
}
.rotating-button__dot svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  stroke-width: 4px;
  pointer-events: none;
}
.rotating-button__dot svg .circle {
  stroke: currentColor;
}

.rotating-button__label {
  white-space: nowrap;
}

@media screen and (max-width: 991px) {
  .rotating-button__label {
    font-size: 10px;
  }
}
.section {
  position: relative;
}

.section__content, .section__header {
  position: relative;
  z-index: 50;
}

.section__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  z-index: 0;
}

.section__overlay {
  z-index: 10;
  transition: opacity 0.3s ease;
  will-change: opacity;
}

.section_hovered .section__overlay {
  opacity: 0.7;
}

@media screen and (min-width: 992px) {
  .section__lg-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    z-index: 0;
  }
}
.section-fullheight {
  display: flex;
}

.section-fullheight__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  z-index: 50;
}

.section-fullheight__inner_mobile {
  height: calc(var(--fix-bar-vh, 1vh) * 100);
  min-height: 0vh;
}

@media screen and (max-width: 991px) {
  .section-fullheight__inner_mobile-auto {
    height: auto;
    display: block;
    min-height: 0;
  }
  .section-fullheight__inner_mobile-min-auto {
    height: auto;
    display: block;
    min-height: 100vh;
  }
}
.shape-rounded-rectangle {
  overflow: hidden;
  clip-path: inset(0px);
  border-radius: 200em;
}

.shape-oval {
  overflow: hidden;
  clip-path: inset(0px);
  border-radius: 100%;
}

.shape-top-arc {
  overflow: hidden;
  clip-path: inset(0px);
  border-top-left-radius: 200em;
  border-top-right-radius: 200em;
}

.shape-bottom-arc {
  overflow: hidden;
  clip-path: inset(0px);
  border-bottom-left-radius: 200em;
  border-bottom-right-radius: 200em;
}

.slider-arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 60;
}

.slider-arrow__button {
  color: currentColor;
  border: none;
  min-width: 0 !important;
  min-height: 0 !important;
}
.slider-arrow__button[disabled] {
  border-color: transparent;
}
.slider-arrow__button * {
  pointer-events: none;
}
.slider-arrow__button svg {
  width: calc(1 * (20 * 1px));
  height: calc(1 * (20 * 1px));
}
@media screen and (min-width: 360px) {
  .slider-arrow__button svg {
    width: calc(1 * (20 * 1px + (40 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .slider-arrow__button svg {
    width: calc(1 * (40 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .slider-arrow__button svg {
    height: calc(1 * (20 * 1px + (40 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .slider-arrow__button svg {
    height: calc(1 * (40 * 1px));
  }
}

.slider-arrow_absolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.slider-arrow_absolute.slider-arrow_left {
  left: var(--gutter-horizontal);
  transform: translate(-33%, -50%);
}
.slider-arrow_absolute.slider-arrow_right {
  right: var(--gutter-horizontal);
  transform: translate(33%, -50%);
}

.has-cursor-follower .slider-arrow__button {
  border: none !important;
}

.no-cursor-follower .slider-arrow__button {
  border-color: var(--color-border-opacity) !important;
}
.no-cursor-follower .slider-arrow__button:hover {
  border-color: currentColor !important;
}

@media screen and (max-width: 1100px) {
  .slider-arrow__button {
    border: none !important;
  }
  .slider-arrow_mobile-bottom.slider-arrow_absolute {
    top: auto;
    bottom: var(--gutter-vertical);
  }
  .slider-arrow_absolute.slider-arrow_left {
    transform: translate(0, -33%);
  }
  .slider-arrow_absolute.slider-arrow_right {
    transform: translate(0, -33%);
  }
  .no-cursor-follower .slider-arrow__button {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.slider-arrows {
  position: absolute;
  margin: -8px;
}

.slider-arrows_left-bottom {
  left: var(--gutter-horizontal);
  bottom: var(--gutter-vertical);
}

.slider-arrows_right-bottom {
  right: var(--gutter-horizontal);
  bottom: var(--gutter-vertical);
}

.slider-arrows_center-bottom {
  left: var(--gutter-horizontal);
  bottom: var(--gutter-vertical);
  right: var(--gutter-horizontal);
  margin: auto;
  text-align: center;
}

.slider-arrows__arrow {
  margin: 8px;
}

.slider-categories {
  display: inline-flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: var(--gutter-vertical);
  z-index: 50;
  white-space: nowrap;
}

.slider-categories_left {
  left: var(--gutter-horizontal);
  margin-right: auto;
  justify-content: flex-start;
}

.slider-categories_center {
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
  margin: 0 auto;
  justify-content: center;
}

.slider-categories_right {
  right: var(--gutter-horizontal);
  margin-left: auto;
  justify-content: flex-end;
}

.slider-categories__inner {
  position: relative;
  overflow: hidden;
}

.slider-categories__item {
  transition: all 0.6s ease;
  opacity: 1;
}

.slider-categories__item:not([current]) {
  transform: translateY(-100%);
  opacity: 0;
}

.slider-dots {
  display: inline-flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: var(--gutter-vertical);
  z-index: 50;
  margin: -4px;
}

.slider-dots_vertical {
  flex-direction: column;
  height: auto;
  vertical-align: initial;
}

.slider-dots_left, .slider-dots_center-left {
  left: var(--gutter-horizontal);
  margin-right: auto;
  justify-content: flex-start;
}

.slider-dots_center-left {
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
}

.slider-dots_center {
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
  margin: 0 auto;
  justify-content: center;
}

.slider-dots_right, .slider-dots_center-right {
  right: var(--gutter-horizontal);
  margin-left: auto;
}
.slider-dots_right .slider-dots__item, .slider-dots_center-right .slider-dots__item {
  justify-content: flex-end;
}
.slider-dots_right .slider-dots__label, .slider-dots_center-right .slider-dots__label {
  order: -1;
}

.slider-dots_center-right {
  top: 50%;
  transform: translate(50%, -50%);
  bottom: auto;
}

.slider-dots__item {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin: 4px;
  color: var(--color-border-solid);
  transition: color 0.3s ease, opacity 0.3s ease;
}
.slider-dots__item[current] {
  color: var(--color-accent);
}
.slider-dots__item[current] .slider-dots__dot:after {
  background-color: var(--color-accent);
}
.slider-dots__item[current] .slider-dots__label, .slider-dots__item[current] .slider-dots__counter {
  opacity: 1;
}
.slider-dots__item:not([current]):hover {
  color: var(--color-border-solid);
}
.slider-dots__item:not([current]):hover .slider-dots__label, .slider-dots__item:not([current]):hover .slider-dots__counter {
  opacity: 1;
}

.slider-dots__label, .slider-dots__counter {
  display: inline-block;
  pointer-events: none;
  padding: 0 0.75em;
  opacity: 0.6;
  transition: color 0.3s ease, opacity 0.3s ease;
}

.slider-dots__dot {
  pointer-events: none;
  --size-min: 25;
  --size-max: 40;
  position: relative;
  width: calc(1 * (var(--size-min) * 1px));
  height: calc(1 * (var(--size-min) * 1px));
  outline: none;
  display: inline-flex;
  align-items: center;
  border-radius: 100%;
  vertical-align: middle;
  z-index: 50;
}
@media screen and (min-width: 360px) {
  .slider-dots__dot {
    width: calc(1 * (var(--size-min) * 1px + (var(--size-max) - var(--size-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .slider-dots__dot {
    width: calc(1 * (var(--size-max) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .slider-dots__dot {
    height: calc(1 * (var(--size-min) * 1px + (var(--size-max) - var(--size-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .slider-dots__dot {
    height: calc(1 * (var(--size-max) * 1px));
  }
}
.slider-dots__dot svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  stroke-width: 4px;
  pointer-events: none;
}
.slider-dots__dot svg .circle {
  stroke: currentColor;
}
.slider-dots__dot:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: calc(1 * (2 * 1px));
  height: calc(1 * (2 * 1px));
  border-radius: 100%;
  background-color: var(--color-border-solid);
}
@media screen and (min-width: 360px) {
  .slider-dots__dot:after {
    width: calc(1 * (2 * 1px + (4 - 2) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .slider-dots__dot:after {
    width: calc(1 * (4 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .slider-dots__dot:after {
    height: calc(1 * (2 * 1px + (4 - 2) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .slider-dots__dot:after {
    height: calc(1 * (4 * 1px));
  }
}

.slider-dots__dot_number {
  --size-min: 40;
  --size-max: 80;
  justify-content: center;
  flex-wrap: wrap;
}
.slider-dots__dot_number svg {
  stroke-width: 1px;
}
.slider-dots__dot_number:after {
  display: none;
}

.no-cursor-follower .slider-dots__dot {
  box-shadow: 0px 0px 0px 1px var(--color-border-opacity) inset;
  transition: box-shadow 0.3s ease;
}
.no-cursor-follower .slider-dots__item:not([current]):hover .slider-dots__dot {
  box-shadow: 0px 0px 0px 1px var(--color-accent) inset;
}

@media screen and (max-width: 1100px) {
  .slider-dots {
    bottom: var(--gutter-vertical);
  }
  .slider-dots_center-right {
    transform: translate(0%, -50%);
    bottom: auto;
  }
}
.slider-fullpage-backgrounds {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 50;
}
.slider-fullpage-backgrounds.locked .slider-fullpage-backgrounds__section {
  pointer-events: none !important;
}
.slider-fullpage-backgrounds.locked .slider-fullpage-backgrounds__section * {
  pointer-events: none !important;
}

.slider-fullpage-backgrounds[data-arts-os-animation=true] .slider-fullpage-backgrounds__section:first-child {
  opacity: 1;
}
.slider-fullpage-backgrounds[data-arts-os-animation=true] .slider-fullpage-backgrounds__section:first-child .slider-fullpage-backgrounds__wrapper-background {
  opacity: 1;
}
.slider-fullpage-backgrounds[data-arts-os-animation=true] .slider-fullpage-backgrounds__section:first-child .slider-fullpage-backgrounds__wrapper-background *:not(.slider-fullpage-backgrounds__overlay) {
  opacity: 1;
}
.slider-fullpage-backgrounds[data-arts-os-animation=true] .slider-fullpage-backgrounds__section:first-child .slider-fullpage-backgrounds__wrapper-content {
  opacity: 0;
}

.slider-fullpage-backgrounds__wrapper-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  --shape-size: 100;
  --shape-size-multipler: 1;
  --shape-position: calc(82.5% - var(--gutter-horizontal));
}

.slider-fullpage-backgrounds__wrapper-background:not(.js-ajax-transition-clone) {
  z-index: 10 !important;
}

.slider-fullpage-backgrounds__wrapper-background_mask-circle {
  clip-path: circle(max(var(--shape-size) * var(--shape-size-multipler) * 1vw, var(--shape-size) * var(--shape-size-multipler) * 1vh) at var(--shape-position) 50%);
}

.slider-fullpage-backgrounds__wrapper-background-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-origin: center center;
}

.slider-fullpage-backgrounds__header {
  position: relative;
}

.slider-fullpage-backgrounds__wrapper-text {
  display: inline-block;
  max-width: 600px;
}

.slider-fullpage-backgrounds__wrapper-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  z-index: 50;
}

.slider-fullpage-backgrounds__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-left: calc(var(--gutter-horizontal) * 2.5);
  padding-right: calc(var(--gutter-horizontal) * 2.5);
}

.slider-fullpage-backgrounds__section {
  perspective: 1px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.slider-fullpage-backgrounds__section:not([data-arts-fullpage-slider-section-state=current]) {
  pointer-events: none;
}
.slider-fullpage-backgrounds__section:not([data-arts-fullpage-slider-section-state=current]) * {
  pointer-events: none;
}

.slider-fullpage-backgrounds__overlay {
  opacity: 0;
  z-index: 20;
}

.slider-fullpage-backgrounds__col {
  position: relative;
  height: 100%;
}

.slider-fullpage-backgrounds__col_gutters {
  padding-top: calc(var(--header-height) + var(--gutter-vertical));
  padding-right: calc(var(--gutter-horizontal) * 2);
  padding-bottom: var(--gutter-vertical);
  padding-left: calc(var(--gutter-horizontal) * 2);
}

.slider-fullpage-backgrounds__wrapper-button_centered {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.slider-fullpage-backgrounds__wrapper-background_arc {
  width: auto;
  height: 100%;
  aspect-ratio: 1/1.5;
  margin: 0 auto;
}

.slider-fullpage-backgrounds__wrapper-button_arc {
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translate(0, -50%);
  z-index: 100;
}

@media screen and (max-width: 1200px) {
  .slider-fullpage-backgrounds__col_gutters {
    padding-top: var(--header-height);
    padding-right: 0;
    padding-bottom: var(--gutter-vertical);
    padding-left: 0;
  }
  .slider-fullpage-backgrounds__wrapper-button_centered {
    left: 50%;
  }
}
@media screen and (max-width: 1100px) {
  .slider-fullpage-backgrounds__container {
    padding-left: var(--gutter-horizontal);
    padding-right: var(--gutter-horizontal);
  }
}
@media screen and (max-width: 767px) {
  .slider-fullpage-backgrounds__header {
    width: 100%;
  }
  .slider-fullpage-backgrounds__wrapper-background {
    --shape-position: 50%;
    --shape-size-multipler: 1.2;
  }
  .slider-fullpage-backgrounds__wrapper-background_arc {
    width: 100%;
    height: 100%;
    aspect-ratio: initial;
  }
}
.slider-images {
  position: relative;
  width: 100%;
  overflow: hidden;
  --slider-min-height-vh: 50;
  --slider-max-height-vh: 70;
  --slider-height-vh: calc(1 * (var(--slider-min-height-vh) * 1vh));
  height: var(--slider-height-vh);
}
@media screen and (min-width: 360px) {
  .slider-images {
    --slider-height-vh: calc(1 * (var(--slider-min-height-vh) * 1vh + (var(--slider-max-height-vh) - var(--slider-min-height-vh)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .slider-images {
    --slider-height-vh: calc(1 * (var(--slider-max-height-vh) * 1vh));
  }
}

.slider-images__lane {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.slider-images__item {
  height: 100%;
  max-width: calc(100vw - 4 * var(--gutter-horizontal));
}

.slider-testimonials {
  position: relative;
}

.slider-testimonials__container {
  position: relative;
}

.slider-testimonials__sign {
  display: inline-flex;
}
.slider-testimonials__sign svg {
  width: calc(1 * (40 * 1px));
  fill: currentColor;
}
@media screen and (min-width: 360px) {
  .slider-testimonials__sign svg {
    width: calc(1 * (40 * 1px + (80 - 40) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .slider-testimonials__sign svg {
    width: calc(1 * (80 * 1px));
  }
}

.slider-testimonials__dot-image {
  display: inline-block;
  transition: opacity 0.3s ease;
}

.slider-testimonials__dot {
  cursor: pointer;
  --size-min: 60;
  --size-max: 80;
  position: relative;
  width: calc(1 * (var(--size-min) * 1px));
  height: calc(1 * (var(--size-min) * 1px));
  outline: none;
  border-radius: 100%;
  margin: 0.5em;
  background-color: #000;
}
@media screen and (min-width: 360px) {
  .slider-testimonials__dot {
    width: calc(1 * (var(--size-min) * 1px + (var(--size-max) - var(--size-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .slider-testimonials__dot {
    width: calc(1 * (var(--size-max) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .slider-testimonials__dot {
    height: calc(1 * (var(--size-min) * 1px + (var(--size-max) - var(--size-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .slider-testimonials__dot {
    height: calc(1 * (var(--size-max) * 1px));
  }
}
.slider-testimonials__dot svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 100%;
  stroke-width: 4px;
  pointer-events: none;
}
.slider-testimonials__dot svg .circle {
  stroke: currentColor;
}
.slider-testimonials__dot[current] .slider-testimonials__dot-image {
  opacity: 1;
}
.slider-testimonials__dot:not([current]) .slider-testimonials__dot-image {
  opacity: 0.7;
}
.slider-testimonials__dot:not([current]):hover .slider-testimonials__dot-image {
  opacity: 1;
}
.slider-testimonials__dot * {
  pointer-events: none;
}

.slider-testimonials__dots {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.slider-testimonials__arrow {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.slider-testimonials__arrow-prev {
  left: var(--gutter-horizontal);
  right: auto;
  transform: translate(-33%, -50%);
}

.slider-testimonials__arrow-next {
  left: auto;
  right: var(--gutter-horizontal);
  transform: translate(33%, -50%);
}

@media screen and (max-width: 1100px) {
  .slider-testimonials__arrow {
    top: calc(1 * (35 * 1px));
  }
}
@media screen and (max-width: 1100px) and (min-width: 360px) {
  .slider-testimonials__arrow {
    top: calc(1 * (35 * 1px + (60 - 35) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (max-width: 1100px) and (min-width: 1920px) {
  .slider-testimonials__arrow {
    top: calc(1 * (60 * 1px));
  }
}
.soc1al {
  display: block;
  --social-size: 16;
  padding: 0;
  list-style: none;
  margin: calc(var(--social-size) * -0.25px);
}
.soc1al .soc1al__item {
  display: inline-block;
  transition: none;
  margin: calc(var(--social-size) * 0.25px);
}
.soc1al .soc1al__item a {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: calc(var(--social-size) * 2.5px);
  height: calc(var(--social-size) * 2.5px);
  font-size: calc(var(--social-size) * 1px);
  border-radius: 50%;
  transition: 0.3s color ease, 0.3s border-color ease;
}
.soc1al .soc1al__item a:hover {
  border-color: var(--color-accent) !important;
}
.soc1al .soc1al__item a:hover svg g {
  fill: var(--color-accent);
}
.soc1al .soc1al__item svg {
  max-width: calc(var(--social-size) * 1px);
  max-height: calc(var(--social-size) * 1px);
}
.soc1al .soc1al__item svg g {
  transition: all 0.3s ease;
}

.soc1al_large {
  --social-size: 20;
}

.soc1al_xlarge {
  --social-size: 24;
}

.soc1al_small {
  --social-size: 12;
}

.has-cursor-follower .soc1al_borders-opacity .soc1al__item a[data-arts-cursor-follower-target]:hover {
  border-color: transparent !important;
}

.soc1al_borders-opacity .soc1al__item a {
  border: 1px solid var(--color-border-opacity);
}

.no-cursor-follower .soc1al_borders-opacity-no-cursor .soc1al__item a {
  border: 1px solid var(--color-border-opacity);
}

.soc1al_borders-solid .soc1al__item a {
  border: 1px solid var(--color-border-solid);
}

.m-xxsmall {
  margin: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .m-xxsmall {
    margin: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .m-xxsmall {
    margin: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.my-xxsmall {
  margin-top: calc(1 * (var(--distance-min-xxsmall) * 1px));
  margin-bottom: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .my-xxsmall {
    margin-top: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .my-xxsmall {
    margin-top: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .my-xxsmall {
    margin-bottom: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .my-xxsmall {
    margin-bottom: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.mx-xxsmall {
  margin-left: calc(1 * (var(--distance-min-xxsmall) * 1px));
  margin-right: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .mx-xxsmall {
    margin-left: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xxsmall {
    margin-left: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .mx-xxsmall {
    margin-right: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xxsmall {
    margin-right: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.mt-xxsmall {
  margin-top: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .mt-xxsmall {
    margin-top: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-xxsmall {
    margin-top: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.me-xxsmall {
  margin-right: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .me-xxsmall {
    margin-right: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .me-xxsmall {
    margin-right: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.mb-xxsmall {
  margin-bottom: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .mb-xxsmall {
    margin-bottom: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-xxsmall {
    margin-bottom: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.ms-xxsmall {
  margin-left: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .ms-xxsmall {
    margin-left: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ms-xxsmall {
    margin-left: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.mt-minus-xxsmall {
  margin-top: calc(-1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .mt-minus-xxsmall {
    margin-top: calc(-1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-xxsmall {
    margin-top: calc(-1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.me-minus-xxsmall {
  margin-right: calc(-1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .me-minus-xxsmall {
    margin-right: calc(-1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .me-minus-xxsmall {
    margin-right: calc(-1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.mb-minus-xxsmall {
  margin-bottom: calc(-1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .mb-minus-xxsmall {
    margin-bottom: calc(-1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-xxsmall {
    margin-bottom: calc(-1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.ms-minus-xxsmall {
  margin-left: calc(-1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .ms-minus-xxsmall {
    margin-left: calc(-1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ms-minus-xxsmall {
    margin-left: calc(-1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.m-xsmall {
  margin: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .m-xsmall {
    margin: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .m-xsmall {
    margin: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.my-xsmall {
  margin-top: calc(1 * (var(--distance-min-xsmall) * 1px));
  margin-bottom: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .my-xsmall {
    margin-top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .my-xsmall {
    margin-top: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .my-xsmall {
    margin-bottom: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .my-xsmall {
    margin-bottom: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.mx-xsmall {
  margin-left: calc(1 * (var(--distance-min-xsmall) * 1px));
  margin-right: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .mx-xsmall {
    margin-left: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xsmall {
    margin-left: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .mx-xsmall {
    margin-right: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xsmall {
    margin-right: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.mt-xsmall {
  margin-top: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .mt-xsmall {
    margin-top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-xsmall {
    margin-top: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.me-xsmall {
  margin-right: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .me-xsmall {
    margin-right: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .me-xsmall {
    margin-right: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.mb-xsmall {
  margin-bottom: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .mb-xsmall {
    margin-bottom: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-xsmall {
    margin-bottom: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.ms-xsmall {
  margin-left: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .ms-xsmall {
    margin-left: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ms-xsmall {
    margin-left: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.mt-minus-xsmall {
  margin-top: calc(-1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .mt-minus-xsmall {
    margin-top: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-xsmall {
    margin-top: calc(-1 * (var(--distance-max-xsmall) * 1px));
  }
}

.me-minus-xsmall {
  margin-right: calc(-1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .me-minus-xsmall {
    margin-right: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .me-minus-xsmall {
    margin-right: calc(-1 * (var(--distance-max-xsmall) * 1px));
  }
}

.mb-minus-xsmall {
  margin-bottom: calc(-1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .mb-minus-xsmall {
    margin-bottom: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-xsmall {
    margin-bottom: calc(-1 * (var(--distance-max-xsmall) * 1px));
  }
}

.ms-minus-xsmall {
  margin-left: calc(-1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .ms-minus-xsmall {
    margin-left: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ms-minus-xsmall {
    margin-left: calc(-1 * (var(--distance-max-xsmall) * 1px));
  }
}

.m-small {
  margin: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .m-small {
    margin: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .m-small {
    margin: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.my-small {
  margin-top: calc(1 * (var(--distance-min-small) * 1px));
  margin-bottom: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .my-small {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .my-small {
    margin-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .my-small {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .my-small {
    margin-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.mx-small {
  margin-left: calc(1 * (var(--distance-min-small) * 1px));
  margin-right: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .mx-small {
    margin-left: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-small {
    margin-left: calc(1 * (var(--distance-max-small) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .mx-small {
    margin-right: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-small {
    margin-right: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.mt-small {
  margin-top: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .mt-small {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-small {
    margin-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.me-small {
  margin-right: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .me-small {
    margin-right: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .me-small {
    margin-right: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.mb-small {
  margin-bottom: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .mb-small {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-small {
    margin-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.ms-small {
  margin-left: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .ms-small {
    margin-left: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ms-small {
    margin-left: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.mt-minus-small {
  margin-top: calc(-1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .mt-minus-small {
    margin-top: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-small {
    margin-top: calc(-1 * (var(--distance-max-small) * 1px));
  }
}

.me-minus-small {
  margin-right: calc(-1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .me-minus-small {
    margin-right: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .me-minus-small {
    margin-right: calc(-1 * (var(--distance-max-small) * 1px));
  }
}

.mb-minus-small {
  margin-bottom: calc(-1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .mb-minus-small {
    margin-bottom: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-small {
    margin-bottom: calc(-1 * (var(--distance-max-small) * 1px));
  }
}

.ms-minus-small {
  margin-left: calc(-1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .ms-minus-small {
    margin-left: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ms-minus-small {
    margin-left: calc(-1 * (var(--distance-max-small) * 1px));
  }
}

.m-medium {
  margin: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .m-medium {
    margin: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .m-medium {
    margin: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.my-medium {
  margin-top: calc(1 * (var(--distance-min-medium) * 1px));
  margin-bottom: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .my-medium {
    margin-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .my-medium {
    margin-top: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .my-medium {
    margin-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .my-medium {
    margin-bottom: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.mx-medium {
  margin-left: calc(1 * (var(--distance-min-medium) * 1px));
  margin-right: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .mx-medium {
    margin-left: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-medium {
    margin-left: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .mx-medium {
    margin-right: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-medium {
    margin-right: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.mt-medium {
  margin-top: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .mt-medium {
    margin-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-medium {
    margin-top: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.me-medium {
  margin-right: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .me-medium {
    margin-right: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .me-medium {
    margin-right: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.mb-medium {
  margin-bottom: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .mb-medium {
    margin-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-medium {
    margin-bottom: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.ms-medium {
  margin-left: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .ms-medium {
    margin-left: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ms-medium {
    margin-left: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.mt-minus-medium {
  margin-top: calc(-1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .mt-minus-medium {
    margin-top: calc(-1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-medium {
    margin-top: calc(-1 * (var(--distance-max-medium) * 1px));
  }
}

.me-minus-medium {
  margin-right: calc(-1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .me-minus-medium {
    margin-right: calc(-1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .me-minus-medium {
    margin-right: calc(-1 * (var(--distance-max-medium) * 1px));
  }
}

.mb-minus-medium {
  margin-bottom: calc(-1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .mb-minus-medium {
    margin-bottom: calc(-1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-medium {
    margin-bottom: calc(-1 * (var(--distance-max-medium) * 1px));
  }
}

.ms-minus-medium {
  margin-left: calc(-1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .ms-minus-medium {
    margin-left: calc(-1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ms-minus-medium {
    margin-left: calc(-1 * (var(--distance-max-medium) * 1px));
  }
}

.m-large {
  margin: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .m-large {
    margin: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .m-large {
    margin: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.my-large {
  margin-top: calc(1 * (var(--distance-min-large) * 1px));
  margin-bottom: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .my-large {
    margin-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .my-large {
    margin-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .my-large {
    margin-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .my-large {
    margin-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.mx-large {
  margin-left: calc(1 * (var(--distance-min-large) * 1px));
  margin-right: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .mx-large {
    margin-left: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-large {
    margin-left: calc(1 * (var(--distance-max-large) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .mx-large {
    margin-right: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-large {
    margin-right: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.mt-large {
  margin-top: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .mt-large {
    margin-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-large {
    margin-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.me-large {
  margin-right: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .me-large {
    margin-right: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .me-large {
    margin-right: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.mb-large {
  margin-bottom: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .mb-large {
    margin-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-large {
    margin-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.ms-large {
  margin-left: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .ms-large {
    margin-left: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ms-large {
    margin-left: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.mt-minus-large {
  margin-top: calc(-1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .mt-minus-large {
    margin-top: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-large {
    margin-top: calc(-1 * (var(--distance-max-large) * 1px));
  }
}

.me-minus-large {
  margin-right: calc(-1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .me-minus-large {
    margin-right: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .me-minus-large {
    margin-right: calc(-1 * (var(--distance-max-large) * 1px));
  }
}

.mb-minus-large {
  margin-bottom: calc(-1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .mb-minus-large {
    margin-bottom: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-large {
    margin-bottom: calc(-1 * (var(--distance-max-large) * 1px));
  }
}

.ms-minus-large {
  margin-left: calc(-1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .ms-minus-large {
    margin-left: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ms-minus-large {
    margin-left: calc(-1 * (var(--distance-max-large) * 1px));
  }
}

.m-xlarge {
  margin: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .m-xlarge {
    margin: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .m-xlarge {
    margin: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.my-xlarge {
  margin-top: calc(1 * (var(--distance-min-xlarge) * 1px));
  margin-bottom: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .my-xlarge {
    margin-top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .my-xlarge {
    margin-top: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .my-xlarge {
    margin-bottom: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .my-xlarge {
    margin-bottom: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.mx-xlarge {
  margin-left: calc(1 * (var(--distance-min-xlarge) * 1px));
  margin-right: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .mx-xlarge {
    margin-left: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xlarge {
    margin-left: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .mx-xlarge {
    margin-right: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xlarge {
    margin-right: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.mt-xlarge {
  margin-top: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .mt-xlarge {
    margin-top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-xlarge {
    margin-top: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.me-xlarge {
  margin-right: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .me-xlarge {
    margin-right: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .me-xlarge {
    margin-right: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.mb-xlarge {
  margin-bottom: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .mb-xlarge {
    margin-bottom: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-xlarge {
    margin-bottom: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.ms-xlarge {
  margin-left: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .ms-xlarge {
    margin-left: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ms-xlarge {
    margin-left: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.mt-minus-xlarge {
  margin-top: calc(-1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .mt-minus-xlarge {
    margin-top: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-xlarge {
    margin-top: calc(-1 * (var(--distance-max-xlarge) * 1px));
  }
}

.me-minus-xlarge {
  margin-right: calc(-1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .me-minus-xlarge {
    margin-right: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .me-minus-xlarge {
    margin-right: calc(-1 * (var(--distance-max-xlarge) * 1px));
  }
}

.mb-minus-xlarge {
  margin-bottom: calc(-1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .mb-minus-xlarge {
    margin-bottom: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-xlarge {
    margin-bottom: calc(-1 * (var(--distance-max-xlarge) * 1px));
  }
}

.ms-minus-xlarge {
  margin-left: calc(-1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .ms-minus-xlarge {
    margin-left: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ms-minus-xlarge {
    margin-left: calc(-1 * (var(--distance-max-xlarge) * 1px));
  }
}

.p-xxsmall {
  padding: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .p-xxsmall {
    padding: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .p-xxsmall {
    padding: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.py-xxsmall {
  padding-top: calc(1 * (var(--distance-min-xxsmall) * 1px));
  padding-bottom: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .py-xxsmall {
    padding-top: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .py-xxsmall {
    padding-top: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .py-xxsmall {
    padding-bottom: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .py-xxsmall {
    padding-bottom: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.px-xxsmall {
  padding-left: calc(1 * (var(--distance-min-xxsmall) * 1px));
  padding-right: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .px-xxsmall {
    padding-left: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-xxsmall {
    padding-left: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .px-xxsmall {
    padding-right: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-xxsmall {
    padding-right: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.pt-xxsmall {
  padding-top: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .pt-xxsmall {
    padding-top: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-xxsmall {
    padding-top: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.pe-xxsmall {
  padding-right: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .pe-xxsmall {
    padding-right: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pe-xxsmall {
    padding-right: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.pb-xxsmall {
  padding-bottom: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .pb-xxsmall {
    padding-bottom: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-xxsmall {
    padding-bottom: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.ps-xxsmall {
  padding-left: calc(1 * (var(--distance-min-xxsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .ps-xxsmall {
    padding-left: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ps-xxsmall {
    padding-left: calc(1 * (var(--distance-max-xxsmall) * 1px));
  }
}

.p-xsmall {
  padding: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .p-xsmall {
    padding: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .p-xsmall {
    padding: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.py-xsmall {
  padding-top: calc(1 * (var(--distance-min-xsmall) * 1px));
  padding-bottom: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .py-xsmall {
    padding-top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .py-xsmall {
    padding-top: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .py-xsmall {
    padding-bottom: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .py-xsmall {
    padding-bottom: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.px-xsmall {
  padding-left: calc(1 * (var(--distance-min-xsmall) * 1px));
  padding-right: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .px-xsmall {
    padding-left: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-xsmall {
    padding-left: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .px-xsmall {
    padding-right: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-xsmall {
    padding-right: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.pt-xsmall {
  padding-top: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .pt-xsmall {
    padding-top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-xsmall {
    padding-top: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.pe-xsmall {
  padding-right: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .pe-xsmall {
    padding-right: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pe-xsmall {
    padding-right: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.pb-xsmall {
  padding-bottom: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .pb-xsmall {
    padding-bottom: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-xsmall {
    padding-bottom: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.ps-xsmall {
  padding-left: calc(1 * (var(--distance-min-xsmall) * 1px));
}
@media screen and (min-width: 360px) {
  .ps-xsmall {
    padding-left: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ps-xsmall {
    padding-left: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.p-small {
  padding: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .p-small {
    padding: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .p-small {
    padding: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.py-small {
  padding-top: calc(1 * (var(--distance-min-small) * 1px));
  padding-bottom: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .py-small {
    padding-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .py-small {
    padding-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .py-small {
    padding-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .py-small {
    padding-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.px-small {
  padding-left: calc(1 * (var(--distance-min-small) * 1px));
  padding-right: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .px-small {
    padding-left: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-small {
    padding-left: calc(1 * (var(--distance-max-small) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .px-small {
    padding-right: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-small {
    padding-right: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.pt-small {
  padding-top: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .pt-small {
    padding-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-small {
    padding-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.pe-small {
  padding-right: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .pe-small {
    padding-right: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pe-small {
    padding-right: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.pb-small {
  padding-bottom: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .pb-small {
    padding-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-small {
    padding-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.ps-small {
  padding-left: calc(1 * (var(--distance-min-small) * 1px));
}
@media screen and (min-width: 360px) {
  .ps-small {
    padding-left: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ps-small {
    padding-left: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.p-medium {
  padding: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .p-medium {
    padding: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .p-medium {
    padding: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.py-medium {
  padding-top: calc(1 * (var(--distance-min-medium) * 1px));
  padding-bottom: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .py-medium {
    padding-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .py-medium {
    padding-top: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .py-medium {
    padding-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .py-medium {
    padding-bottom: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.px-medium {
  padding-left: calc(1 * (var(--distance-min-medium) * 1px));
  padding-right: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .px-medium {
    padding-left: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-medium {
    padding-left: calc(1 * (var(--distance-max-medium) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .px-medium {
    padding-right: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-medium {
    padding-right: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.pt-medium {
  padding-top: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .pt-medium {
    padding-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-medium {
    padding-top: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.pe-medium {
  padding-right: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .pe-medium {
    padding-right: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pe-medium {
    padding-right: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.pb-medium {
  padding-bottom: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .pb-medium {
    padding-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-medium {
    padding-bottom: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.ps-medium {
  padding-left: calc(1 * (var(--distance-min-medium) * 1px));
}
@media screen and (min-width: 360px) {
  .ps-medium {
    padding-left: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ps-medium {
    padding-left: calc(1 * (var(--distance-max-medium) * 1px));
  }
}

.p-large {
  padding: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .p-large {
    padding: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .p-large {
    padding: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.py-large {
  padding-top: calc(1 * (var(--distance-min-large) * 1px));
  padding-bottom: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .py-large {
    padding-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .py-large {
    padding-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .py-large {
    padding-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .py-large {
    padding-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.px-large {
  padding-left: calc(1 * (var(--distance-min-large) * 1px));
  padding-right: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .px-large {
    padding-left: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-large {
    padding-left: calc(1 * (var(--distance-max-large) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .px-large {
    padding-right: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-large {
    padding-right: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.pt-large {
  padding-top: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .pt-large {
    padding-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-large {
    padding-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.pe-large {
  padding-right: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .pe-large {
    padding-right: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pe-large {
    padding-right: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.pb-large {
  padding-bottom: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .pb-large {
    padding-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-large {
    padding-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.ps-large {
  padding-left: calc(1 * (var(--distance-min-large) * 1px));
}
@media screen and (min-width: 360px) {
  .ps-large {
    padding-left: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ps-large {
    padding-left: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.p-xlarge {
  padding: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .p-xlarge {
    padding: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .p-xlarge {
    padding: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.py-xlarge {
  padding-top: calc(1 * (var(--distance-min-xlarge) * 1px));
  padding-bottom: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .py-xlarge {
    padding-top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .py-xlarge {
    padding-top: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .py-xlarge {
    padding-bottom: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .py-xlarge {
    padding-bottom: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.px-xlarge {
  padding-left: calc(1 * (var(--distance-min-xlarge) * 1px));
  padding-right: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .px-xlarge {
    padding-left: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-xlarge {
    padding-left: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .px-xlarge {
    padding-right: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .px-xlarge {
    padding-right: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.pt-xlarge {
  padding-top: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .pt-xlarge {
    padding-top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pt-xlarge {
    padding-top: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.pe-xlarge {
  padding-right: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .pe-xlarge {
    padding-right: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pe-xlarge {
    padding-right: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.pb-xlarge {
  padding-bottom: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .pb-xlarge {
    padding-bottom: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pb-xlarge {
    padding-bottom: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.ps-xlarge {
  padding-left: calc(1 * (var(--distance-min-xlarge) * 1px));
}
@media screen and (min-width: 360px) {
  .ps-xlarge {
    padding-left: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .ps-xlarge {
    padding-left: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.m-gutters {
  margin-top: var(--gutter-vertical);
  margin-bottom: var(--gutter-vertical);
  margin-left: var(--gutter-horizontal);
  margin-right: var(--gutter-horizontal);
}

.m-min-gutters {
  margin-top: calc(var(--gutter-vertical-min) * 1px);
  margin-bottom: calc(var(--gutter-vertical-min) * 1px);
  margin-left: calc(var(--gutter-horizontal-min) * 1px);
  margin-right: calc(var(--gutter-horizontal-min) * 1px);
}

.mx-gutters {
  margin-left: var(--gutter-horizontal);
  margin-right: var(--gutter-horizontal);
}

.mx-min-gutters {
  margin-left: calc(var(--gutter-horizontal-min) * 1px);
  margin-right: calc(var(--gutter-horizontal-min) * 1px);
}

.me-gutters {
  margin-right: var(--gutter-horizontal);
}

.ms-gutters {
  margin-left: var(--gutter-horizontal);
}

.my-gutters {
  margin-top: var(--gutter-vertical);
  margin-bottom: var(--gutter-vertical);
}

.my-min-gutters {
  margin-top: calc(var(--gutter-vertical-min) * 1px);
  margin-bottom: calc(var(--gutter-vertical-min) * 1px);
}

.mt-gutters {
  margin-top: var(--gutter-vertical);
}

.mb-gutters {
  margin-bottom: var(--gutter-vertical);
}

.my-gutters-horizontal {
  margin-top: var(--gutter-horizontal);
  margin-bottom: var(--gutter-horizontal);
}

.my-min-gutters-horizontal {
  margin-top: calc(var(--gutter-horizontal-min) * 1px);
  margin-bottom: calc(var(--gutter-horizontal-min) * 1px);
}

.mt-gutter-horizontal {
  margin-top: var(--gutter-horizontal);
}

.mt-min-gutter-horizontal {
  margin-top: calc(var(--gutter-horizontal-min) * 1px);
}

.mb-gutter-horizontal {
  margin-bottom: var(--gutter-horizontal);
}

.mb-min-gutter-horizontal {
  margin-bottom: calc(var(--gutter-horizontal-min) * 1px);
}

.mx-gutters-horizontal {
  margin-left: var(--gutter-horizontal);
  margin-right: var(--gutter-horizontal);
}

.mx-min-gutters-horizontal {
  margin-left: calc(var(--gutter-horizontal-min) * 1px);
  margin-right: calc(var(--gutter-horizontal-min) * 1px);
}

.me-gutter-horizontal {
  margin-right: var(--gutter-horizontal);
}

.me-min-gutter-horizontal {
  margin-right: calc(var(--gutter-horizontal-min) * 1px);
}

.ms-gutter-horizontal {
  margin-left: var(--gutter-horizontal);
}

.ms-min-gutter-horizontal {
  margin-left: calc(var(--gutter-horizontal-min) * 1px);
}

.my-gutters-vertical {
  margin-top: var(--gutter-vertical);
  margin-bottom: var(--gutter-vertical);
}

.my-min-gutters-vertical {
  margin-top: calc(var(--gutter-vertical-min) * 1px);
  margin-bottom: calc(var(--gutter-vertical-min) * 1px);
}

.mt-gutter-vertical {
  margin-top: var(--gutter-vertical);
}

.mt-min-gutter-vertical {
  margin-top: calc(var(--gutter-vertical-min) * 1px);
}

.mb-gutter-vertical {
  margin-bottom: var(--gutter-vertical);
}

.mb-min-gutter-vertical {
  margin-bottom: calc(var(--gutter-vertical-min) * 1px);
}

.mx-gutters-vertical {
  margin-left: var(--gutter-vertical);
  margin-right: var(--gutter-vertical);
}

.mx-min-gutters-vertical {
  margin-left: calc(var(--gutter-vertical-min) * 1px);
  margin-right: calc(var(--gutter-vertical-min) * 1px);
}

.me-gutter-vertical {
  margin-right: var(--gutter-vertical);
}

.me-min-gutter-vertical {
  margin-right: calc(var(--gutter-vertical-min) * 1px);
}

.ms-gutter-vertical {
  margin-left: var(--gutter-vertical);
}

.ms-min-gutter-vertical {
  margin-left: calc(var(--gutter-vertical-min) * 1px);
}

.p-gutters {
  padding-top: var(--gutter-vertical);
  padding-bottom: var(--gutter-vertical);
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
}

.p-min-gutters {
  padding-top: calc(var(--gutter-vertical-min) * 1px);
  padding-bottom: calc(var(--gutter-vertical-min) * 1px);
  padding-left: calc(var(--gutter-horizontal-min) * 1px);
  padding-right: calc(var(--gutter-horizontal-min) * 1px);
}

.px-gutters {
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
}

.px-min-gutters {
  padding-left: calc(var(--gutter-horizontal-min) * 1px);
  padding-right: calc(var(--gutter-horizontal-min) * 1px);
}

.pe-gutters {
  padding-right: var(--gutter-horizontal);
}

.ps-gutters {
  padding-left: var(--gutter-horizontal);
}

.py-gutters {
  padding-top: var(--gutter-vertical);
  padding-bottom: var(--gutter-vertical);
}

.py-min-gutters {
  padding-top: calc(var(--gutter-vertical-min) * 1px);
  padding-bottom: calc(var(--gutter-vertical-min) * 1px);
}

.pt-gutters {
  padding-top: var(--gutter-vertical);
}

.pb-gutters {
  padding-bottom: var(--gutter-vertical);
}

.py-gutters-horizontal {
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
}

.py-min-gutters-horizontal {
  padding-top: calc(var(--gutter-horizontal-min) * 1px);
  padding-bottom: calc(var(--gutter-horizontal-min) * 1px);
}

.pt-gutter-horizontal {
  padding-top: var(--gutter-horizontal);
}

.pt-min-gutter-horizontal {
  padding-top: calc(var(--gutter-horizontal-min) * 1px);
}

.pb-gutter-horizontal {
  padding-bottom: var(--gutter-horizontal);
}

.pb-min-gutter-horizontal {
  padding-bottom: calc(var(--gutter-horizontal-min) * 1px);
}

.px-gutters-horizontal {
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
}

.px-min-gutters-horizontal {
  padding-left: calc(var(--gutter-horizontal-min) * 1px);
  padding-right: calc(var(--gutter-horizontal-min) * 1px);
}

.pe-gutter-horizontal {
  padding-right: var(--gutter-horizontal);
}

.pe-min-gutter-horizontal {
  padding-right: calc(var(--gutter-horizontal-min) * 1px);
}

.ps-gutter-horizontal {
  padding-left: var(--gutter-horizontal);
}

.ps-min-gutter-horizontal {
  padding-left: calc(var(--gutter-horizontal-min) * 1px);
}

.py-gutters-vertical {
  padding-top: var(--gutter-vertical);
  padding-bottom: var(--gutter-vertical);
}

.py-min-gutters-vertical {
  padding-top: calc(var(--gutter-vertical-min) * 1px);
  padding-bottom: calc(var(--gutter-vertical-min) * 1px);
}

.pt-gutter-vertical {
  padding-top: var(--gutter-vertical);
}

.pt-min-gutter-vertical {
  padding-top: calc(var(--gutter-vertical-min) * 1px);
}

.pb-gutter-vertical {
  padding-bottom: var(--gutter-vertical);
}

.pb-min-gutter-vertical {
  padding-bottom: calc(var(--gutter-vertical-min) * 1px);
}

.px-gutters-vertical {
  padding-left: var(--gutter-vertical);
  padding-right: var(--gutter-vertical);
}

.px-min-gutters-vertical {
  padding-left: calc(var(--gutter-vertical-min) * 1px);
  padding-right: calc(var(--gutter-vertical-min) * 1px);
}

.pe-gutter-vertical {
  padding-right: var(--gutter-vertical);
}

.pe-min-gutter-vertical {
  padding-right: calc(var(--gutter-vertical-min) * 1px);
}

.ps-gutter-vertical {
  padding-left: var(--gutter-vertical);
}

.ps-min-gutter-vertical {
  padding-left: calc(var(--gutter-vertical-min) * 1px);
}

.py-half-gutters {
  padding-top: max(var(--gutter-vertical) / 2, var(--gutter-vertical-min) * 1px);
  padding-bottom: max(var(--gutter-vertical) / 2, var(--gutter-vertical-min) * 1px);
}

.px-half-gutters {
  padding-left: max(var(--gutter-horizontal) / 2, var(--gutter-horizontal-min) * 1px);
  padding-right: max(var(--gutter-horizontal) / 2, var(--gutter-horizontal-min) * 1px);
}

.ps-half-gutters {
  padding-left: max(var(--gutter-horizontal) / 2, var(--gutter-horizontal-min) * 1px);
}

.pe-half-gutters {
  padding-right: max(var(--gutter-horizontal) / 2, var(--gutter-horizontal-min) * 1px);
}

.p-gutters-horizontal {
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
}

.px-gutters-horizontal {
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
}

.ps-gutter-horizontal {
  padding-left: var(--gutter-horizontal);
}

.pe-gutter-horizontal {
  padding-right: var(--gutter-horizontal);
}

.py-gutters-horizontal {
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
}

.pt-gutter-horizontal {
  padding-top: var(--gutter-horizontal);
}

.pb-gutter-horizontal {
  padding-bottom: var(--gutter-horizontal);
}

.m-gutters-horizontal {
  margin-top: var(--gutter-horizontal);
  margin-bottom: var(--gutter-horizontal);
  margin-left: var(--gutter-horizontal);
  margin-right: var(--gutter-horizontal);
}

.mx-gutters-horizontal {
  margin-left: var(--gutter-horizontal);
  margin-right: var(--gutter-horizontal);
}

.ms-gutter-horizontal {
  margin-left: var(--gutter-horizontal);
}

.me-gutter-horizontal {
  margin-right: var(--gutter-horizontal);
}

.my-gutters-horizontal {
  margin-top: var(--gutter-horizontal);
  margin-bottom: var(--gutter-horizontal);
}

.mt-gutter-horizontal {
  margin-top: var(--gutter-horizontal);
}

.mb-gutter-horizontal {
  margin-bottom: var(--gutter-horizontal);
}

.p-gutters-vertical {
  padding-top: var(--gutter-vertical);
  padding-bottom: var(--gutter-vertical);
  padding-left: var(--gutter-vertical);
  padding-right: var(--gutter-vertical);
}

.px-gutters-vertical {
  padding-left: var(--gutter-vertical);
  padding-right: var(--gutter-vertical);
}

.ps-gutter-vertical {
  padding-left: var(--gutter-vertical);
}

.pe-gutter-vertical {
  padding-right: var(--gutter-vertical);
}

.py-gutters-vertical {
  padding-top: var(--gutter-vertical);
  padding-bottom: var(--gutter-vertical);
}

.pt-gutter-vertical {
  padding-top: var(--gutter-vertical);
}

.pb-gutter-vertical {
  padding-bottom: var(--gutter-vertical);
}

.m-gutters-vertical {
  margin-top: var(--gutter-vertical);
  margin-bottom: var(--gutter-vertical);
  margin-left: var(--gutter-vertical);
  margin-right: var(--gutter-vertical);
}

.mx-gutters-vertical {
  margin-left: var(--gutter-vertical);
  margin-right: var(--gutter-vertical);
}

.ms-gutter-vertical {
  margin-left: var(--gutter-vertical);
}

.me-gutter-vertical {
  margin-right: var(--gutter-vertical);
}

.my-gutters-vertical {
  margin-top: var(--gutter-vertical);
  margin-bottom: var(--gutter-vertical);
}

.mt-gutter-vertical {
  margin-top: var(--gutter-vertical);
}

.mb-gutter-vertical {
  margin-bottom: var(--gutter-vertical);
}

.spinner {
  position: fixed;
  left: var(--gutter-horizontal);
  bottom: var(--gutter-vertical);
  width: 30px;
  height: 30px;
  z-index: 10000;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  opacity: 0;
  visibility: hidden;
  color: var(--color-accent-light-theme);
}
.spinner circle {
  stroke: currentColor;
  stroke-width: 2px;
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -150;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

.has-cursor-follower .spinner {
  display: none !important;
}

.spinner[data-arts-color-theme=dark], [data-arts-color-theme=dark] ~ .spinner {
  color: var(--color-accent-dark-theme);
}

.split-counter {
  display: inline-flex;
  align-items: flex-end;
}

.split-counter__lane {
  position: relative;
  overflow: hidden;
  display: inline-block;
  line-height: 1;
}
.split-counter__lane:nth-child(1) span {
  right: 0;
}
.split-counter__lane:nth-child(2) span {
  left: 0;
}
.split-counter__lane:nth-child(3) span {
  left: 0;
}

.js-arts-split-text__wrapper-line {
  display: block;
  overflow: hidden;
}

.js-arts-split-text__wrapper-char {
  display: inline-flex;
  overflow: hidden;
}

.js-arts-split-text__wrapper-word {
  display: inline-flex;
  overflow: hidden;
}

/* SWIPPER */
.swiper-pagination, .swiper-scrollbar-horizontal {
  position: relative !important;
  margin-top: calc(1 * (40 * 1px));
}
@media screen and (min-width: 360px) {
  .swiper-pagination, .swiper-scrollbar-horizontal {
    margin-top: calc(1 * (40 * 1px + (80 - 40) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .swiper-pagination, .swiper-scrollbar-horizontal {
    margin-top: calc(1 * (80 * 1px));
  }
}

.swiper-button-next, .swiper-button-prev {
  width: calc(1 * (40 * 1px));
}
@media screen and (min-width: 360px) {
  .swiper-button-next, .swiper-button-prev {
    width: calc(1 * (40 * 1px + (50 - 40) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .swiper-button-next, .swiper-button-prev {
    width: calc(1 * (50 * 1px));
  }
}

.swipperGallery .swiper-slide .lazy-wrapper {
  max-width: 900px;
  margin: 0 auto;
}

.swipperBeneficios p {
  opacity: 0;
  transition: all 0.3s ease;
}
.swipperBeneficios h2 {
  color: rgba(0, 52, 121, 0.5);
  transition: all 0.3s ease;
}
.swipperBeneficios .swiper-slide-active h2 {
  color: var(--color-green);
  margin-bottom: 30px;
}
.swipperBeneficios .swiper-slide-active p {
  opacity: 1;
}

.swipperTimeline {
  overflow: visible !important;
}
.swipperTimeline .swiper-slide:first-child .title::after {
  width: 85%;
  left: 15%;
}
.swipperTimeline .swiper-slide:last-child .title::after {
  width: 15%;
}
.swipperTimeline .title {
  margin-bottom: 50px;
}
.swipperTimeline .title::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: var(--color-green);
  transform: translateY(-6px) translateX(2px);
}
.swipperTimeline .title > div {
  left: 15%;
  position: relative;
  height: 130px;
}
.swipperTimeline .title > div .xxl {
  padding-right: 20px;
}
.swipperTimeline .title .punto {
  width: 12px;
  height: 12px;
  background: var(--color-green);
  position: relative;
  border-radius: 50%;
  left: 15%;
}
.swipperTimeline .hover-zoom .shape-rounded-rectangle::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  border: solid var(--color-green);
  border-width: calc(1 * (6 * 1px));
  z-index: 999;
  border-radius: 200em;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
@media screen and (min-width: 360px) {
  .swipperTimeline .hover-zoom .shape-rounded-rectangle::after {
    border-width: calc(1 * (6 * 1px + (10 - 6) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .swipperTimeline .hover-zoom .shape-rounded-rectangle::after {
    border-width: calc(1 * (10 * 1px));
  }
}
.swipperTimeline .hover-zoom .paragraph {
  transform: translateX(-40px);
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  padding-left: 20px;
  padding-left: calc(1 * (15 * 1px));
  padding-right: calc(1 * (40 * 1px));
  opacity: 0;
  visibility: hidden;
}
@media screen and (min-width: 360px) {
  .swipperTimeline .hover-zoom .paragraph {
    padding-left: calc(1 * (15 * 1px + (20 - 15) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .swipperTimeline .hover-zoom .paragraph {
    padding-left: calc(1 * (20 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .swipperTimeline .hover-zoom .paragraph {
    padding-right: calc(1 * (40 * 1px + (120 - 40) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .swipperTimeline .hover-zoom .paragraph {
    padding-right: calc(1 * (120 * 1px));
  }
}
.swipperTimeline .hover-zoom .paragraph p {
  margin-top: 0px;
}
@media screen and (max-width: 576px) {
  .swipperTimeline .hover-zoom .paragraph {
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
  }
}
.swipperTimeline .hover-zoom:hover .shape-rounded-rectangle::after {
  opacity: 1;
  visibility: visible;
}
.swipperTimeline .hover-zoom:hover .paragraph {
  transform: translateX(0px);
  opacity: 1;
  visibility: visible;
}

.swipperRecipe {
  padding-right: calc(1 * (15 * 1px));
  padding-left: calc(1 * (15 * 1px));
  padding-bottom: calc(1 * (50 * 1px));
}
@media screen and (min-width: 360px) {
  .swipperRecipe {
    padding-right: calc(1 * (15 * 1px + (50 - 15) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .swipperRecipe {
    padding-right: calc(1 * (50 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .swipperRecipe {
    padding-left: calc(1 * (15 * 1px + (50 - 15) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .swipperRecipe {
    padding-left: calc(1 * (50 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .swipperRecipe {
    padding-bottom: calc(1 * (50 * 1px + (60 - 50) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .swipperRecipe {
    padding-bottom: calc(1 * (60 * 1px));
  }
}
.swipperRecipe .swiper-slide {
  width: 380px;
}
@media screen and (max-width: 576px) {
  .swipperRecipe .swiper-slide {
    width: 100%;
  }
}

.swiper-scrollbar {
  max-width: 600px !important;
  margin-left: auto;
  margin-right: auto;
  height: 3px !important;
}
.swiper-scrollbar .swiper-scrollbar-drag {
  background: var(--color-blue);
}

@media screen and (min-width: 577px) {
  .swiper-home .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto !important;
  }
  .swiper-home .swiper-slide img {
    display: block;
    object-fit: cover;
    height: calc(1 * (500 * 1px));
  }
}
@media screen and (min-width: 577px) and (min-width: 360px) {
  .swiper-home .swiper-slide img {
    height: calc(1 * (500 * 1px + (700 - 500) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 577px) and (min-width: 1920px) {
  .swiper-home .swiper-slide img {
    height: calc(1 * (700 * 1px));
  }
}
.tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100em;
  padding: 8px 12px;
}

.team-member {
  position: relative;
  width: 100%;
  height: 100%;
}

.team-member__footer {
  position: relative;
}

.team-member_has-social:hover .team-member__position {
  transition-delay: 50ms;
  transform: translateY(-30px);
  opacity: 0;
  visibility: hidden;
}
.team-member_has-social:hover .team-member__name {
  transform: translateY(-20px);
  opacity: 0;
  visibility: hidden;
  transition-delay: 0ms;
}
.team-member_has-social:hover .team-member__social li {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}
.team-member_has-social:hover .team-member__social li:nth-child(1) {
  transition-delay: 70ms;
}
.team-member_has-social:hover .team-member__social li:nth-child(2) {
  transition-delay: 90ms;
}
.team-member_has-social:hover .team-member__social li:nth-child(3) {
  transition-delay: 110ms;
}
.team-member_has-social:hover .team-member__social li:nth-child(4) {
  transition-delay: 130ms;
}
.team-member_has-social:hover .team-member__social li:nth-child(5) {
  transition-delay: 150ms;
}
.team-member_has-social:hover .team-member__social li:nth-child(6) {
  transition-delay: 170ms;
}
.team-member_has-social:hover .team-member__social li:nth-child(7) {
  transition-delay: 190ms;
}
.team-member_has-social:hover .team-member__social li:nth-child(8) {
  transition-delay: 210ms;
}
.team-member_has-social:hover .team-member__social li:nth-child(9) {
  transition-delay: 230ms;
}
.team-member_has-social:hover .team-member__social li:nth-child(10) {
  transition-delay: 250ms;
}

.team-member__name {
  transition: all 0.3s ease;
  transition-delay: 150ms;
}

.team-member__position {
  transition: all 0.3s ease;
  transition-delay: 100ms;
}

.team-member__social {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.team-member__social .soc1al li {
  transform: translateY(30px);
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  opacity: 0;
  visibility: hidden;
}

.underline, .underline-hover-self {
  display: inline-block;
  position: relative;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
}
.underline .arts-split-text__line, .underline-hover-self .arts-split-text__line {
  display: inline-block !important;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.4s ease;
}

.underline-hover-self {
  background-position: 100% 100%;
  background-size: 0% 1px;
  transition: background-size 0.4s ease, color 0.2s ease-in-out;
}
.underline-hover-self:hover {
  background-position: 0% 100%;
  background-size: 100% 1px;
}

.underline-hover:hover .underline-hover__target {
  background-position: 0% 100%;
  background-size: 100% 1px;
}
.underline-hover:hover .underline-hover__target .arts-split-text__line {
  background-position: 0% 100%;
  background-size: 100% 1px;
}

.underline-hover__target:not(.arts-split-text) {
  display: inline;
  position: relative;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.4s ease;
}

.transition-opacity {
  transition: opacity 0.3s ease;
}

.transition-color {
  transition: color 0.3s ease;
}

.has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter {
  float: left;
  font-style: normal;
  margin: 0.15em 0.25em 0 0;
}
.has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter:after {
  content: "";
  display: table;
  clear: both;
}
.has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter:after {
  content: "";
  display: table;
  clear: both;
}

.arts-split-text:not(.js-arts-split-text) .has-drop-cap > div:first-child {
  display: inline-block !important;
  float: left;
}
.arts-split-text:not(.js-arts-split-text) .has-drop-cap > div:first-child:after {
  content: "";
  display: table;
  clear: both;
}
.arts-split-text:not(.js-arts-split-text) .has-drop-cap:first-letter {
  margin: unset;
  float: unset;
  font-size: unset;
  text-transform: unset;
}

.display-xxl {
  font-family: var(--display-xxl-font-family);
  font-weight: var(--display-xxl-font-weight);
  line-height: var(--display-xxl-line-height);
  letter-spacing: var(--display-xxl-letter-spacing);
  font-size: calc(var(--display-xxl-min-font-size) * 1px);
  color: var(--display-xxl-color);
}
@media screen and (min-width: 360px) {
  .display-xxl {
    font-size: calc(var(--display-xxl-min-font-size) * 1px + (var(--display-xxl-max-font-size) - var(--display-xxl-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .display-xxl {
    font-size: calc(var(--display-xxl-max-font-size) * 1px);
  }
}

.display-xl {
  font-family: var(--display-xl-font-family);
  font-weight: var(--display-xl-font-weight);
  line-height: var(--display-xl-line-height);
  letter-spacing: var(--display-xl-letter-spacing);
  font-size: calc(var(--display-xl-min-font-size) * 1px);
  color: var(--display-xl-color);
}
@media screen and (min-width: 360px) {
  .display-xl {
    font-size: calc(var(--display-xl-min-font-size) * 1px + (var(--display-xl-max-font-size) - var(--display-xl-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .display-xl {
    font-size: calc(var(--display-xl-max-font-size) * 1px);
  }
}

.display-caps {
  font-family: var(--display-caps-font-family);
  font-weight: var(--display-caps-font-weight);
  line-height: var(--display-caps-line-height);
  letter-spacing: var(--display-caps-letter-spacing);
  font-size: calc(var(--display-caps-min-font-size) * 1px);
  color: var(--display-caps-color);
}
@media screen and (min-width: 360px) {
  .display-caps {
    font-size: calc(var(--display-caps-min-font-size) * 1px + (var(--display-caps-max-font-size) - var(--display-caps-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .display-caps {
    font-size: calc(var(--display-caps-max-font-size) * 1px);
  }
}

.display-caps {
  text-transform: uppercase;
}

h1, .h1 {
  font-family: var(--h1-font-family);
  font-weight: var(--h1-font-weight);
  line-height: var(--h1-line-height);
  letter-spacing: var(--h1-letter-spacing);
  font-size: calc(var(--h1-min-font-size) * 1px);
  color: var(--h1-color);
}
@media screen and (min-width: 360px) {
  h1, .h1 {
    font-size: calc(var(--h1-min-font-size) * 1px + (var(--h1-max-font-size) - var(--h1-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  h1, .h1 {
    font-size: calc(var(--h1-max-font-size) * 1px);
  }
}

h2, .h2, .h2 p {
  font-family: var(--h2-font-family);
  font-weight: var(--h2-font-weight);
  line-height: var(--h2-line-height);
  letter-spacing: var(--h2-letter-spacing);
  font-size: calc(var(--h2-min-font-size) * 1px);
  color: var(--h2-color);
}
@media screen and (min-width: 360px) {
  h2, .h2, .h2 p {
    font-size: calc(var(--h2-min-font-size) * 1px + (var(--h2-max-font-size) - var(--h2-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  h2, .h2, .h2 p {
    font-size: calc(var(--h2-max-font-size) * 1px);
  }
}

h3, .h3 {
  font-family: var(--h3-font-family);
  font-weight: var(--h3-font-weight);
  line-height: var(--h3-line-height);
  letter-spacing: var(--h3-letter-spacing);
  font-size: calc(var(--h3-min-font-size) * 1px);
  color: var(--h3-color);
}
@media screen and (min-width: 360px) {
  h3, .h3 {
    font-size: calc(var(--h3-min-font-size) * 1px + (var(--h3-max-font-size) - var(--h3-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  h3, .h3 {
    font-size: calc(var(--h3-max-font-size) * 1px);
  }
}

h4, .h4, .comments-title, .comment-reply-title, .comments-title p, .comment-reply-title p, .h4 p {
  font-family: var(--h4-font-family);
  font-weight: var(--h4-font-weight);
  line-height: var(--h4-line-height);
  letter-spacing: var(--h4-letter-spacing);
  font-size: calc(var(--h4-min-font-size) * 1px);
  color: var(--h4-color);
}
@media screen and (min-width: 360px) {
  h4, .h4, .comments-title, .comment-reply-title, .comments-title p, .comment-reply-title p, .h4 p {
    font-size: calc(var(--h4-min-font-size) * 1px + (var(--h4-max-font-size) - var(--h4-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  h4, .h4, .comments-title, .comment-reply-title, .comments-title p, .comment-reply-title p, .h4 p {
    font-size: calc(var(--h4-max-font-size) * 1px);
  }
}

h5, .h5 {
  font-family: var(--h5-font-family);
  font-weight: var(--h5-font-weight);
  line-height: var(--h5-line-height);
  letter-spacing: var(--h5-letter-spacing);
  font-size: calc(var(--h5-min-font-size) * 1px);
  color: var(--h5-color);
}
@media screen and (min-width: 360px) {
  h5, .h5 {
    font-size: calc(var(--h5-min-font-size) * 1px + (var(--h5-max-font-size) - var(--h5-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  h5, .h5 {
    font-size: calc(var(--h5-max-font-size) * 1px);
  }
}

h6, .h6, .comment-body .fn {
  font-family: var(--h6-font-family);
  font-weight: var(--h6-font-weight);
  line-height: var(--h6-line-height);
  letter-spacing: var(--h6-letter-spacing);
  font-size: calc(var(--h6-min-font-size) * 1px);
  color: var(--h6-color);
}
@media screen and (min-width: 360px) {
  h6, .h6, .comment-body .fn {
    font-size: calc(var(--h6-min-font-size) * 1px + (var(--h6-max-font-size) - var(--h6-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  h6, .h6, .comment-body .fn {
    font-size: calc(var(--h6-max-font-size) * 1px);
  }
}

.subheading, .wp-block-latest-posts__post-date, .widget_categories ul li span, .comment-edit-link, .comment-reply-link, .comment-metadata {
  font-family: var(--subheading-font-family);
  font-weight: var(--subheading-font-weight);
  line-height: var(--subheading-line-height);
  letter-spacing: var(--subheading-letter-spacing);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  color: var(--subheading-color);
}
@media screen and (min-width: 360px) {
  .subheading, .wp-block-latest-posts__post-date, .widget_categories ul li span, .comment-edit-link, .comment-reply-link, .comment-metadata {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .subheading, .wp-block-latest-posts__post-date, .widget_categories ul li span, .comment-edit-link, .comment-reply-link, .comment-metadata {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.subheading, .wp-block-latest-posts__post-date, .widget_categories ul li span, .comment-edit-link, .comment-reply-link, .comment-metadata {
  text-transform: uppercase;
}

.overlay-menu-item {
  font-family: var(--overlay-menu-item-font-family);
  font-weight: var(--overlay-menu-item-font-weight);
  line-height: var(--overlay-menu-item-line-height);
  letter-spacing: var(--overlay-menu-item-letter-spacing);
  font-size: calc(var(--overlay-menu-item-min-font-size) * 1px);
  color: var(--overlay-menu-item-color);
}
@media screen and (min-width: 360px) {
  .overlay-menu-item {
    font-size: calc(var(--overlay-menu-item-min-font-size) * 1px + (var(--overlay-menu-item-max-font-size) - var(--overlay-menu-item-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .overlay-menu-item {
    font-size: calc(var(--overlay-menu-item-max-font-size) * 1px);
  }
}

.overlay-submenu-item {
  font-family: var(--overlay-submenu-item-font-family);
  font-weight: var(--overlay-submenu-item-font-weight);
  line-height: var(--overlay-submenu-item-line-height);
  letter-spacing: var(--overlay-submenu-item-letter-spacing);
  font-size: calc(var(--overlay-submenu-item-min-font-size) * 1px);
  color: var(--overlay-submenu-item-color);
}
@media screen and (min-width: 360px) {
  .overlay-submenu-item {
    font-size: calc(var(--overlay-submenu-item-min-font-size) * 1px + (var(--overlay-submenu-item-max-font-size) - var(--overlay-submenu-item-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .overlay-submenu-item {
    font-size: calc(var(--overlay-submenu-item-max-font-size) * 1px);
  }
}

.paragraph {
  font-family: var(--paragraph-font-family);
  font-weight: var(--paragraph-font-weight);
  line-height: var(--paragraph-line-height);
  letter-spacing: var(--paragraph-letter-spacing);
  font-size: calc(var(--paragraph-min-font-size) * 1px);
  color: var(--paragraph-color);
}
@media screen and (min-width: 360px) {
  .paragraph {
    font-size: calc(var(--paragraph-min-font-size) * 1px + (var(--paragraph-max-font-size) - var(--paragraph-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .paragraph {
    font-size: calc(var(--paragraph-max-font-size) * 1px);
  }
}

.ui-element, .pswp-custom-button, .pswp-custom-caption__content, .header__col {
  font-family: var(--ui-element-font-family);
  font-weight: var(--ui-element-font-weight);
  line-height: var(--ui-element-line-height);
  letter-spacing: var(--ui-element-letter-spacing);
  font-size: calc(var(--ui-element-min-font-size) * 1px);
  color: var(--ui-element-color);
}
@media screen and (min-width: 360px) {
  .ui-element, .pswp-custom-button, .pswp-custom-caption__content, .header__col {
    font-size: calc(var(--ui-element-min-font-size) * 1px + (var(--ui-element-max-font-size) - var(--ui-element-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ui-element, .pswp-custom-button, .pswp-custom-caption__content, .header__col {
    font-size: calc(var(--ui-element-max-font-size) * 1px);
  }
}

.dropcap, .has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter {
  font-family: var(--dropcap-font-family);
  font-weight: var(--dropcap-font-weight);
  line-height: var(--dropcap-line-height);
  letter-spacing: var(--dropcap-letter-spacing);
  font-size: calc(var(--dropcap-min-font-size) * 1px);
  color: var(--dropcap-color);
}
@media screen and (min-width: 360px) {
  .dropcap, .has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter {
    font-size: calc(var(--dropcap-min-font-size) * 1px + (var(--dropcap-max-font-size) - var(--dropcap-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .dropcap, .has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter {
    font-size: calc(var(--dropcap-max-font-size) * 1px);
  }
}

.super-label {
  font-family: var(--super-label-font-family);
  font-weight: var(--super-label-font-weight);
  line-height: var(--super-label-line-height);
  letter-spacing: var(--super-label-letter-spacing);
  font-size: calc(var(--super-label-min-font-size) * 1px);
  color: var(--super-label-color);
}
@media screen and (min-width: 360px) {
  .super-label {
    font-size: calc(var(--super-label-min-font-size) * 1px + (var(--super-label-max-font-size) - var(--super-label-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .super-label {
    font-size: calc(var(--super-label-max-font-size) * 1px);
  }
}

a {
  transition: color 0.3s ease;
  text-decoration: none;
  color: var(--color-links);
}
a:hover {
  color: var(--color-accent);
  text-decoration: none;
}
a * {
  pointer-events: none;
}

.strong {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

h1, h2, h3 {
  margin-top: calc(1 * (16 * 1px));
  margin-bottom: calc(1 * (16 * 1px));
}
@media screen and (min-width: 360px) {
  h1, h2, h3 {
    margin-top: calc(1 * (16 * 1px + (24 - 16) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  h1, h2, h3 {
    margin-top: calc(1 * (24 * 1px));
  }
}
@media screen and (min-width: 360px) {
  h1, h2, h3 {
    margin-bottom: calc(1 * (16 * 1px + (24 - 16) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  h1, h2, h3 {
    margin-bottom: calc(1 * (24 * 1px));
  }
}

h4, h5, h6 {
  margin-top: calc(1 * (16 * 1px));
  margin-bottom: calc(1 * (16 * 1px));
}
@media screen and (min-width: 360px) {
  h4, h5, h6 {
    margin-top: calc(1 * (16 * 1px + (24 - 16) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  h4, h5, h6 {
    margin-top: calc(1 * (24 * 1px));
  }
}
@media screen and (min-width: 360px) {
  h4, h5, h6 {
    margin-bottom: calc(1 * (16 * 1px + (24 - 16) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  h4, h5, h6 {
    margin-bottom: calc(1 * (24 * 1px));
  }
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: calc(1 * (16 * 1px));
}
@media screen and (min-width: 360px) {
  p {
    font-size: calc(1 * (16 * 1px + (18 - 16) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  p {
    font-size: calc(1 * (18 * 1px));
  }
}

h6 {
  text-transform: uppercase;
}

blockquote {
  display: block;
  font-size: calc(var(--blockquote-min-font-size) * 1px);
  line-height: var(--blockquote-line-height);
  letter-spacing: calc(var(--blockquote-letter-spacing) * 1px);
  font-style: italic;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding: 1.5em;
  border: 1px solid var(--color-border-opacity);
  border-radius: 4px;
}
@media screen and (min-width: 360px) {
  blockquote {
    font-size: calc(var(--blockquote-min-font-size) * 1px + (var(--blockquote-max-font-size) - var(--blockquote-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  blockquote {
    font-size: calc(var(--blockquote-max-font-size) * 1px);
  }
}
blockquote p {
  margin-top: 0;
  font-size: calc(var(--blockquote-min-font-size) * 1px);
  line-height: var(--blockquote-line-height);
}
@media screen and (min-width: 360px) {
  blockquote p {
    font-size: calc(var(--blockquote-min-font-size) * 1px + (var(--blockquote-max-font-size) - var(--blockquote-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  blockquote p {
    font-size: calc(var(--blockquote-max-font-size) * 1px);
  }
}
blockquote p:last-child, blockquote p:last-of-type {
  margin-bottom: 0;
}
blockquote cite {
  display: block;
  margin-top: 1em;
  font-size: calc(var(--paragraph-min-font-size) * 1px);
  line-height: 1;
  color: var(--paragraph-color);
  font-style: normal;
  font-weight: bold;
}
@media screen and (min-width: 360px) {
  blockquote cite {
    font-size: calc(var(--paragraph-min-font-size) * 1px + (var(--paragraph-max-font-size) - var(--paragraph-min-font-size)) * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  blockquote cite {
    font-size: calc(var(--paragraph-max-font-size) * 1px);
  }
}
blockquote svg {
  max-width: calc(1 * (30 * 1px));
  height: auto;
  margin-top: 4px;
  fill: currentColor;
}
@media screen and (min-width: 360px) {
  blockquote svg {
    max-width: calc(1 * (30 * 1px + (50 - 30) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  blockquote svg {
    max-width: calc(1 * (50 * 1px));
  }
}

.h5 {
  text-transform: uppercase;
}

[data-arts-color-theme=dark] {
  color: var(--paragraph-color-dark-theme);
  --paragraph-color: var(--paragraph-color-dark-theme);
  --ui-element-color: var(--ui-element-color-dark-theme);
  --display-xxl-color: var(--display-xxl-color-dark-theme);
  --display-xl-color: var(--display-xl-color-dark-theme);
  --display-caps-color: var(--display-caps-color-dark-theme);
  --h1-color: var(--h1-color-dark-theme);
  --h2-color: var(--h2-color-dark-theme);
  --h3-color: var(--h3-color-dark-theme);
  --h4-color: var(--h4-color-dark-theme);
  --h5-color: var(--h5-color-dark-theme);
  --h6-color: var(--h6-color-dark-theme);
  --blockquote-color: var(--blockquote-color-dark-theme);
  --dropcap-color: var(--dropcap-color-dark-theme);
  --subheading-color: var(--subheading-color-dark-theme);
  --overlay-menu-item-color: var(--overlay-menu-item-color-dark-theme);
  --overlay-submenu-item-color: var(--overlay-submenu-item-color-dark-theme);
  --super-label-color: var(--super-label-color-dark-theme);
  --color-accent: var(--color-accent-dark-theme);
  --color-border-solid: var(--color-border-solid-dark-theme);
  --color-border-opacity: var(--color-border-opacity-dark-theme);
  --color-links: var(--color-links-dark-theme);
}

@media screen and (max-width: 1100px) {
  [data-arts-color-theme-lg=dark] {
    color: var(--paragraph-color-dark-theme);
    --paragraph-color: var(--paragraph-color-dark-theme);
    --ui-element-color: var(--ui-element-color-dark-theme);
    --display-xxl-color: var(--display-xxl-color-dark-theme);
    --display-xl-color: var(--display-xl-color-dark-theme);
    --display-caps-color: var(--display-caps-color-dark-theme);
    --h1-color: var(--h1-color-dark-theme);
    --h2-color: var(--h2-color-dark-theme);
    --h3-color: var(--h3-color-dark-theme);
    --h4-color: var(--h4-color-dark-theme);
    --h5-color: var(--h5-color-dark-theme);
    --h6-color: var(--h6-color-dark-theme);
    --blockquote-color: var(--blockquote-color-dark-theme);
    --dropcap-color: var(--dropcap-color-dark-theme);
    --subheading-color: var(--subheading-color-dark-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-dark-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-dark-theme);
    --super-label-color: var(--super-label-color-dark-theme);
    --color-accent: var(--color-accent-dark-theme);
    --color-border-solid: var(--color-border-solid-dark-theme);
    --color-border-opacity: var(--color-border-opacity-dark-theme);
    --color-links: var(--color-links-dark-theme);
  }
}
@media screen and (max-width: 991px) {
  [data-arts-color-theme-md=dark] {
    color: var(--paragraph-color-dark-theme);
    --paragraph-color: var(--paragraph-color-dark-theme);
    --ui-element-color: var(--ui-element-color-dark-theme);
    --display-xxl-color: var(--display-xxl-color-dark-theme);
    --display-xl-color: var(--display-xl-color-dark-theme);
    --display-caps-color: var(--display-caps-color-dark-theme);
    --h1-color: var(--h1-color-dark-theme);
    --h2-color: var(--h2-color-dark-theme);
    --h3-color: var(--h3-color-dark-theme);
    --h4-color: var(--h4-color-dark-theme);
    --h5-color: var(--h5-color-dark-theme);
    --h6-color: var(--h6-color-dark-theme);
    --blockquote-color: var(--blockquote-color-dark-theme);
    --dropcap-color: var(--dropcap-color-dark-theme);
    --subheading-color: var(--subheading-color-dark-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-dark-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-dark-theme);
    --super-label-color: var(--super-label-color-dark-theme);
    --color-accent: var(--color-accent-dark-theme);
    --color-border-solid: var(--color-border-solid-dark-theme);
    --color-border-opacity: var(--color-border-opacity-dark-theme);
    --color-links: var(--color-links-dark-theme);
  }
}
@media screen and (max-width: 767px) {
  [data-arts-color-theme-sm=dark] {
    color: var(--paragraph-color-dark-theme);
    --paragraph-color: var(--paragraph-color-dark-theme);
    --ui-element-color: var(--ui-element-color-dark-theme);
    --display-xxl-color: var(--display-xxl-color-dark-theme);
    --display-xl-color: var(--display-xl-color-dark-theme);
    --display-caps-color: var(--display-caps-color-dark-theme);
    --h1-color: var(--h1-color-dark-theme);
    --h2-color: var(--h2-color-dark-theme);
    --h3-color: var(--h3-color-dark-theme);
    --h4-color: var(--h4-color-dark-theme);
    --h5-color: var(--h5-color-dark-theme);
    --h6-color: var(--h6-color-dark-theme);
    --blockquote-color: var(--blockquote-color-dark-theme);
    --dropcap-color: var(--dropcap-color-dark-theme);
    --subheading-color: var(--subheading-color-dark-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-dark-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-dark-theme);
    --super-label-color: var(--super-label-color-dark-theme);
    --color-accent: var(--color-accent-dark-theme);
    --color-border-solid: var(--color-border-solid-dark-theme);
    --color-border-opacity: var(--color-border-opacity-dark-theme);
    --color-links: var(--color-links-dark-theme);
  }
}
@media screen and (max-width: 576px) {
  [data-arts-color-theme-xs=dark] {
    color: var(--paragraph-color-dark-theme);
    --paragraph-color: var(--paragraph-color-dark-theme);
    --ui-element-color: var(--ui-element-color-dark-theme);
    --display-xxl-color: var(--display-xxl-color-dark-theme);
    --display-xl-color: var(--display-xl-color-dark-theme);
    --display-caps-color: var(--display-caps-color-dark-theme);
    --h1-color: var(--h1-color-dark-theme);
    --h2-color: var(--h2-color-dark-theme);
    --h3-color: var(--h3-color-dark-theme);
    --h4-color: var(--h4-color-dark-theme);
    --h5-color: var(--h5-color-dark-theme);
    --h6-color: var(--h6-color-dark-theme);
    --blockquote-color: var(--blockquote-color-dark-theme);
    --dropcap-color: var(--dropcap-color-dark-theme);
    --subheading-color: var(--subheading-color-dark-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-dark-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-dark-theme);
    --super-label-color: var(--super-label-color-dark-theme);
    --color-accent: var(--color-accent-dark-theme);
    --color-border-solid: var(--color-border-solid-dark-theme);
    --color-border-opacity: var(--color-border-opacity-dark-theme);
    --color-links: var(--color-links-dark-theme);
  }
}
[data-arts-color-theme=light] {
  color: var(--paragraph-color-light-theme);
  --paragraph-color: var(--paragraph-color-light-theme);
  --ui-element-color: var(--ui-element-color-light-theme);
  --display-xxl-color: var(--display-xxl-color-light-theme);
  --display-xl-color: var(--display-xl-color-light-theme);
  --display-caps-color: var(--display-caps-color-light-theme);
  --h1-color: var(--h1-color-light-theme);
  --h2-color: var(--h2-color-light-theme);
  --h3-color: var(--h3-color-light-theme);
  --h4-color: var(--h4-color-light-theme);
  --h5-color: var(--h5-color-light-theme);
  --h6-color: var(--h6-color-light-theme);
  --blockquote-color: var(--blockquote-color-light-theme);
  --dropcap-color: var(--dropcap-color-light-theme);
  --subheading-color: var(--subheading-color-light-theme);
  --overlay-menu-item-color: var(--overlay-menu-item-color-light-theme);
  --overlay-submenu-item-color: var(--overlay-submenu-item-color-light-theme);
  --super-label-color: var(--super-label-color-light-theme);
  --color-accent: var(--color-accent-light-theme);
  --color-border-solid: var(--color-border-solid-light-theme);
  --color-border-opacity: var(--color-border-opacity-light-theme);
  --color-links: var(--color-links-light-theme);
}

@media screen and (max-width: 1100px) {
  [data-arts-color-theme-lg=light] {
    color: var(--paragraph-color-light-theme);
    --paragraph-color: var(--paragraph-color-light-theme);
    --ui-element-color: var(--ui-element-color-light-theme);
    --display-xxl-color: var(--display-xxl-color-light-theme);
    --display-xl-color: var(--display-xl-color-light-theme);
    --display-caps-color: var(--display-caps-color-light-theme);
    --h1-color: var(--h1-color-light-theme);
    --h2-color: var(--h2-color-light-theme);
    --h3-color: var(--h3-color-light-theme);
    --h4-color: var(--h4-color-light-theme);
    --h5-color: var(--h5-color-light-theme);
    --h6-color: var(--h6-color-light-theme);
    --blockquote-color: var(--blockquote-color-light-theme);
    --dropcap-color: var(--dropcap-color-light-theme);
    --subheading-color: var(--subheading-color-light-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-light-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-light-theme);
    --super-label-color: var(--super-label-color-light-theme);
    --color-accent: var(--color-accent-light-theme);
    --color-border-solid: var(--color-border-solid-light-theme);
    --color-border-opacity: var(--color-border-opacity-light-theme);
    --color-links: var(--color-links-light-theme);
  }
}
@media screen and (max-width: 991px) {
  [data-arts-color-theme-md=light] {
    color: var(--paragraph-color-light-theme);
    --paragraph-color: var(--paragraph-color-light-theme);
    --ui-element-color: var(--ui-element-color-light-theme);
    --display-xxl-color: var(--display-xxl-color-light-theme);
    --display-xl-color: var(--display-xl-color-light-theme);
    --display-caps-color: var(--display-caps-color-light-theme);
    --h1-color: var(--h1-color-light-theme);
    --h2-color: var(--h2-color-light-theme);
    --h3-color: var(--h3-color-light-theme);
    --h4-color: var(--h4-color-light-theme);
    --h5-color: var(--h5-color-light-theme);
    --h6-color: var(--h6-color-light-theme);
    --blockquote-color: var(--blockquote-color-light-theme);
    --dropcap-color: var(--dropcap-color-light-theme);
    --subheading-color: var(--subheading-color-light-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-light-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-light-theme);
    --super-label-color: var(--super-label-color-light-theme);
    --color-accent: var(--color-accent-light-theme);
    --color-border-solid: var(--color-border-solid-light-theme);
    --color-border-opacity: var(--color-border-opacity-light-theme);
    --color-links: var(--color-links-light-theme);
  }
}
@media screen and (max-width: 767px) {
  [data-arts-color-theme-sm=light] {
    color: var(--paragraph-color-light-theme);
    --paragraph-color: var(--paragraph-color-light-theme);
    --ui-element-color: var(--ui-element-color-light-theme);
    --display-xxl-color: var(--display-xxl-color-light-theme);
    --display-xl-color: var(--display-xl-color-light-theme);
    --display-caps-color: var(--display-caps-color-light-theme);
    --h1-color: var(--h1-color-light-theme);
    --h2-color: var(--h2-color-light-theme);
    --h3-color: var(--h3-color-light-theme);
    --h4-color: var(--h4-color-light-theme);
    --h5-color: var(--h5-color-light-theme);
    --h6-color: var(--h6-color-light-theme);
    --blockquote-color: var(--blockquote-color-light-theme);
    --dropcap-color: var(--dropcap-color-light-theme);
    --subheading-color: var(--subheading-color-light-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-light-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-light-theme);
    --super-label-color: var(--super-label-color-light-theme);
    --color-accent: var(--color-accent-light-theme);
    --color-border-solid: var(--color-border-solid-light-theme);
    --color-border-opacity: var(--color-border-opacity-light-theme);
    --color-links: var(--color-links-light-theme);
  }
}
@media screen and (max-width: 576px) {
  [data-arts-color-theme-xs=light] {
    color: var(--paragraph-color-light-theme);
    --paragraph-color: var(--paragraph-color-light-theme);
    --ui-element-color: var(--ui-element-color-light-theme);
    --display-xxl-color: var(--display-xxl-color-light-theme);
    --display-xl-color: var(--display-xl-color-light-theme);
    --display-caps-color: var(--display-caps-color-light-theme);
    --h1-color: var(--h1-color-light-theme);
    --h2-color: var(--h2-color-light-theme);
    --h3-color: var(--h3-color-light-theme);
    --h4-color: var(--h4-color-light-theme);
    --h5-color: var(--h5-color-light-theme);
    --h6-color: var(--h6-color-light-theme);
    --blockquote-color: var(--blockquote-color-light-theme);
    --dropcap-color: var(--dropcap-color-light-theme);
    --subheading-color: var(--subheading-color-light-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-light-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-light-theme);
    --super-label-color: var(--super-label-color-light-theme);
    --color-accent: var(--color-accent-light-theme);
    --color-border-solid: var(--color-border-solid-light-theme);
    --color-border-opacity: var(--color-border-opacity-light-theme);
    --color-links: var(--color-links-light-theme);
  }
}
.widget select {
  width: 100%;
  max-width: 100%;
}
.widget ul, .widget ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.widget ul li, .widget ol li {
  display: block;
  margin-bottom: 0.5em;
}
.widget ul li:last-child, .widget ol li:last-child {
  margin-bottom: 0;
}
.widget ul li .children, .widget ul li .sub-menu, .widget ol li .children, .widget ol li .sub-menu {
  margin-top: 0.5em;
}
.widget ul ul, .widget ul ol, .widget ol ul, .widget ol ol {
  width: 100%;
  padding-left: 1em;
  margin-top: 1em;
}
.widget ul .sub-menu, .widget ol .sub-menu {
  padding-left: 1em;
  margin-top: 0.5em;
}
.widget .inline-links {
  text-align: inherit;
}

.widget__title {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.widget__title_underline:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--color-border-solid);
  margin-top: 0.25em;
  margin-bottom: 0.5em;
}

.comments-title, .comment-reply-title {
  position: relative;
  z-index: 50;
  margin-top: 0;
  margin-bottom: 0.25em;
}

.comment-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 0 !important;
}
.comment-list > li {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
.comment-list > li ol.children {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 4%;
}
.comment-list > li ol.children li {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
.comment-list > li ol.children li:last-child {
  padding-bottom: 0;
}
.comment-list > li:not(:last-child) {
  border-bottom: 1px solid var(--color-border-opacity);
}
.comment-list > ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.comment-author {
  margin-right: 2em;
  flex: 0 0 auto;
}
.comment-author .avatar {
  position: relative;
  width: 100%;
  max-width: 80px;
  max-height: 80px;
  border-radius: 100%;
  display: block;
}

.comment-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.comment-metadata {
  margin-left: 1rem;
}
.comment-metadata a {
  border-bottom: none !important;
}

.comment-body {
  display: flex;
}
.comment-content {
  width: 100%;
}

.reply {
  display: inline-block;
  line-height: 1;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.comment-reply-link {
  border-bottom: none !important;
  margin-right: 24px;
}
.comment-reply-link:before {
  content: "\e15e";
  font-family: "Material Icons";
  text-transform: none;
  margin-right: 0.5rem;
}

.comment-respond {
  position: relative;
  z-index: 50;
  margin-top: 1em;
}

.comment-edit-link {
  border-bottom: none !important;
}
.comment-edit-link:before {
  content: "\e3c9";
  font-family: "Material Icons";
  text-transform: none;
  margin-right: 6px;
}

.trackback .edit-link, .pingback .edit-link {
  margin-left: 0.5em;
}

#cancel-comment-reply-link {
  margin-top: 1em;
  margin-left: 1em;
}

.comment {
  outline-width: 1em;
  outline-style: solid;
  outline-color: transparent;
  transition: background-color 2.4s ease, outline-color 2.4s ease, opacity 0.3s ease;
}

.comment_ajax-added {
  outline-color: #eeece6;
  background-color: #eeece6;
}

.comment_ajax-unapproved .comment-body {
  opacity: 0.3;
  pointer-events: none;
}

@media screen and (max-width: 991px) {
  .comment-author {
    margin-right: 1em;
  }
  .comment-author .avatar {
    max-width: 50px;
    max-height: 50px;
  }
  .comment-meta {
    flex-wrap: wrap;
  }
  .comment-metadata {
    margin-top: 0.75em;
    margin-left: 0;
    width: 100%;
  }
}
.pagination .nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination .nav-links__container {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: auto;
  margin-right: auto;
}
.pagination .page-numbers {
  --number-size-min: 32;
  --number-size-max: 48;
  width: calc(1 * (var(--number-size-min) * 1px));
  height: calc(1 * (var(--number-size-min) * 1px));
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-radius: 100%;
  border: 1px solid var(--color-border-opacity);
  transition-property: color, border-color;
  margin: 0.5rem;
}
@media screen and (min-width: 360px) {
  .pagination .page-numbers {
    width: calc(1 * (var(--number-size-min) * 1px + (var(--number-size-max) - var(--number-size-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pagination .page-numbers {
    width: calc(1 * (var(--number-size-max) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .pagination .page-numbers {
    height: calc(1 * (var(--number-size-min) * 1px + (var(--number-size-max) - var(--number-size-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .pagination .page-numbers {
    height: calc(1 * (var(--number-size-max) * 1px));
  }
}
.pagination .page-numbers.prev {
  font-size: 24px;
  font-weight: normal;
  margin-left: -1rem;
  border-color: transparent;
}
.pagination .page-numbers.next {
  font-size: 24px;
  font-weight: normal;
  margin-right: -1rem;
  border-color: transparent;
}
.pagination .page-numbers:not(a) {
  border-color: transparent;
}
.pagination .page-numbers.current {
  background-color: var(--color-accent);
  color: var(--ui-element-color-dark-theme);
  border-color: transparent;
}
.pagination a.page-numbers:hover {
  border-color: currentColor;
}

.has-cursor-follower .pagination a[data-arts-cursor-follower-target]:hover {
  border-color: transparent !important;
}

@media screen and (max-width: 991px) {
  .pagination .nav-links__container {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .pagination .page-numbers {
    margin: 0.25rem;
  }
  .pagination .page-numbers.prev {
    margin-left: -0.75rem;
  }
  .pagination .page-numbers.next {
    margin-right: -0.75rem;
  }
}
.pagination[data-arts-color-theme=dark] .page-numbers.current, [data-arts-color-theme=dark] .pagination .page-numbers.current {
  color: var(--ui-element-color-light-theme);
}

.gallery {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

.blocks-gallery-caption, .wp-block-embed figcaption, .wp-block-image figcaption {
  font-size: 13px;
  text-align: center;
}

.blocks-gallery-item__caption {
  line-height: 1.4;
}

.post {
  position: relative;
  z-index: 50;
}

.post__wrapper-date {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 50;
}

.post__media, .post__link {
  position: relative;
}

.post-meta {
  list-style-type: none;
  padding: 0;
  margin: 0;
  line-height: 1;
}
.post-meta i {
  font-size: 14px !important;
  vertical-align: middle;
  margin-right: 4px;
}
.post-meta li {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.6;
  vertical-align: middle;
}
.post-meta li span {
  vertical-align: middle;
}
.post-meta li:not(:last-child):after {
  content: "/";
  color: var(--color-accent);
  display: inline-block;
  margin-left: 8px;
  margin-right: 4px;
  transition: all 0.3s ease;
}
.post-meta ul {
  padding-left: 0;
}
.post-meta a {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}

.post-meta_block li {
  display: block;
  margin: 0.5em 0;
}
.post-meta_block li:not(:last-child):after {
  display: none;
}

.post-meta_flex {
  display: flex;
  justify-content: space-between;
}
.post-meta_flex li:not(:last-child):after {
  display: none;
}

.post-meta_accent * {
  color: var(--color-accent);
}

.sidebar > .widget:not(:last-child) {
  margin-bottom: 1.75em;
}

.post-date-floating {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  --date-size-min: 60;
  --date-size-max: 100;
  width: calc(1 * (var(--date-size-min) * 1px));
  height: calc(1 * (var(--date-size-min) * 1px));
  text-align: center;
}
@media screen and (min-width: 360px) {
  .post-date-floating {
    width: calc(1 * (var(--date-size-min) * 1px + (var(--date-size-max) - var(--date-size-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .post-date-floating {
    width: calc(1 * (var(--date-size-max) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .post-date-floating {
    height: calc(1 * (var(--date-size-min) * 1px + (var(--date-size-max) - var(--date-size-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .post-date-floating {
    height: calc(1 * (var(--date-size-max) * 1px));
  }
}

.tagcloud a, .widget .tagcloud a, .wp-block-tag-cloud a {
  display: inline-block;
  font-size: 13px !important;
  font-weight: 600;
  line-height: 1;
  background-color: transparent;
  padding: 8px 14px;
  margin-bottom: 6px;
  margin-right: 4px;
  border-radius: 100px;
  border: 1px solid var(--color-border-opacity);
  transition: 0.3s ease color, 0.3s ease border-color;
}
.tagcloud a:hover, .widget .tagcloud a:hover, .wp-block-tag-cloud a:hover {
  color: var(--color-accent);
  border-color: currentColor;
}
.tagcloud ul, .widget .tagcloud ul, .wp-block-tag-cloud ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.tagcloud ul li, .widget .tagcloud ul li, .wp-block-tag-cloud ul li {
  display: inline-block;
  margin-bottom: 0;
}

.button-circle {
  position: relative;
  --button-paddings-min: 20;
  --button-paddings-max: 20;
  --button-size-min: 80;
  --button-size-max: 100;
  --button-paddings: calc(1 * (var(--button-paddings-min) * 1px));
  --button-size: calc(1 * (var(--button-size-min) * 1px));
  padding-top: 0;
  padding-left: var(--button-paddings);
  padding-bottom: 0;
  padding-right: var(--button-paddings);
  min-width: var(--button-size);
  min-height: var(--button-size);
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid var(--color-orange);
  background: none;
  transition: color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86), border-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  cursor: pointer;
}
@media screen and (min-width: 360px) {
  .button-circle {
    --button-paddings: calc(1 * (var(--button-paddings-min) * 1px + (var(--button-paddings-max) - var(--button-paddings-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .button-circle {
    --button-paddings: calc(1 * (var(--button-paddings-max) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .button-circle {
    --button-size: calc(1 * (var(--button-size-min) * 1px + (var(--button-size-max) - var(--button-size-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .button-circle {
    --button-size: calc(1 * (var(--button-size-max) * 1px));
  }
}
.button-circle:hover {
  color: var(--color-orange);
  border-color: currentColor;
}
.button-circle .button__title {
  text-transform: uppercase;
  color: var(--color-orange);
  font-size: calc(1 * (12 * 1px));
  font-weight: 500;
}
@media screen and (min-width: 360px) {
  .button-circle .button__title {
    font-size: calc(1 * (12 * 1px + (14 - 12) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .button-circle .button__title {
    font-size: calc(1 * (14 * 1px));
  }
}
.button-circle[class*=bg-] {
  border: none;
}
.button-circle[disabled] {
  pointer-events: none;
  opacity: 0.2;
}
.button-circle:before {
  content: "";
  display: block;
  padding-top: calc(100% + var(--button-paddings) * 2);
}

.button-border-opacity {
  border-color: var(--color-border-opacity);
}
.button-border-opacity svg {
  transition: opacity 0.2s ease;
}
.button-border-opacity:hover {
  border-color: currentColor !important;
}
.button-border-opacity:hover svg {
  opacity: 0;
}

.button-progress .svg-circle {
  position: absolute;
  top: -3px;
  left: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  stroke: var(--color-accent);
  border-radius: 100%;
  stroke-width: 0.5px;
  stroke-dashoffset: 0.001;
  stroke-dasharray: 0px, 999999px;
}
.button-progress * {
  pointer-events: none !important;
  user-select: none !important;
}

.button-progress_animating {
  border-color: var(--color-border-opacity) !important;
}

.button-progress__hold {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.has-click-and-hold, .has-click-and-hold * {
  -webkit-touch-callout: none !important;
  user-select: none !important;
}

.button-circles {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 20px;
  height: 20px;
  padding: 0 !important;
}

.button-circles__circle {
  position: relative;
  width: 8px;
  height: 8px;
  outline: none;
  display: inline-block;
  border: 2px solid currentColor;
  vertical-align: middle;
  border-radius: 100%;
}
.button-circles__circle:nth-child(1) {
  align-self: flex-start;
}
.button-circles__circle:nth-child(2) {
  align-self: flex-start;
}
.button-circles__circle:nth-child(3) {
  align-self: flex-end;
}
.button-circles__circle:nth-child(4) {
  align-self: flex-end;
}

.input-checkbox {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 0;
}

.input-checkbox__checkbox {
  display: none;
}

.input-checkbox__checkbox:checked ~ .input-checkbox__label:before {
  content: "check";
  color: var(--color-accent);
}

.input-checkbox__label {
  cursor: pointer;
  display: inline-block;
}
.input-checkbox__label:before {
  content: "";
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  line-height: 1;
  font-weight: bold;
  width: 1.3em;
  height: 1.3em;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid currentColor;
  font-family: "Material Icons";
  font-size: 18px;
  margin-right: 0.4em;
  transition: all 0.3s ease;
}
.input-checkbox__label:hover:before {
  color: var(--color-accent);
}

.input-float {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 0;
}

.input-float__label {
  position: absolute;
  top: 0.75em;
  left: 0;
  margin: auto;
  display: block;
  font-size: 16px;
  transition: all 0.3s ease;
  transform-origin: left center;
  pointer-events: none;
  color: currentColor;
  opacity: 0.7;
}

.input-float__input {
  display: block;
  width: 100%;
  width: 100%;
  border-bottom: 1px solid var(--color-border-opacity);
  border-top: none;
  border-right: none;
  border-left: none;
  outline: none;
  padding: 14px 0 8px;
  transition: all 0.3s ease;
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  line-height: 1.5;
}

.input-float__input_textarea {
  resize: none;
  height: 200px;
}

.input-float__error, span.wpcf7-not-valid-tip {
  display: block;
  font-size: 13px;
  color: red;
  text-align: left;
  margin-top: 4px;
}

.input-float__input_focused + .input-float__label, .input-float__input_not-empty + .input-float__label {
  transform: scale(0.8571) translateY(-1.75rem);
  pointer-events: all;
}

.input-float__input_focused {
  border-color: var(--color-border-solid);
}
.input-float__input_focused + .input-float__label {
  opacity: 1;
  pointer-events: all;
}

.input-search__input {
  padding-right: 30px !important;
}
.input-search__input::-webkit-outer-spin-button, .input-search__input::-webkit-inner-spin-button, .input-search__input::-webkit-clear-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-search__submit {
  display: inline-block;
  font-size: 18px;
  width: 24px;
  height: 24px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 12px;
  bottom: 0;
  margin: auto;
  background: transparent;
  border: none;
  color: #888;
  outline: none;
  transition: all 0.3s ease;
  cursor: pointer;
}
.input-search__submit:hover {
  color: var(--color-accent);
}
.input-search__submit:focus {
  outline: none;
}

#page-header select, #page-wrapper select, #page-footer select {
  display: block;
  border: 1px solid var(--color-border-solid);
  border-radius: 2px;
  padding: 12px 36px 12px 16px;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../img/ui/select_black.png");
  background-position: right 15px bottom 50%;
  background-repeat: no-repeat;
  background-size: 6px 4px;
  color: currentColor;
}

.section-fixed-wall__wrapper {
  width: 100%;
}
.section-fixed-wall__wrapper.initialized {
  height: 100vh;
  overflow: hidden;
}
.section-fixed-wall__wrapper.initialized .section-fixed-wall__img-wrapper-height {
  display: inline-flex;
  height: 100%;
  width: auto;
}
.section-fixed-wall__wrapper.initialized .section-fixed-wall__img-wrapper-height img, .section-fixed-wall__wrapper.initialized .section-fixed-wall__img-wrapper-height video {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  max-width: unset;
}

.section-fixed-wall__img-wrapper-height {
  width: 100%;
  height: auto;
}

.section-demo-screens-wall__wrapper-lanes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section-demo-screens-wall__overlay {
  z-index: 0;
}

@media screen and (max-width: 991px) {
  .section-demo-screens-wall__overlay {
    --overlay-gradient-opacity-end: 0.75;
  }
}
.section-form__marquee {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: -1;
  pointer-events: none;
}

.section-instagram__lane {
  --height-min: 200;
  --height-max: 350;
  --gutters-min: 10;
  --gutters-max: 20;
  height: calc(1 * (var(--height-min) * 1px));
}
@media screen and (min-width: 360px) {
  .section-instagram__lane {
    height: calc(1 * (var(--height-min) * 1px + (var(--height-max) - var(--height-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .section-instagram__lane {
    height: calc(1 * (var(--height-max) * 1px));
  }
}

.section-instagram__link {
  display: block;
}
.section-instagram__link img {
  height: 100%;
}

.section-instagram__item {
  height: 100%;
}

.section-instagram__link {
  height: 100%;
}

.section-instagram__item-inner {
  padding-left: calc(1 * (var(--gutters-min) * 1px));
  padding-right: calc(1 * (var(--gutters-min) * 1px));
  height: 100%;
}
@media screen and (min-width: 360px) {
  .section-instagram__item-inner {
    padding-left: calc(1 * (var(--gutters-min) * 1px + (var(--gutters-max) - var(--gutters-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .section-instagram__item-inner {
    padding-left: calc(1 * (var(--gutters-max) * 1px));
  }
}
@media screen and (min-width: 360px) {
  .section-instagram__item-inner {
    padding-right: calc(1 * (var(--gutters-min) * 1px + (var(--gutters-max) - var(--gutters-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .section-instagram__item-inner {
    padding-right: calc(1 * (var(--gutters-max) * 1px));
  }
}

.section-instagram__inner .section-instagram__lane:first-child {
  padding-bottom: calc(1 * (var(--gutters-min) * 1px));
}
@media screen and (min-width: 360px) {
  .section-instagram__inner .section-instagram__lane:first-child {
    padding-bottom: calc(1 * (var(--gutters-min) * 1px + (var(--gutters-max) - var(--gutters-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .section-instagram__inner .section-instagram__lane:first-child {
    padding-bottom: calc(1 * (var(--gutters-max) * 1px));
  }
}
.section-instagram__inner .section-instagram__lane:last-child {
  padding-top: calc(1 * (var(--gutters-min) * 1px));
}
@media screen and (min-width: 360px) {
  .section-instagram__inner .section-instagram__lane:last-child {
    padding-top: calc(1 * (var(--gutters-min) * 1px + (var(--gutters-max) - var(--gutters-min)) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .section-instagram__inner .section-instagram__lane:last-child {
    padding-top: calc(1 * (var(--gutters-max) * 1px));
  }
}

.section-map {
  height: 100vh;
  max-height: 800px;
}

.section-portfolio-grid__col-filter {
  position: relative;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease, opacity 0.3s ease;
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0);
  padding-top: calc(1 * (20 * 1px));
  padding-bottom: calc(1 * (20 * 1px));
}
@media screen and (min-width: 360px) {
  .section-portfolio-grid__col-filter {
    padding-top: calc(1 * (20 * 1px + (40 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .section-portfolio-grid__col-filter {
    padding-top: calc(1 * (40 * 1px));
  }
}
@media screen and (min-width: 360px) {
  .section-portfolio-grid__col-filter {
    padding-bottom: calc(1 * (20 * 1px + (40 - 20) * ((100vw - 360px) / 1560)));
  }
}
@media screen and (min-width: 1920px) {
  .section-portfolio-grid__col-filter {
    padding-bottom: calc(1 * (40 * 1px));
  }
}
.section-portfolio-grid__col-filter.sticking {
  border-color: transparent;
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.04);
}

.section-portfolio-grid_hovered .portfolio-card__link:hover .hover-zoom__zoom {
  transform: scale(0.98);
}
.section-portfolio-grid_hovered .portfolio-card__link:hover .hover-zoom__inner {
  transform: scale(1.02);
}

.section-portfolio-grid_hovered-zoom-out .portfolio-card__link:not(:hover) .hover-zoom__zoom {
  transform: scale(0.95);
}
.section-portfolio-grid_hovered-zoom-out .portfolio-card__link:not(:hover) .hover-zoom__inner {
  transform: scale(1.05);
}
.section-portfolio-grid_hovered-zoom-out .portfolio-card__opacity-link:not(:hover) {
  opacity: 0.5;
}

.section-portfolio-headings-centered__item {
  display: block;
  position: relative;
  z-index: 50;
  pointer-events: none;
}

.section-portfolio-headings-centered__item-header {
  text-align: center;
}

.section-portfolio-headings-centered__item {
  max-width: calc(100% - var(--gutter-horizontal) * 2);
  margin: auto;
}

.section-portfolio-headings-centered__item-media {
  position: relative;
}

.section-portfolio-headings-centered__link {
  display: block;
  pointer-events: auto;
}

.section-portfolio-headings-centered__wrapper-button {
  display: inline-block;
  pointer-events: auto;
}

.section-portfolio-headings-centered__item-counter {
  position: absolute;
  top: 0;
  left: 1em;
  white-space: nowrap;
  pointer-events: none;
  transform: none !important;
}
.section-portfolio-headings-centered__item-counter [data-arts-split-text-element=char]:nth-child(1), .section-portfolio-headings-centered__item-counter .js-arts-split-text__char:nth-child(1) {
  z-index: -1;
  transform: translateY(-40%);
}
.section-portfolio-headings-centered__item-counter [data-arts-split-text-element=char]:nth-child(2), .section-portfolio-headings-centered__item-counter .js-arts-split-text__char:nth-child(2) {
  z-index: 60;
  transform: translateY(-40%);
}

.section-portfolio-headings-centered__item-counter:not([data-arts-split-text-ready=true]) {
  transform: translateY(-40%);
}

.section-portfolio-headings-centered__item-counter_right {
  left: auto;
  right: 1em;
}

@media screen and (max-width: 991px) {
  .section-portfolio-headings-centered__item-header {
    margin-top: 1em;
  }
}
.section-portfolio-headings-centered__item_hovered .hover-zoom__zoom {
  transform: scale(0.98);
}
.section-portfolio-headings-centered__item_hovered .hover-zoom__inner {
  transform: scale(1.02);
}

@media screen and (min-width: 992px) {
  .section-portfolio-headings-centered__item {
    max-width: 100%;
  }
  .section-portfolio-headings-centered__item-header {
    position: absolute;
    top: 50%;
    left: var(--gutter-horizontal);
    right: var(--gutter-horizontal);
    width: auto;
    transform: translateY(-50%);
    margin: auto;
    z-index: 60;
  }
  .section-portfolio-headings-centered__item-media {
    position: relative;
    width: 720px;
    max-width: calc(50vw - var(--gutter-horizontal));
    pointer-events: auto;
  }
}
#wp-calendar, .wp-block-calendar {
  width: 100%;
  text-align: center;
}
#wp-calendar thead, .wp-block-calendar thead {
  border-bottom: 1px solid var(--border-color-solid);
}
#wp-calendar caption, .wp-block-calendar caption {
  caption-side: top;
  width: 100%;
  text-align: center;
  padding-top: 0;
  padding-bottom: 10px;
}
#wp-calendar th, .wp-block-calendar th {
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
#wp-calendar td, .wp-block-calendar td {
  padding: 5px;
}
#wp-calendar td#next, .wp-block-calendar td#next {
  text-align: right;
}
#wp-calendar td#prev, .wp-block-calendar td#prev {
  text-align: left;
}
#wp-calendar tbody a:before, .wp-block-calendar tbody a:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.3s ease;
}
#wp-calendar tr, .wp-block-calendar tr {
  border-bottom: none;
}
#wp-calendar a, .wp-block-calendar a {
  display: inline-block;
  position: relative;
  z-index: 50;
}

.wp-calendar-nav {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  margin-top: 0.5em;
}

.widget_archive ul li {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: 600;
}

.widget_categories ul li {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: 600;
}
.widget_categories ul li span {
  line-height: 1;
}

.widget_nav_menu ul.menu-column > li {
  white-space: normal;
  word-break: break-word;
}
.widget_nav_menu ul.menu-column > li a {
  display: inline-block;
  padding: 0;
  text-transform: none;
  letter-spacing: 0;
}
.widget_nav_menu ul.menu-column > li.menu-item-has-children:not(:last-child) {
  margin-bottom: 1em !important;
}

.widget_rss .rsswidget {
  font-weight: 600;
}
.widget_rss .rss-date {
  display: block;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 1em;
}
.widget_rss .rssSummary {
  margin-top: 1em;
  margin-bottom: 1em;
}
.widget_rss ul > li {
  border-bottom: 1px solid var(--color-border-opacity);
  padding-top: 0.5em;
  padding-bottom: 1em;
}
.widget_rss ul > li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.widget_recent_comments ul li {
  padding: 0.75em 0;
  margin-bottom: 0 !important;
  border-bottom: 1px solid var(--color-border-opacity);
}
.widget_recent_comments ul li:first-child {
  padding-top: 0;
}
.widget_recent_comments ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.widget_recent_comments ul li a {
  font-weight: 600;
}
.widget_recent_comments .comment-author-link a {
  font-weight: normal;
}

.wp-block-latest-comments__comment-link {
  font-weight: normal;
}

.wp-block-latest-comments li {
  padding: 1em 0;
  margin-bottom: 0 !important;
  border-bottom: 1px solid var(--color-border-opacity);
}
.wp-block-latest-comments li:first-child {
  padding-top: 0;
}
.wp-block-latest-comments li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.wp-block-latest-comments li a:not(.wp-block-latest-comments__comment-author) {
  font-weight: 500;
}

.widget_recent_entries .post-date {
  display: block;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  opacity: 0.7;
}
.widget_recent_entries ul li {
  padding: 0.75em 0;
  margin-bottom: 0 !important;
  border-bottom: 1px solid var(--color-border-opacity);
}
.widget_recent_entries ul li a {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}
.widget_recent_entries ul li a span {
  display: inline-block;
}
.widget_recent_entries ul li:first-child {
  padding-top: 0;
}
.widget_recent_entries ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.wp-block-latest-posts__list li {
  padding: 1em 0;
  margin-bottom: 0 !important;
  border-bottom: 1px solid var(--color-border-opacity);
}
.wp-block-latest-posts__list li a {
  font-weight: 500;
  line-height: 1.5;
}
.wp-block-latest-posts__list li a span {
  display: inline-block;
}
.wp-block-latest-posts__list li:first-child {
  padding-top: 0;
}
.wp-block-latest-posts__list li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.wp-block-latest-posts__post-date {
  display: block;
  width: 100%;
  margin-top: 0.25em;
}

.widget_asli_soc1al ul {
  text-align: inherit;
}
.widget_asli_soc1al ul li {
  display: inline-block;
  margin-bottom: 0;
}

.widget_text .textwidget > *:first-child {
  margin-top: 0;
}
.widget_text .textwidget > *:last-child {
  margin-bottom: 0;
}